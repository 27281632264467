2<template>
	<v-layout wrap>
		<v-flex xs12>
			<v-badge right overlap color="primary" style="width: 100%;">
				<template v-if="config.canChangeRestHome" v-slot:badge>
					<v-icon class="pointer" @click="$emit('resetRestHome')">close</v-icon>
				</template>
				<v-card elevation="0" class="pa-3" full-width>
					<v-card-text>
						<h5 class="title font-weight-bold mb-3">
							{{ extra.restHome.tradingName }} - ID: {{ extra.restHome.id }}
						</h5>
						<ul class="ma-0 pa-0" style="list-style: none;">
							<li>
								<strong>Razão social:</strong> {{ extra.restHome.companyName }}
							</li>
							<li>
								<strong>CNPJ:</strong> {{ extra.restHome.federalRegistration }} - <strong>Insc. EST.:</strong> {{ extra.restHome.stateRegistration || '-' }}
							</li>
							<li>
								<strong>E-mail:</strong> {{ extra.restHome.email }} - <strong>TEL.:</strong> {{ extra.restHome.responsiblePhone | telefone }}
							</li>
						</ul>
					</v-card-text>
				</v-card>
			</v-badge>
		</v-flex>

		<v-flex xs12>
			<v-switch
				v-model="isOptionalPayment"
				:disabled="isOptionalPaymentDisabled"
				large
				@change="setPaymentOptional">
				<template v-slot:label>
					<div class="ml-4">
						<h5 class="font-weight-bold subheading mb-3">{{ isOptionalPaymentActiveMessage }}</h5>

						<p v-if="isOptionalPayment">
							O pedido terá uma farura* gerada, mas <b>não precisará de pagamento antes da conclusão.</b> <br>
							Para finalização, o orçamento dependerá de liberação manual e inclusão de receita se houver produtos controlados. <br>
							*A fatura será gerada para o próprio cliente ou para o responsável financeiro, se houver produto controlado.
						</p>
						<p v-else>
							<b>Este pedido NÃO terá uma fatura associada e necessidade de pagamento antes da conclusão.</b><br>
							O orçamento dependerá de liberação manual e inclusão de receita se houver produto controlado.
						</p>
					</div>
				</template>
			</v-switch>
		</v-flex>
	</v-layout>
</template>

<script>
import { BUSINESS_B2B2C_TYPE, BUSINESS_B2B_TYPE, BUSINESS_B2C_TYPE } from '@Consts/business-type';

export default {
	name: 'RestHomenfo',
	props: {
		extra: {
			required: true,
			type: Object
		},
		config: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			isOptionalPayment: false
		};
	},
	created() {
		this.isOptionalPayment = !!this.extra.isOptionalPayment;
	},
	computed: {
		isOptionalPaymentDisabled() {
			return this.isBusinessTypeB2C || !this.config.canMarkAsOptionalPayment;
		},
		isBusinessTypeB2C() {
			return this.extra.restHome.businessType === BUSINESS_B2C_TYPE.value;
		},
		isOptionalPaymentActiveMessage() {
			return `Pagamento opcional ${this.isOptionalPayment ? 'ATIVADO' : 'DESATIVADO'}`;
		}
	},
	methods: {
		async validate() {
			const isValid = await this.$validator.validateAll();
			return !!isValid;
		},
		setPaymentOptional(value) {
			this.extra.isOptionalPayment = !!value;

			// b2b e pagamento opcional ativado = b2b2c
			if ((this.extra.businessType === BUSINESS_B2B_TYPE.value) && value)
				this.extra.businessType = BUSINESS_B2B2C_TYPE.value;

			// b2b2c e pgamento opcional desativado = b2b
			if ((this.extra.businessType === BUSINESS_B2B2C_TYPE.value) && !value)
				this.extra.businessType = BUSINESS_B2B_TYPE.value;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
