<template>
	<section class="farme-dialog" style="overflow-y: scroll;">

		<div class="farme-dialog-header pt-0">
			<button
				class="mt-2 mb-2"
				style="margin-left: 99%"
				@click="$emit('close')">
				<v-icon>close</v-icon>
			</button>
			<DialogHeader
				:quotationId="quotation.id"
				:invoice="quotation.invoice"
				:restHome="quotation.restHome"
				:quotationBusinessType="quotation.businessType"
				:hasControlled="hasControlledMedicine"/>
		</div>

		<div class="farme-dialog-body">
			<template
				v-if="isLoading">
				Carregando....
			</template>

			<DialogBody
				v-else
				:quotation="quotation"
				:hasControlled="hasControlledMedicine"
				:prescription="prescription" />
		</div>

		<div class="farme-dialog-body">
			<DialogFooter
				:hasControlled="hasControlledMedicine"
				:hasPrescriptions="!!prescription.items.length"
				:quotationStatus="quotation.status"
				:quotationBusinessType="quotation.businessType"
				@handleCancel="handleCancel"
				@handleApprove="handleApprove"/>
		</div>
	</section>
</template>

<script>
import { OrderPresenter } from '@Presenter/order-presenter';
import { PontualQuotationUtil } from '@Util/pontual-quotation';
import DialogHeader from './header/index.vue';
import DialogBody from './body/index.vue';
import DialogFooter from './footer/index.vue';

export default {
	name: 'DetailPontualQuotationDialog',
	components: {
		DialogHeader,
		DialogBody,
		DialogFooter
	},
	props: {
		quotation: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			prescription: {
				canAdd: false,
				canRemove: false,
				items: []
			},
			isLoading: false
		};
	},
	async created() {
		if (this.hasControlledMedicine) {
			if (this.quotation.externalId)
				await this.getPontualOrder();

			this.prescription.canAdd = !PontualQuotationUtil.isDone(this.quotation.status);
		}
	},
	computed: {
		hasControlledMedicine() {
			return this.quotation.items.some(item => item.isControlled);
		}

	},
	methods: {
		handleCancel() {
			this.$emit('handleCancel');
		},
		handleApprove() {
			this.$emit('handleApprove', this.prescription.items);
		},
		async getPontualOrder() {
			this.isLoading = true;
			try {
				const response = await OrderPresenter.find(this.quotation.externalId);
				const items = [];
				if (response.attachments.length) {
					response.attachments
						.forEach(item => {
							if (item.name === 'Receita') {
								items.push({
									canRemove: false,
									notificationNumber: item.metadata.notificationNumber,
									prescribedDate: item.metadata.prescriptionDate,
									prescriber: item.metadata.prescriber
								});
							}
						});
				}

				if (items.length) {
					this.prescription.items = items;
					this.prescription.canAdd = false;
				}
			} catch (e) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						message: e.message || 'Erro ao buscar informações do pedido pontual.',
						color: 'error'
					}
				);
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
