import $http from '@Config/axios';

const PREFIX_V2 = '/v2/patients';

export class PatientPresenter {
	/**
	 * @typedef {Object} FindPatientAddressResponse
	 * @property {String} street
	 * @property {String} neighborhood
	 * @property {?String} number
	 * @property {?String} city
	 * @property {?String} state
	 * @property {?String} stateName
	 * @property {?String} complement
	 *
	 * @typedef {Object} FindPatientHomeResponse
	 * @property {Number} id
	 * @property {String} name
	 * @property {String} type
	 * @property {?FindPatientAddressResponse} address
	 *
	 * @typedef {Object} FindPatientSubscriptionResponse
	 * @property {Number} id
	 * @property {Boolean} externalCharge
	 * @property {?FindPatientAddressResponse} address
	 *
	 *
	 * @typedef {Object} FindPatientResponse
	 * @property {Number} id
	 * @property {String} name
	 * @property {String} email
	 * @property {String} type
	 * @property {String} gender
	 * @property {String} sex
	 * @property {String} birthdate
	 * @property {String} phone

	 * @property {String} document
	 * @property {?FindPatientRestHomeResponse} restHome
	 * @property {?FindPatientSubscriptionResponse} subscription
	 *
	 * @param {Number} id
	 * @returns {Promise<FindPatientResponse>}
	 */
	static async find(id) {
		const response = await $http.get(`${PREFIX_V2}/${id}`);
		// ajutar parse
		return response;
	}
}
