import { render, staticRenderFns } from "./medicine-prescription-utilization-badge.vue?vue&type=template&id=43a0a022&scoped=true"
import script from "./medicine-prescription-utilization-badge.vue?vue&type=script&lang=js"
export * from "./medicine-prescription-utilization-badge.vue?vue&type=script&lang=js"
import style0 from "./medicine-prescription-utilization-badge.vue?vue&type=style&index=0&id=43a0a022&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43a0a022",
  null
  
)

export default component.exports