<template>
	<v-chip
		style="min-width: 100px"
		small
		disabled
		class="justify-center mx-0 white--text"
		:class="$functions.getStatusOrcamento(status).class">
		{{
			$functions
				.getStatusOrcamento(status)
				.label.toUpperCase()
		}}
	</v-chip>
</template>

<script>
export default {
	props: {
		status: {
			required: true,
			type: String
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
