<template>
	<section style="display: flex; align-items: center; height: 55px;">
		<v-autocomplete
			box
			:search-input.sync="q"
			hide-details
			hide-selected
			return-object
			:loading="loading"
			:disabled="loading"
			background-color="white"
			label="Busque por Nome, E-mail ou CPF"
			:no-data-text="getNotextData"
			prepend-inner-icon="search"
			no-filter
			:items="items"
			@change="selectPerson"
			full-width>
			<template v-slot:selection="data">
				{{ data.item.name }}
			</template>

			<template v-slot:item="data">
				{{ data.item.name }} - CPF ({{ data.item.document }} | E-mail: {{ data.item.email }})
			</template>
		</v-autocomplete>

		<v-dialog
			persistent
			v-model="isCreatePersonDialogOpened"
			transition="fade"
			max-width="600">

			<template v-slot:activator="{ on }">
				<button
					v-on="on"
					style="background-color: #FFC875; height: 100%;"
					class="ml-2 px-4"
					@click="openCreatePersonDialog">
					<v-icon>add</v-icon>
				</button>
			</template>

			<section class="farme-dialog">
				<section class="farme-dialog-header">
					Adicionar pessoa
				</section>
				<section class="farme-dialog-body">
					<v-container fluid grid-list-md class="pa-0">
						<v-layout wrap>
							<v-flex md4 xs12>
								<v-text-field
									v-model="customer.documentNumber"
									box
									label="CPF *"
									mask="###.###.###-##"
									data-vv-name="CPF"
									:error-messages="errors.collect('CPF')"
									background-color="white"
									v-validate="{ cpf: true, required: true }" />
							</v-flex>

							<v-flex md8 xs12>
								<v-text-field
									v-model="customer.name"
									box
									label="Nome Completo *"
									background-color="white"
									v-validate="{ required: true }"
									data-vv-name="Nome completo"
									:error-messages="errors.collect('Nome completo')" />
							</v-flex>

							<v-flex md6 xs12>
								<v-text-field
									v-model="customer.email"
									box
									label="E-mail *"
									data-vv-name="E-mail"
									:error-messages="errors.collect('E-mail')"
									background-color="white"
									v-validate="{ required: true }"  />
							</v-flex>

							<v-flex md6 xs12>
								<v-text-field
									v-model="customer.phone"
									box
									label="Telefone *"
									:mask="'(##) #####-####' || '(##) ####-####'"
									data-vv-name="Telefone"
									:error-messages="errors.collect('Telefone')"
									background-color="white"
									v-validate="{ required: true, telefone: true }"/>
							</v-flex>

							<v-flex md6 xs12>
								<TextDateField
									background-color="white"
									label="Data de nascimento"
									placeholder="dd/mm/aaaa"
									box
									v-model="customer.birthdate"
									data-vv-name="Data de nascimento"
									:validate="{ required: false, data: true }"
									:error-messages="errors.collect('Data de nascimento')"/>
							</v-flex>

							<v-flex md6 xs12>
								<v-select
									v-model="customer.sex"
									:items="sexs"
									label="Sexo"
									box
									clearable
									v-validate="{ required: false }"
									:error-messages="errors.collect('Sexo')"
									data-vv-name="Sexo"
									background-color="white"/>
							</v-flex>
						</v-layout>
					</v-container>
				</section>
				<section class="farme-dialog-footer">
					<v-btn depressed flat @click="closeCreatePersonDialog">
						Cancelar
					</v-btn>
					<v-spacer />
					<v-btn large depressed color="primary" @click="handlePerson">
						Salvar
					</v-btn>
				</section>
			</section>
		</v-dialog>
	</section>
</template>

<script>
import { PersonPresenter } from '@Presenter/v2/person-presenter';
import { PatientPresenter } from '@Presenter/v2/patient-presenter';
import { debounce, isEqual } from 'lodash';
import { getAllSex } from '@Consts/sexo';

export default {
	$_veeValidate: { validator: 'new' },
	data: () => ({
		loading: false,
		items: [],
		q: null,
		dialogPaciente: false,
		customer: {},
		isCreatePersonDialogOpened: false
	}),
	created() {
		this.reset();
	},
	computed: {
		sexs() {
			return getAllSex().map(sex => ({ text: sex.name, value: sex.id }));
		},
		getNotextData() {
			if (!this.q)
				return 'Digite alguma coisa';
			if (this.loading)
				return 'Buscando items';

			return 'Nenhuma pessoa encontrada';
		}
	},
	methods: {
		openCreatePersonDialog() {
			this.isCreatePersonDialogOpened = true;
		},
		closeCreatePersonDialog() {
			this.isCreatePersonDialogOpened = false;
		},
		getItems: debounce(async function () {
			try {
				this.loading = true;
				const response = await PersonPresenter.list({
					shouldBePaginated: !!this.q,
					q: this.q
				});
				this.items = response.items;
			} catch (error) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: error.message || 'Erro ao buscar clientes'
				});
			} finally {
				this.loading = false;
			}
		}, 1000),

		async selectPerson(person) {
			try {
				this.loading = true;
				const response = await PatientPresenter.find(person.id);
				this.$emit('handlePatientSearch', response);
			} catch (error) {
				this.$emit('handlePatientSearch', person);
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'warning',
					textColor: 'rgba(0, 0, 0, 0.87)',
					timeout: 10000,
					message: 'O cliente selecionado não possui uma assinatura Far.me ativa.'
				});
			} finally {
				this.loading = false;
				this.reset();
			}
		},

		reset() {
			this.items = [];
			this.q = null;
			this.customer = {
				documentNumber: null,
				name: null,
				email: null,
				phone: null,
				sex: null,
				birthdate: null
			};
		},

		async handlePerson() {
			const isValid = await this.$validator.validateAll();
			if (!isValid) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Formulário incompleto'
				});
			}

			try {
				this.loading = true;
				await PersonPresenter.create({
					name: this.customer.name,
					birthdate: this.$functions.FormatDateToRequest(this.customer.birthdate),
					documentNumber: this.customer.documentNumber,
					sex: this.customer.sex,
					email: this.customer.email,
					phone: this.customer.phone
				});
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'success',
					message: 'Pessoa cadastrada com sucesso!'
				});
				this.closeCreatePersonDialog();
				this.reset();
			} catch (error) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: error.message || 'Erro ao salvar dados do cliente'
				});
			} finally {
				this.loading = false;
			}
		}
	},
	watch: {
		q: {
			async handler(newVal, oldVal) {
				if (isEqual(newVal, oldVal) || !newVal)
					return;
				await this.getItems();
			}
		}
	}
};
</script>
