<template>
	<v-dialog
		v-model="opened"
		transition="fade"
		max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn v-on="on" flat color="error" @click="open">Cancelar orçamento</v-btn>
		</template>

		<section class="farme-dialog" style="border: 2px solid #E50000">
			<section class="farme-dialog-body">
				<v-layout column wrap class="d-flex items-center justify-center">
					<v-flex xs12 class="imgStyle d-flex items-center mb-4">
						<img src="/img/icons/exclamation-icon.svg" width="50" height="50" alt="Imagem de exclamação, sugerindo atenção">
					</v-flex>
					<v-flex xs12 class="text-center sans">
						<h5 class="title font-weight-bold mb-4">Você tem certeza que deseja cancelar o orçamento?</h5>
						<p class="title font-weight-light">
							Esta ação não poderá ser desfeita. <br />
							Se um pagamento tiver sido efetuado o estorno <br /> será realizado para o cliente.
						</p>
					</v-flex>
					<v-flex xs12 class="d-flex items-center mt-3">
						<v-btn dark depressed @click="close">Voltar</v-btn>
						<v-btn color="error" depressed @click="cancel">Cancelar orçamento</v-btn>
					</v-flex>
				</v-layout>
			</section>
		</section>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			opened: false
		};
	},
	methods: {
		open() {
			this.opened = true;
		},
		close() {
			this.opened = false;
		},
		cancel() {
			this.$emit('handleCancel');
			this.close();
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
