<template>
	<transition>
		<v-card
			style="
				border-radius: 0 !important;
				background-color: rgba(255, 200, 117, 0.5) !important;
			">
			<div class="purple-title toolbarHeight full-width">
				<div class="d-flex items-center q-column">
					<Header
						class="full-width"
						:title="title"
						:tabSelected="tabSelected"
						:companyUnitId="generalData.extra.companyUnitId"
						:loadingSave="loadingSaveQuotation"
						:loadingSubmit="loadingSubmitQuotation"
						@saveQuotation="saveQuotation"
						@close="$emit('close')"/>
					<div class="container">
						<Tabs
							:tabSelected="tabSelected"
							:allowNavagitation="allowNavagitation"
							@selectTab="(tab) => (tabSelected = tab)"/>
					</div>
				</div>
			</div>
			<v-card-text class="cardBody">
				<div v-if="!loadingQuotation" class="container">
					<GeneralData
						ref="generalData"
						v-show="isGeneralData"
						:config="config"
						:generalData="generalData"
						class="container"/>

					<ProductData
						ref="productsData"
						v-show="isTabProducts"
						:productData="productData"
						:generalData="generalData"
						class="container"/>

					<v-textarea
						v-model="generalData.extra.observation"
						label="Observação do cliente"
						box
						background-color="white"
						cols="10"/>

					<Footer
						:quotation="{}"
						:tabSelected="tabSelected"
						class="container py-0"
						@cancel="dialogCancelQuotation = true"
						@back="back"
						@next="next"/>
				</div>
				<div
					v-if="loadingQuotation"
					class="loading flex-center justify-center">
					<v-progress-circular indeterminate color="secondary" size="70" />
				</div>
			</v-card-text>

			<v-dialog
				persistent
				v-model="dialogCancelQuotation"
				scrollable
				transition="fade"
				max-width="400px">
				<transition>
					<Dialog
						v-if="dialogCancelQuotation"
						titulo="Cancelar orçamento"
						subtitulo="Tem certeza que deseja cancelar o orçamento? Ele não poderá ser recuperado."
						btn_acao="Cancelar orçamento"
						btn_acao_class="error"
						btn_cancelar="Fechar"
						@close="dialogCancelQuotation = false"
						@acao="cancelQuotation"/>
				</transition>
			</v-dialog>

			<v-dialog
				persistent
				v-if="dialogConfirmQuotation"
				scrollable
				v-model="dialogConfirmQuotation"
				transition="fade"
				max-width="500px">
				<transition>
					<DialogConfirmQuotation
						v-if="dialogConfirmQuotation"
						:loading="loadingSubmitQuotation"
						:close.sync="dialogConfirmQuotation"
						@submitQuotation="submitQuotation"/>
				</transition>
			</v-dialog>

			<v-dialog
				persistent
				v-if="dialogSaveQuotation"
				scrollable
				v-model="dialogSaveQuotation"
				transition="fade"
				max-width="500px">
				<transition>
					<DialogSaveQuotation
						v-if="dialogSaveQuotation"
						:loading="loadingSaveQuotation"
						:close.sync="dialogSaveQuotation"
						@saveQuotation="
							isNewQuotation ? saveQuotation() : saveEditedQuotation()
						"/>
				</transition>
			</v-dialog>
		</v-card>
	</transition>
</template>

<script>
import { stepsQuotation } from '@Consts/quotation-v2';
import Tabs from './tabs.vue';
import Header from './header.vue';
import GeneralData from './general-data/index.vue';
import ProductData from './products-data/index.vue';

import Footer from './footer.vue';
import DialogConfirmQuotation from './dialog-confirm-quotation.vue';
import DialogSaveQuotation from './dialog-confirm-save.vue';

export default {
	name: 'QuotationPontual',
	props: {
		generalData: {
			required: true,
			type: Object
		},
		productData: {
			required: true,
			type: Object
		},
		config: {
			required: true,
			type: Object
		},
		title: {
			required: true,
			type: String
		},
		allowNavagitation: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	components: {
		Tabs,
		Header,
		Footer,
		GeneralData,
		ProductData,
		DialogConfirmQuotation,
		DialogSaveQuotation
	},
	data: () => ({
		tabSelected: 'general-data',
		erros: [],
		tags: [],
		loadingQuotation: false,
		loadingSaveQuotation: false,
		loadingSubmitQuotation: false,
		dialogCancelQuotation: false,
		dialogConfirmQuotation: false,
		dialogSaveQuotation: false,
		loadingSellers: true,
		loadingConfig: false
	}),
	created() {
		this.tabSelected = 'general-data';
	},
	computed: {
		isGeneralData() {
			return this.tabSelected === stepsQuotation.generalData;
		},
		isTabProducts() {
			return this.tabSelected === stepsQuotation.products;
		},
		isNewQuotation() {
			return typeof this.quotationId !== 'number';
		}
	},
	methods: {
		cancelQuotation() {
			this.$emit('close');
		},
		async saveQuotation() {
			const generalDataValidate = await this.$refs.generalData.validate();
			if (!generalDataValidate) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Dados gerais está incompleto!'
				});
			}

			const productDataValidate = await this.$refs.productsData.validate();
			if (!productDataValidate) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Dados de produtos está incompleto'
				});
			}

			this.$emit(
				'handleQuotation',
				{
					generalData: this.generalData,
					productData: this.productData
				}
			);
		},

		async next() {
			if (this.isGeneralData) {
				const generalDataValidate = await this.$refs.generalData.validate();
				if (!generalDataValidate) {
					return this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Dados gerais está incompleto!'
					});
				}

				this.tabSelected = stepsQuotation.products;
				return;
			}

			if (this.isTabProducts) {
				const productDataValidate = await this.$refs.productsData.validate();
				if (!productDataValidate) {
					return this.$store.dispatch('SHOW_SNACKBAR', {
						color: 'error',
						message: 'Dados de produtos está incompleto'
					});
				}
			}
		},
		back() {
			if (this.isTabProducts)
				this.tabSelected = stepsQuotation.generalData;
		},
		onSelectTab(tab) {
			this.tabSelected = tab;
		}
	}
};
</script>

<style lang="scss" scoped>
	.container {
		max-width: 850px !important;
		min-width: 850px !important;
		padding: 24px 0px;
	}

	.toolbarHeight {
		height: 160px !important;
	}

	.cardBody {
		background-color: rgba(255, 234, 204, 1);
		height: 100vh;
	}
</style>
