<template>
	<section>
		<h4 class="subtitle mb-3">Histórico de status</h4>
		<v-data-table
			:disable-initial-sort="true"
			:headers="headers"
			:items="history"
			:rows-per-page-items="[-1]"
			hide-actions
			no-data-text="Não há histórico">
			<template v-slot:items="props">
				<tr class="pointer" style="background-color: transparent;">
					<!-- <td>{{ props.item.user }}</td> -->
					<td class="text-left">{{ props.item.createdAt | formatDate('DD/MM/YYYY - HH:mm:ss') }}</td>
					<td class="text-xs-center">
						<v-chip disabled :class="$functions.getStatusOrcamento(props.item.status).class"
							class="white--text px-3 text-uppercase caption text-bold">
							{{ $functions.getStatusOrcamento( props.item.status).label }}
						</v-chip>
					</td>
				</tr>
			</template>
		</v-data-table>
	</section>
</template>

<script>
export default {
	name: 'QuotationHistory',
	props: {
		history: {
			required: false,
			type: Array,
			default: () => []
		}
	},
	data: () => ({
		headers: [
			{
				text: 'Data', value: 'date', align: 'left', width: '200px'
			},
			{
				text: 'Status', value: 'status', align: 'end', sortable: false
			}
		]
	})
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.title {
	font-weight: 600;
	font-size: 13px;
}

.transparent-bg >>> .v-data-table, .transparent-bg {
	background-color: transparent !important;
  }
</style>
