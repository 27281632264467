import { BUSINESS_B2B_TYPE, BUSINESS_B2C_TYPE } from '@Consts/business-type';

export const BUSINESS_TYPE_B2B = Object.freeze({
	value: BUSINESS_B2B_TYPE.value,
	text: BUSINESS_B2B_TYPE.text
});

export const BUSINESS_TYPE_B2C = Object.freeze({
	value: BUSINESS_B2C_TYPE.value,
	text: BUSINESS_B2C_TYPE.text
});

export const BUSINESS_TYPES = Object.freeze([
	BUSINESS_TYPE_B2B,
	BUSINESS_TYPE_B2C
]);
