<template>
	<v-card flat elevation="0" color="#FFF3E2" class="cardStyle sans pb-sm-3 pt-sm-4 px-sm-4">
		<v-flex class="d-flex items-center justify-center">
			<div style="width: 68px !important">
				<v-avatar size="68" style="width: 68px !important" color="white">
					<img src="/img/icons/patient.svg" alt="Icone de paciente"
						style="width: 35px;height: 39px" />
				</v-avatar>
			</div>
			<v-flex xs12 md6>
				<p class="font-weight-bold mb-2" style="font-size: 18px">{{customerName}}</p>
				<p class="mb-0"><strong>Telefone:</strong>{{ customerPhone | telefone }}</p>
				<p v-if="quotation.customer.email" class="mb-0"><strong>E-mail:</strong> {{ customerEmail }}</p>
			</v-flex>
			<v-flex xs12 md6>
				<p class="mb-0"><strong>Criação:</strong> {{ quotation.createdAt | formatDate('DD/MM/YYYY') }}
				</p>
				<p class="mb-0"><strong>Validade:</strong> {{ quotation.expiresAt | formatDate('DD/MM/YYYY')}}</p>
				<p class="mb-0"><strong>Qtd. de itens:</strong> {{ quotation.items.length || 0 }}</p>
				<p class="mb-0"><strong>Vendedor:</strong> {{ quotation.seller.name }}</p>
			</v-flex>
			<v-flex xs12 md6>
				<p class="mb-0"><strong>Data da entrega: </strong> <br> {{ deliveryDate | formatDate('DD/MM/YYYY')}} </p>
				<v-chip disabled :class="$functions.getStatusOrcamento(quotation.status).class"
					class="white--text text-uppercase px-3">
					{{ $functions.getStatusOrcamento(quotation.status).label }}
				</v-chip>
			</v-flex>
		</v-flex>
	</v-card>
</template>

<script>
export default {
	props: {
		quotation: {
			required: true,
			type: Object
		}
	},
	computed: {
		customerName() {
			return this.quotation.customer.name;
		},
		customerEmail() {
			return this.quotation.customer.email;
		},
		customerPhone() {
			return this.quotation.customer.phone;
		},
		deliveryDate() {
			return this.quotation.startDate;
		}
	}

};
</script>

<style lang="scss" scoped>

</style>
