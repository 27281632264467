<template>
	<Dialog :titulo="isNew ? 'Adicionar produto' : 'Editar produto'" @close="closeDialog" background="#FFEACC">
		<v-container fluid grid-list-xl>
			<v-layout row wrap>
				<CardProduct :product="product"/>
				<Values :product="product" :isNew="isNew" :discountType="discountType" @updateValues="val => product.values = val" @selectDiscount="openDialogSelectDiscount"/>
				<hr class="spacerQuotationStyle">
				<Actions class="noPadding" :product="product" :salesMethod="salesMethodSelected" @salesMethodSelected="val => salesMethodSelected = val" @methodSelected="val => methodSelected = val"/>
				<Posology class="noPadding" :product="product" :posology="posology" :methodSelected="methodSelected" :salesMethodSelected="salesMethodSelected"
					:isIntegralityEnabled="enableIntegrality"
					@schedulesMethod="resetPosology"/>
				<Footer class="noPadding" :isNew="isNew" @addProduct="isNew ? addProduct(product) : saveProduct(product)" @removeProduct="openDialogRemoveProduct" @cancel="closeDialog"/>
			</v-layout>
		</v-container>

		<v-dialog persistent v-if="dialogRemoveProduct" v-model="dialogRemoveProduct"
			transition="fade" max-width="450px">
			<transition>
				<DialogRemoveProduct @removeProduct="removeProduct(product)" :close.sync="dialogRemoveProduct"/>
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { posologyTypes } from '@Consts/quotation-v2';
import { calculateValuesOfDiscount, getPropertyValue } from '@Util/ProductService';
import { featureFlags } from '@Consts/feature-flags';
import { getSimulationCycles } from '@Util/cycles';
import DialogRemoveProduct from '../dialog-remove-product.vue';
import Posology from './posology.vue';
import Values from './values.vue';
import Actions from './actions.vue';
import Footer from './footer.vue';
import CardProduct from './card-product.vue';
import Mixin from '../../mixin';

export default {
	name: 'DialogProductAndPosology',
	components: {
		Values, Actions, Footer, CardProduct, Posology, DialogRemoveProduct
	},
	mixins: [Mixin],
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		methodSelected: 'subscription',
		salesMethodSelected: 'schedules',
		dialogRemoveProduct: false,
		dialogSelectDiscount: false,
		product: {},
		posology: {
			frequency: posologyTypes.allDays,
			daysOfWeek: [],
			daysOfMonth: [],
			schedules: []
		},
		discountType: ''
	}),
	created() {
		this.product = {
			...this.$lodash.cloneDeep(this.item),
			hasPrescriptionUtilization: this.item.hasPrescriptionUtilization ? this.item.hasPrescriptionUtilization : null,
			quantityOfBoxes: this.item.quantityOfBoxes ? this.item.quantityOfBoxes : undefined
		};
		if (!this.isNew) {
			this.posology = this.$lodash.cloneDeep(this.product.posology);

			this.product.values = { ...this.product.values };

			if (this.product.quantityOfBoxes)
				this.salesMethodSelected = 'boxes';
		}
	},
	methods: {
		async addProduct(product) {
			const isControlled = product.drug && !!product.drug.prescriptionRule;

			if (!this.posology.frequency && !this.product.quantityOfBoxes) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'É necessário adicionar frequência ou a quantidade de caixas.' });
				return;
			}

			if (this.posology.frequency && !this.posology.schedules.length) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'É necessário adicionar algum horário para adicionar o produto.' });
				return;
			}

			const missingPills = this.posology.schedules.some(schedule => !schedule.quantidade_por_horario);

			if (this.posology.frequency && missingPills) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'É necessário adicionar a quantidade de comprimidos de todos os horários escolhidos' });
				return;
			}

			if (this.enableIntegrality && isControlled && product.hasPrescriptionUtilization === null) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'É necessário selecionar se o produto tem aproveitamento de receita' });
				return;
			}

			this.adjustValues(product);
			const isDivisible = product.drug ? getPropertyValue(product.drug.properties, 'isDivisible') : false;
			if (!isDivisible || (isDivisible && product.quantityOfBoxes))
				product.posology = null;
			else
				product.posology = this.posology;

			const closedPackagesWithPrescriptionUtilization = await this.getClosedPackagesPrescriptionReuse(product);
			calculateValuesOfDiscount(product, closedPackagesWithPrescriptionUtilization);

			this.$emit('addProduct', product);
			this.quotation.type = this.methodSelected;
			this.closeDialog();
		},
		async saveProduct(product) {
			const isControlled = !!product?.drug?.prescriptionRule;

			if ((this.posology && !this.posology.frequency) && !this.product.quantityOfBoxes) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'É necessário adicionar frequência ou a quantidade de caixas.' });
				return;
			}

			if ((this.posology && this.posology.frequency) && !this.posology.schedules.length) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'É necessário adicionar algum horário para adicionar o produto.' });
				return;
			}

			const missingPills = this.posology && this.posology.schedules.some(schedule => !schedule.quantidade_por_horario);

			if ((this.posology && this.posology.frequency) && missingPills) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'É necessário adicionar a quantidade de comprimidos de todos os horários escolhidos' });
				return;
			}

			if (this.enableIntegrality && isControlled && product.hasPrescriptionUtilization === null) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'É necessário selecionar se o produto tem aproveitamento de receita' });
				return;
			}

			this.adjustValues(product);
			const isDivisible = product.drug ? getPropertyValue(product.drug.properties, 'isDivisible') : false;
			if (!isDivisible || (isDivisible && product.quantityOfBoxes))
				product.posology = null;
			else
				product.posology = this.posology;

			const closedPackagesWithPrescriptionUtilization = await this.getClosedPackagesPrescriptionReuse(product);
			calculateValuesOfDiscount(product, closedPackagesWithPrescriptionUtilization);

			const updatedProducts = this.quotation.items.map(prodt => (prodt.id === product.id ? { ...product } : prodt));

			this.$emit('updatedProducts', updatedProducts);

			this.quotation.type = this.methodSelected;
			this.closeDialog();
		},
		adjustValues(product) {
			switch (product.values.discountType) {
				case 'percentage':
					product.values.nominalDiscount = product.values.price * (product.values.discount / 100);
					product.values.finalPrice = product.values.price - product.values.nominalDiscount;
					break;
				case 'nominalDiscount':
					product.values.discount = (product.values.nominalDiscount * 100) / product.values.price;
					product.values.finalPrice = product.values.price - product.values.nominalDiscount;
					break;
				case 'finalPrice':
					product.values.nominalDiscount = product.values.price - product.values.finalPrice;
					product.values.discount = (product.values.nominalDiscount * 100) / product.values.price;
					break;
				default:
					break;
			}

			return product.values;
		},
		async getClosedPackagesPrescriptionReuse(product) {
			if (product.quantityOfBoxes > 0)
				return product.quantityOfBoxes;

			const quotation = this.$lodash.cloneDeep(this.quotation);
			quotation.items = quotation.items.map(prodt => (prodt.id === product.id ? { ...product } : prodt));
			if (!quotation.items.find(item => item.id === product.id))
				quotation.items.push(product);

			const cycles = await getSimulationCycles(quotation);
			if (!cycles || !cycles.length || !cycles[0].produtos || !cycles[0].produtos.length)
				return undefined;
			return cycles[0].produtos.find(item => item.id === product.id).quantidade_caixas_ciclo;
		},
		openDialogRemoveProduct() {
			this.dialogRemoveProduct = true;
		},
		openDialogSelectDiscount() {
			this.dialogSelectDiscount = true;
		},
		removeProduct(product) {
			const index = this.quotation.items.findIndex(prodt => product.gtin === prodt.gtin);
			if (index !== -1)
				this.quotation.items.splice(index, 1);

			this.closeDialog();
		},
		closeDialog() {
			this.$emit('update:close', false);
		},
		resetPosology() {
			this.posology = {
				frequency: posologyTypes.allDays,
				daysOfWeek: [],
				daysOfMonth: [],
				schedules: []
			};
		}
	},
	computed: {
		isNew() {
			const items = this.quotation.items || [];
			return !items.find(prodt => prodt.id === this.item.id);
		},
		enableIntegrality() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.integrality_b2c);
		}
	}
};
</script>

<style lang="scss" scoped>
.noPadding {
	padding: 0px !important;
}
</style>
