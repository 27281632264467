<template>
	<v-flex xs12 class="full-width mb-3" style="padding: 0px !important">
		<v-card
			color="#FFF3E2"
			class="cardStyle sans"
			:class="isOutOfBox ? 'outOfBoxStyle' : ''">
			<v-layout wrap class="full-height">
				<v-flex xs2 class="q-column items-center fit imageStyle" :class="isOutOfBox ? 'imgBorderOutOfBoxStyle' : 'imgBorderStyle'" :style="isOutOfBox ? 'padding: 20px !important' : 'padding: 10px !important'">
					<span v-if="isOutOfBox" class="textOutOfBox mb-1 text-bold caption sans">Fora da box</span>
					<img
						src="/img/default-medicine-box.svg" width="100%" height="180" alt="Imagem do produto"/>
				</v-flex>
				<v-flex xs10 class="justify-space-between" :style="!isOutOfBox ? 'padding: 0px !important' : 'padding: 20px !important'">
					<v-card-title class="pt-4 pb-0">
						<v-layout wrap>
							<ProductInfo :productInfo="item" />
							<Schedules :posology="item.posology" :item="item" />
							<OthersProductInfo :item="item" />
							<v-flex xs1 class="d-flex items-center justify-center">
								<div class="d-flex q-column items-center">
									<v-flex
										class="bgTrashIcon pointer d-flex items-center justify-center mb-2"
										@click="removeProduct(index)">
										<v-icon color="#E50000" small style="padding: 8px">fas fa-trash</v-icon>
									</v-flex>
									<v-flex
										class="bgEditIcon pointer d-flex items-center justify-center mb-2"
										@click="editProduct(item)">
										<v-icon color="#342B1D" small style="padding: 8px">fas fa-pen</v-icon>
									</v-flex>
								</div>
							</v-flex>
						</v-layout>
					</v-card-title>
				</v-flex>
			</v-layout>
		</v-card>
	</v-flex>
</template>

<script>
import { getPropertyValue } from '@Util/ProductService';
import Schedules from './schedules.vue';
import ProductInfo from './product-info.vue';
import OthersProductInfo from './others-product-info.vue';
import Mixin from '../../mixin';

export default {
	name: 'ItemQuotationV2',
	components: { Schedules, ProductInfo, OthersProductInfo },
	mixins: [Mixin],
	props: {
		item: {
			type: Object,
			default: () => {}
		},
		index: {
			type: Number
		}
	},
	data: () => ({
		product: {}
	}),
	created() {
		if (!this.isNewQuotation) {
			this.product = this.$lodash.cloneDeep({
				...this.item,
				quantityOfBoxes: this.item.quantityOfBoxes
			});
		} else
			this.product = this.$lodash.cloneDeep(this.item);
	},
	methods: {
		removeProduct(index) {
			this.$emit('removeProduct', index);
		},
		editProduct(product) {
			this.$emit('editProduct', product);
		}
	},
	computed: {
		isMedicine() {
			return this.product.drug;
		},
		isOutOfBox() {
			return this.isMedicine ? Boolean(getPropertyValue(this.product.drug.properties, 'isOutOfBox')) : true;
		}
	}
};
</script>

<style lang="scss" scoped>
	.cardStyle {
		border-radius: 0px 10px 10px 0px;
		color: rgba(52, 43, 29, 1);
		padding: 5px;
	}

	.imageStyle {
		background-color: white;
		padding-top: 0px !important;
	}

	.textOutOfBox {
		position: relative;
		top: -23px;
		left: 2px;
		background-color: #FFC875;
		border-radius: 0px 0px 4px 4px;
		padding: 2px 4px;
	}

	.outOfBoxStyle {
		border: 2px dashed rgba(255, 200, 117, 1) !important;
		border-radius: 0px 10px 10px 0px;
	}

	.imgBorderStyle {
		border-radius: 10px 0px 0px 10px;
		border: 1px solid #dcdcdc;
	}

	.imgBorderOutOfBoxStyle {
		border-radius: 10px 0px 0px 10px !important;
		border-right: 2px dashed rgba(255, 200, 117, 1) !important;
	}

	.bgTrashIcon {
		background-color: rgba(229, 0, 0, 0.1);
		border-radius: 20px;

		&:hover {
			background-color: rgba(229, 0, 0, 0.2);
			transition: 0.1s ease-in;
		}
	}

	.bgEditIcon {
		background-color: #ffeacc;
		border-radius: 20px;

		&:hover {
			background-color: rgba(52, 43, 29, 0.2);
			transition: 0.1s ease-in;
		}
	}
</style>
