<template>
	<v-chip
		label
		:color="getBgColor"
		text-color="white"
		class="py-1" style="border-radius: 10px;">
		<v-icon class="mr-2">house</v-icon>
		<span style="font-weight: 900; font-size: 18px;">{{ businessType }}</span>
	</v-chip>
</template>

<script>
import {
	BUSINESS_B2B2C_TYPE, BUSINESS_B2B_TYPE, BUSINESS_B2C_TYPE, BUSINESS_TYPES
} from '@Consts/business-type';

export default {
	props: {
		businessType: {
			required: true,
			type: String,
			validator: value => BUSINESS_TYPES.map(bt => bt.value).includes(value)
		}
	},
	computed: {
		getBgColor() {
			const colorsMap = {
				[BUSINESS_B2C_TYPE.value]: '#00B503',
				[BUSINESS_B2B_TYPE.value]: '#E50000',
				[BUSINESS_B2B2C_TYPE.value]: '#FF823C'
			};
			return colorsMap[this.businessType];
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
