<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }">
			<v-icon v-on="on" small color="#FF823C" class="medicine-prescription-utilization">fa-solid fa-file-prescription</v-icon>
		</template>
		<span class="inter text-bold" style="color: #000;">Aproveitamento de receita</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'MedicinePrescriptionUtilizationBadge'
};
</script>

<style lang="scss" scoped>
.medicine-prescription-utilization {
	border-radius: 17px;
	background-color: rgba(255, 130, 60, 0.3);
	padding: 7px 10px;
}

</style>
