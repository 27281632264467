import { getUrlJornadaDigital } from '@Config/url-jornada-digital';

export class FarmeUrls {
	/**
	 * @static
	 * @param {Number | String} id
	 * @returns {String}
	 */
	static getPontualQuotationJornadaDigitalUrl(id) {
		return `${getUrlJornadaDigital()}/pontual/${id}`;
	}
}
