<template>
	<v-flex
		class="bgTrashIcon pointer d-flex items-center justify-center mb-2"
		@click="openRemoveProductDialog">
		<v-icon color="#E50000" small style="padding: 8px">fas fa-trash</v-icon>

		<v-dialog persistent
			v-if="isRemoveProductDialogOpened"
			v-model="isRemoveProductDialogOpened"
			transition="fade" max-width="450px">
			<transition>
				<v-flex class="dialogStyle">
					<v-layout column wrap class="d-flex items-center justify-center">
						<v-flex xs12 class="imgStyle d-flex items-center mb-4">
							<img src="/img/icons/exclamation-icon.svg" width="42" height="42" alt="Imagem de exclamação, sugerindo atenção">
						</v-flex>
						<v-flex xs12 class="mb-4 text-center sans text-bold title">
							Você tem certeza que deseja remover este produto do orçamento?
						</v-flex>
						<v-flex xs12 class="d-flex items-center">
							<v-btn @click="closeRemoveProductDialog">Cancelar</v-btn>
							<v-btn color="error" @click="removeProduct">Remover Produto</v-btn>
						</v-flex>
					</v-layout>
				</v-flex>
			</transition>
		</v-dialog>
	</v-flex>
</template>

<script>
export default {
	data() {
		return {
			isRemoveProductDialogOpened: false
		};
	},
	methods: {
		openRemoveProductDialog() {
			this.isRemoveProductDialogOpened = true;
		},
		closeRemoveProductDialog() {
			this.isRemoveProductDialogOpened = false;
		},
		removeProduct() {
			this.$emit('removeProduct');
			this.closeRemoveProductDialog();
		}
	}
};
</script>

<style lang="scss" scoped>
.dialogStyle {
	padding: 20px 50px;
	border-radius: 20px;
	background: #FFF3E2;
	border: 2px solid #FFC875
}

.imgStyle {
	background-color: rgba(255, 200, 117, 0.6);
	padding: 15px;
	border-radius: 40px;
}
</style>
