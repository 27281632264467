<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }">
			<img
				v-on="on"
				src="/img/icons/percentage.svg"
				alt="Imagem de porcentagem"
				class="medicine-pbm-badge"
				style="width: 33px;height: 33px"/>
		</template>
		<span class="inter text-bold" style="color: #000;">PBM</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'MedicinePbmBadge'
};
</script>

<style lang="scss" scoped>
.medicine-pbm-badge {
	border-radius: 17px;
	background-color: rgba(45, 206, 113, 0.2);
	padding: 7px;
}
</style>
