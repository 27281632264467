import $http from '@Config/axios';

const prefix = '/v2/quotations';

export const QuotationPresenter = {
	createOrSaveQuotationB2c: data => $http.post(prefix, data),
	index: ({
		q, status, seller, page, itensPerPage, sort, companyUnit, type
	}) => $http.get(prefix, {
		params: {
			q, status, seller, page, itensPerPage, sort, companyUnit, type
		}
	}),
	show: id => $http.get(`${prefix}/${id}`),
	update: (id, data) => $http.put(`${prefix}/${id}`, data),
	submit: (id, data) => $http.post(`${prefix}/${id}/submissions`, data)
};
