<template>
	<v-container fluid grid-list-lg class="pa-0" gap>
		<v-layout wrap>
			<v-flex>
				<h4 class="subtitle mb-3">Preço total</h4>

				<v-card flat elevation="0" color="#FFF3E2" class="py-3 text-center">
					<v-flex class="d-flex items-center justify-center">
						<span class="font-weight-bold sans">{{ total | dinheiro }}</span>
					</v-flex>
				</v-card>
			</v-flex>
			<v-flex >
				<h4 class="subtitle mb-3">Taxa de entrega</h4>
				<v-card flat elevation="0" color="#FFF3E2" class="py-3 text-center">
					<v-flex class="d-flex items-center justify-center">
						<span class="font-weight-bold sans">{{ shippingPrice | dinheiro }}</span>
					</v-flex>
				</v-card>
			</v-flex>
			<v-flex>
				<h4 class="subtitle mb-3">Desconto</h4>

				<v-card flat elevation="0" color="#FFF3E2" class=" py-3 text-center">
					<v-flex class="d-flex items-center justify-center">
						<span class="font-weight-bold sans">{{ discount | dinheiro }}</span>
					</v-flex>
				</v-card>
			</v-flex>

			<v-flex xs12>
				<v-card flat elevation="0" v-for="(item, index) of items" :key="index" color="#FFF3E2"
					class="cardStyle sans py-3 mb-3 px-4">
					<v-layout align-center>
						<p class="font-weight-bold mb-2 pr-4" style="font-size: 20px">{{ item.closedPackages }}</p>
						<v-flex>
							<span class="smallTitle">{{item.ingredientActive}}</span>
							<p class="font-weight-bold mb-0" style="font-size: 16px">{{ item.name }}</p>
							<p class="mb-0 smallTitle">{{ item.presentation }}</p>
						</v-flex>
						<v-flex class="items-center justify-center">
							<MedicinePrescriptionUtilizationBadge v-if="item.hasPrescriptionUtilization" />
							<MedicinePrescriptionRetentionBadge v-if="item.isControlled" />
							<MedicineGenericBadge v-if="item.isGeneric" />
							<MedicinePbmBadge v-if="item.isPbm" />
						</v-flex>
						<div class="medicine-container">
							<v-flex class="pl-2 px-sm-4" d-flex>
								<span :style="`color: #857B6B`" class="medicine-value px-sm-2 mt-xs-1 py-sm-2"
									:color="'#FFF3E2'">
									{{ item.priceSku | dinheiro }}
								</span>
								<span :style="`color: #039800 `" class="medicine-value px-sm-2 mt-xs-1 py-sm-2"
									:color="'#FFF3E2'">
									{{ item.discount | dinheiro }}
								</span>
								<span :style="`color: #342B1D `" class="medicine-value px-sm-2 mt-xs-1 py-sm-2"
									:color="'#FFF3E2'">
									{{ item.total | dinheiro }}
								</span>
							</v-flex>
						</div>
					</v-layout>
				</v-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import MedicineGenericBadge from '@Views/componentes/medicine-generic-badge.vue';
import MedicinePbmBadge from '@Views/componentes/medicine-pbm-badge.vue';
import MedicinePrescriptionRetentionBadge from '@Views/componentes/medicine-prescription-retention-badge.vue';
import MedicinePrescriptionUtilizationBadge from '@Views/componentes/medicine-prescription-utilization-badge.vue';

export default {
	name: 'PontualDetailProductItem',
	components: {
		MedicinePbmBadge,
		MedicineGenericBadge,
		MedicinePrescriptionRetentionBadge,
		MedicinePrescriptionUtilizationBadge
	},
	props: {
		items: {
			required: false,
			type: Array,
			default: () => ([])
		},
		total: {
			required: true,
			type: Number
		},
		shippingPrice: {
			required: false,
			type: Number,
			default: null
		},
		discount: {
			required: true,
			type: Number
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

.cardStyle {
	width: 100%;
	border-radius: 0px 10px 10px 0px;
	color: rgba(52, 43, 29, 1);
}

.smallTitle {
	font-size: 10px
}

.medicine-container {
	width: 370px;

	.medicine-value {
		font-weight: 600;
		font-size: 16px;
		text-align: center;
	}
}

.iconStyle {
	border-radius: 17px;
	margin: 5px
}

.prescriptionUtilization {
	background-color: rgba(255, 130, 60, 0.3);
	padding: 7px 10px
}
</style>
