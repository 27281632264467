<template>
	<section style="position: relative;">
		<v-text-field
			v-model="search"
			box
			hide-details
			hide-selected
			append-icon="search"
			clearable
			label="Buscar Empresa"
			background-color="white"/>

		<v-card
			v-if="search && items.length"
			full-width
			class="pa-3"
			style="position: absolute;top: 60px; z-index: 1; width: 100%; height: 200px; overflow-y: scroll;">
			<v-data-table
				:headers="headers"
				:items="items"
				hide-actions
				:search="search"
				:loading="loading"
				no-results-text="Nenhum resultado encontrado"
				:no-data-text="loading ? 'Carregando...' : 'Nenhum item cadastrado'">
				<template slot="items" slot-scope="props">
					<tr class="text-xs-left pointer" @click="selectRestHome(props.item.id)">
						<td> {{ props.item.id }} </td>
						<td> {{ props.item.documentNumber }} </td>
						<td> {{ props.item.tradingName }} </td>
					</tr>
				</template>
			</v-data-table>
		</v-card>
	</section>
</template>

<script>
import { debounce, isEqual } from 'lodash';
import { RestHomePresenter } from '@Presenter/v2/rest-home-presenter';

export default {
	props: {
		companyUnitId: {
			required: false,
			default: null
		}
	},
	data: () => ({
		loading: false,
		items: [],
		search: null
	}),
	created() {
		this.reset();
	},
	computed: {
		headers() {
			return [
				{
					text: 'ID',
					align: 'left',
					value: 'id',
					width: '10px',
					sortable: false,
					class: 'font-weight-bold'
				},
				{
					text: 'CNPJ',
					align: 'left',
					value: 'documentNumber',
					width: '150px',
					sortable: false

				},
				{
					text: 'Nome',
					align: 'left',
					value: 'tradingName',
					sortable: false

				}
			];
		},
		getNotextData() {
			if (!this.q)
				return 'Digite alguma coisa';
			if (this.loading)
				return 'Buscando items';

			return 'Nenhuma pessoa encontrada';
		}
	},
	methods: {
		getItems: debounce(async function () {
			try {
				this.loading = true;
				const response = await RestHomePresenter.list({
					filter: {
						q: this.search,
						companyUnitId: this.companyUnitId
					},
					pagination: {
						shouldBePaginated: false
					}
				});
				this.items = response.items;
			} catch (error) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: error.message || 'Erro ao buscar clientes'
				});
			} finally {
				this.loading = false;
			}
		}, 1000),

		async selectRestHome(id) {
			try {
				this.loading = true;
				const response = await RestHomePresenter.getDetail(id);
				this.$emit('handleRestHomeSearch', response);
			} catch (error) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: error.message || 'Erro ao buscar detalhes da empresa'
				});
			} finally {
				this.loading = false;
				this.reset();
			}
		},

		reset() {
			this.items = [];
			this.search = null;
		}
	},
	watch: {
		search: {
			async handler(newVal, oldVal) {
				if (isEqual(newVal, oldVal) || !newVal)
					return;
				await this.getItems();
			}
		}
	}
};
</script>
