import { render, staticRenderFns } from "./approve-pontual-quotation.vue?vue&type=template&id=14d75289&scoped=true"
import script from "./approve-pontual-quotation.vue?vue&type=script&lang=js"
export * from "./approve-pontual-quotation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d75289",
  null
  
)

export default component.exports