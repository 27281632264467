<template>

	<section>
		<v-layout
			class="mt-3 mb-4 styleDiv flex items-center justify-center pointer"
			@click="openSearchProductDialog">
			<span class="inter text-bold caption">
				<v-icon color="black" small class="mr-2">fas fa-plus-circle</v-icon>
				Adicionar produto
			</span>
		</v-layout>

		<v-dialog persistent v-if="isSearchProductDialogOpened" scrollable v-model="isSearchProductDialogOpened"
			transition="fade" max-width="1800px">
			<transition>
				<SearchProductsDialog
					:email="generalData.customer.email"
					:flow="getFlow"
					:subscriptionId="getSubscriptionId"
					:companyUnitId="generalData.extra.companyUnitId"
					:clientType="getClientType"
					@close="closeSearchProductDialog"
					@selectedProduct="prodt => editProduct(prodt)" />
			</transition>
		</v-dialog>

		<v-dialog
			v-if="isProductDialogOpened"
			v-model="isProductDialogOpened"
			scrollable
			persistent
			transition="fade"
			max-width="1200px">
			<transition>
				<EditProductDialog
					:product="product"
					:pricing="product.pricing"
					@handleProduct="addItem"
					@close="closeEditProductDialog"/>
			</transition>
		</v-dialog>
	</section>

</template>

<script>
import { getPropertyValue } from '@Util/ProductService';
import { BUSINESS_B2B_TYPE } from '@Consts/business-type';
import SearchProductsDialog from '../dialog-products.vue';
import EditProductDialog from '../dialog-product/index.vue';

export default {
	components: {
		SearchProductsDialog,
		EditProductDialog
	},
	props: {
		generalData: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			isSearchProductDialogOpened: false,
			isProductDialogOpened: false,
			product: {}
		};
	},
	computed: {
		getClientType() {
			if (this.generalData.extra.restHome && this.generalData.extra.restHome.businessType === BUSINESS_B2B_TYPE.value)
				return 'b2b';

			return 'b2c';
		},
		getFlow() {
			if (this.generalData.extra.restHome && !this.generalData.extra.person.subscription)
				return 'entrada';

			return 'pontual';
		},
		getSubscriptionId() {
			if (this.generalData.extra.person.subscription)
				return this.generalData.extra.person.subscription.id;

			return null;
		}
	},
	methods: {

		openSearchProductDialog() {
			this.isSearchProductDialogOpened = true;
		},

		closeSearchProductDialog() {
			this.isSearchProductDialogOpened = false;
		},

		openEditProductDialog() {
			this.isProductDialogOpened = true;
		},

		closeEditProductDialog() {
			this.isProductDialogOpened = false;
		},

		editProduct(product) {
			const item = {
				pricing: {
					packagePrice: product.price,
					packageDiscount: 0,
					packageAmount: 0
				},
				productId: product.id,
				gtin: product.gtins.shift(),
				name: product.name,
				presentation: product.presentation,
				dosage: product.drug ? product.drug.compositions
					.map(i => i.concentration + i.unitOfMeasurement.symbol)
					.join() : '',
				manufacturer: product.manufacturer.name,
				price: product.price,
				discount: 0,
				priceSku: product.price,
				closedPackages: 0,
				quantityPillsPerPackage: product.drug && getPropertyValue(product.drug.properties, 'isDivisible') ? product.quantity : 1,
				activeIngredient: product.drug && product.drug.compositions
					? product.drug.compositions.map(i => i.activeIngredient.name).join(' + ')
					: 'Sem principio ativo',
				isControlled: product.drug ? !!product.drug.prescriptionRule : false,
				isDivisible: product.drug ? getPropertyValue(product.drug.properties, 'isDivisible') : false,
				isOutOfBox: product.drug ? getPropertyValue(product.drug.properties, 'isOutOfBox') : false,
				hasPrescriptionUtilization: product.hasPrescriptionUtilization // verificar se precisa adicionar
			};
			this.product = item;
			this.closeSearchProductDialog();
			this.openEditProductDialog();
		},

		addItem(item) {
			this.product = {};
			this.closeEditProductDialog();
			this.$emit('addItem', item);
		}

	}
};
</script>

<style lang="scss" scoped>
.styleDiv {
	border: 1.85px dashed rgba(52, 43, 29, 0.4);
	padding: 24px 65px;
	border-radius: 5px;

	&:hover {
		border: 1.85px dashed rgba(52, 43, 29, 1);
		border-radius: 5px;
	}
}
</style>
