import { render, staticRenderFns } from "./table.vue?vue&type=template&id=f4e526aa&scoped=true"
import script from "./table.vue?vue&type=script&lang=js"
export * from "./table.vue?vue&type=script&lang=js"
import style0 from "./table.vue?vue&type=style&index=0&id=f4e526aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4e526aa",
  null
  
)

export default component.exports