<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }">
			<v-icon v-on="on" small color="#E50000" class="prescription-retentition-badge">fas fa-file</v-icon>
		</template>
		<span class="inter text-bold" style="color: #000;">Retenção de receita</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'MedicinePrescriptionRetentionBadge'
};
</script>

<style lang="scss" scoped>
.prescription-retentition-badge {
	border-radius: 17px;
	background-color: rgba(229, 0, 0, 0.2);
	padding: 7px 10px;
}
</style>
