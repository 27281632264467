<template>
	<v-container fluid grid-list-md class="pa-0 mb-2">

		<v-layout v-if="getItems.length" column class="mt-4">
			<ListItem title="itens do pedido" maxWidthTitle="25%" maxWidthHr="66%">
				<Item
					v-for="(item, i) in getItems"
					:key="i"
					:item="item"
					:index="i"
					@editProduct="product => editItem(i, product)"
					@removeProduct="removeItem(i)" />
			</ListItem>
		</v-layout>

		<AddProduct :generalData="generalData" @addItem="addItem" />

		<v-layout wrap>
			<v-flex md6 xs12 class="px-2">
				<v-text-field
					v-model="productData.shippingPrice"
					box background-color="white"
					hide-details label="Valor do frete"
					v-money/>
			</v-flex>

			<!--
			<v-flex md6 xs12 class="px-2">
				<TextDateField
					v-model="productData.deliveryDate"
					box
					background-color="white"
					label="Data de entrega"
					placeholder="dd/mm/yyyy"
					data-vv-name="Data de entrega"
					:validate="{ required: true, data: true }"
					:error-messages="errors.collect('Data de entrega')"/>
			</v-flex>
			-->
		</v-layout>
		<hr class="spacerQuotationStyle">

	</v-container>
</template>

<script>
import ListItem from './list-item.vue';
import Item from './Item/index.vue';

import AddProduct from './add-product/index.vue';

export default {
	name: 'ProductDataPontualQuotation',
	$_veeValidate: { validator: 'new' },
	components: {
		Item, ListItem, AddProduct
	},
	props: {
		productData: {
			required: true,
			type: Object
		},
		generalData: {
			required: true,
			type: Object
		}
	},
	computed: {
		getItems() {
			return this.productData.items;
		}
	},
	methods: {
		addItem(product) {
			this.productData.items.push(product);
		},

		editItem(index, product) {
			this.productData.items.splice(index, 1, product);
		},

		removeItem(index) {
			this.productData.items.splice(index, 1);
		},

		async validate() {
			const hasItems = this.productData.items.length;
			const isValid = await this.$validator.validateAll();
			return !!isValid && hasItems;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
