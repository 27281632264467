<template>
	<Dialog titulo="Pesquisa de produtos" @close="$emit('close', false)">
		<v-layout row wrap>
			<v-flex xs12>
				<v-text-field
					placeholder="Digite o item, ou marca do material"
					label="Pesquise o produto"
					v-model="searchProduct"/>
			</v-flex>
			<v-flex xs12>
				<v-data-table
					:items="products"
					:headers="headers"
					:loading="loadingProducts"
					:rows-per-page-items="[5, 10]"
					rows-per-page-text="Itens por página"
					:pagination.sync="pagination"
					:total-items="totalItems"
					:no-results-text="no_data_text"
					no-data-text="Nenhum medicamento encontrado">
					<template slot="headers" slot-scope="props">
						<tr>
							<th v-for="(header) in props.headers" :key="header.text"
								class="text-xs-left"
								:class="['column', { sortable: header.sortable }, pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
								@click="changeSort(header.value)" :style="!header.sortable ? 'pointer-events: none' : ''">
								{{ header.text }}
								<v-icon small v-if="header.sortable">arrow_upward</v-icon>
							</th>
						</tr>
					</template>
					<template slot="items" slot-scope="props">
						<tr
							class="pointer"
							@click="
								!showCopyIcon
									? selectedProduct(props.item)
									: copyEan(props.item)
							">
							<td :class="{ 'text-center': !props.item.id }">
								{{ props.item.id }}
							</td>
							<td class="text-capitalize">
								{{ props.item.name || "-" }}
								<v-tooltip v-if="props.item.drug && props.item.drug.pbm" top>
									<v-chip class="justify-center" color="orange" style="max-width: 20px; max-height: 20px;" slot="activator" dark
										small><strong>%</strong></v-chip>
									<span>{{ props.item.drug.pbm.program || "PBM" }}</span>
								</v-tooltip>
							</td>
							<td
								class="text-capitalize"
								:class="{ 'text-center': !props.item.drug }">
								{{ nameActiveIngredient(props.item) || "-" }}
							</td>
							<td>
								<v-tooltip top open-on-hover>
									<template v-slot:activator="{ on }">
										<div
											v-on="on"
											@mouseover="showCopyIcon = props.item.id"
											@mouseleave="showCopyIcon = false">
											{{ props.item.gtins ? props.item.gtins.join() : "-" }}
											<Transition name="fade" mode="out-in">
												<v-icon
													size="12px"
													v-show="showCopyIcon === props.item.id"
													class="pl-1"
													v-on="on">fas fa-copy</v-icon>
											</Transition>
										</div>
									</template>
									<span>Copiar Ean/GTIN</span>
								</v-tooltip>
							</td>
							<td :class="{ 'text-center': !props.item.presentation }">
								{{ props.item.presentation || "-" }}
							</td>
							<td :class="{ 'text-center': !props.item.manufacturer.name }">
								{{ props.item.manufacturer.name || "-" }}
							</td>
							<td :class="{ 'text-center': !props.item.drug }">
								{{ productTypeText(props.item) }}
							</td>
							<td :class="{ 'text-center': !props.item.price }">
								{{ props.item.price | dinheiro }}
							</td>
							<td class="text-center">
								<v-tooltip right>
									<template v-slot:activator="{ on }">
										<v-icon
											class="mx-0 white--text pointer"
											v-on="on"
											:color="getColorStatus(props.item.availability)">
											fas fa-circle
										</v-icon>
									</template>
									<span>{{ `${nameStatus(props.item.availability)}` }}</span>
								</v-tooltip>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-flex>
			<v-flex xs12 class="subtitle">
				<v-icon class="mr-1" color="success">fas fa-circle</v-icon>
				<span class="mr-3">Disponível</span>
				<v-icon class="mr-1" color="grey">fas fa-circle</v-icon>
				<span class="mr-3">Sujeito a disponibilidade</span>
				<v-icon class="mr-1" color="red">fas fa-circle</v-icon>
				<span class="mr-3">Em falta</span>
			</v-flex>
		</v-layout>
	</Dialog>
</template>

<script>
import { copyToClipboard } from '@Util/functions';
import { debounce } from 'lodash';
import { ProductPresenter } from '@Presenter/product-presenter';
import { availabilityKeys } from '@Consts/availabities';

export default {
	name: 'DialogProducts',
	props: {
		flow: {
			required: false,
			type: String,
			default: 'pontual'
		},
		subscriptionId: {
			required: false,
			type: Number,
			default: null
		},
		clientType: {
			required: true,
			type: String
		},
		companyUnitId: {
			required: true,
			type: Number
		},
		email: {
			required: false,
			type: String,
			default: null
		}
	},
	data: () => ({
		products: [],
		loadingProducts: false,
		searchProduct: '',
		error: false,
		headers: [
			{ text: 'ID', value: 'id', sortable: false },
			{ text: 'Produto', value: 'name', sortable: true },
			{
				text: 'Princípio ativo',
				value: 'activePrinciple',
				sortable: false
			},
			{
				text: 'Ean/GTIN',
				value: 'gtin',
				sortable: false,
				width: '15%'
			},
			{ text: 'Apresentação', value: 'presentation', sortable: true },
			{ text: 'Laboratório/Marca', value: 'brand', sortable: true },
			{ text: 'Tipo do produto', value: 'productType', sortable: false },
			{
				text: 'Preço',
				value: 'price',
				sortable: false,
				width: '12%'
			},
			{ text: 'Disponibilidade', value: 'availability', sortable: false }
		],
		pagination: {},
		totalItems: 0,
		showCopyIcon: 0
	}),
	methods: {
		callGetProducts: debounce(async function (search) {
			await this.getProducts(search);
		}, 500),
		async getProducts() {
			try {
				this.loadingProducts = true;

				const productParams = {
					q: this.searchProduct,
					companyUnitId: this.companyUnitId,
					perPage: this.pagination.rowsPerPage,
					page: this.pagination.page,
					sortBy: this.pagination.sortBy,
					sortDirection: this.pagination.descending,
					flow: this.flow,
					clientType: this.clientType,
					email: this.email,
					subscriptionId: this.subscriptionId
				};

				const productsResponse = await ProductPresenter.getProducts(productParams);
				this.products = productsResponse.items;
				this.totalItems = productsResponse.total;
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Erro ao buscar produtos',
					color: 'error'
				});
			} finally {
				this.loadingProducts = false;
			}
		},
		nameStatus(availability) {
			switch (availability) {
				case availabilityKeys.AVAILABLE:
					return 'DISPONÍVEL';
				case availabilityKeys.UNAVAILABLE:
					return 'EM FALTA';
				default:
					return 'NÃO VERIFICADO';
			}
		},
		getColorStatus(availability) {
			switch (availability) {
				case availabilityKeys.AVAILABLE:
					return 'success';
				case availabilityKeys.UNAVAILABLE:
					return 'red';
				default:
					return 'gray';
			}
		},
		nameActiveIngredient(product) {
			if (product.drug && product.drug.compositions) {
				return product.drug.compositions
					.map(item => item.activeIngredient.name)
					.join(' + ');
			}
			return '';
		},
		copyEan(item) {
			copyToClipboard(item.gtins[0], `${item.name}: Ean`);
		},
		selectedProduct(item) {
			if (item.price === 0) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Este item não está precificado',
					color: 'error'
				});
			}

			const itemUnavailable = item.availability === availabilityKeys.UNAVAILABLE || item.availability === availabilityKeys.DISCONTINUED;
			if (itemUnavailable) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Este item está indisponível',
					color: 'error'
				});
			}

			/* if (this.quotation.items && this.quotation.items.length > 0) {
				const hasItem = this.quotation.items.find(
					prodt => prodt.id === item.id
				);

				if (hasItem) {
					return this.$store.dispatch('SHOW_SNACKBAR', {
						message: 'Este item ja foi adicionado no orçamento',
						color: 'error'
					});
				}
			}
				*/

			return this.$emit('selectedProduct', item);
		},
		productTypeText(product) {
			return product.drug && product.drug.type ? product.drug.type : '-';
		},
		changeSort(column) {
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		}
	},
	watch: {
		pagination: {
			async handler() {
				await this.getProducts();
			},
			deep: true
		},
		searchProduct(search) {
			if (this.searchProduct) {
				this.loadingProducts = true;
				this.callGetProducts(search || '');
			} else
				this.products = [];
		}
	},
	computed: {
		no_data_text() {
			if (this.loadingProducts)
				return 'Carregando...';
			if (this.error)
				return 'Erro ao carregar. Por favor, tente novamente';
			if (this.products.length === 0 && !this.searchProduct)
				return 'Utilize o campo acima para buscar um medicamento';
			return 'Nenhum medicamento encontrado';
		}
	}
};
</script>

<style lang="scss" scoped>
	.subtitle {
		display: flex;
		align-items: center;
	}
</style>
