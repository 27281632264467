<template>
	<v-dialog
		persistent
		scrollable
		v-model="opened"
		transition="fade"
		max-width="500px">

		<template v-slot:activator="{ on }">
			<v-btn
				v-on="on"
				outline
				flat
				:loading="loading"
				style="color: #342b1d; border-radius: 5px"
				@click="open">
				Salvar <v-icon small right>fas fa-save</v-icon>
			</v-btn>
		</template>

		<v-flex class="dialogStyle">
			<v-layout column wrap class="d-flex items-center justify-center">
				<v-flex xs12 class="imgStyle d-flex items-center mb-4">
					<img src="/img/icons/exclamation-icon.svg" width="42" height="42" alt="Imagem de exclamação, sugerindo atenção">
				</v-flex>
				<v-flex xs12 class="mb-2 text-center sans">
					<span class=" text-bold title"> Você tem certeza que deseja salvar?</span>
					<p>Os dados do cliente e/ou ILPI, bem como a opção de pagamento, não poderão ser alterados posteriormente ao salvamento.</p>
				</v-flex>
				<v-flex xs12 class="d-flex items-center mt-3">
					<v-btn color="primary" @click="cancel">Cancelar</v-btn>
					<v-btn :loading="loading" @click="confirm">Salvar</v-btn>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-dialog>

</template>

<script>
export default {
	name: 'DialogSaveQuotation',
	props: {
		loading: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			opened: false
		};
	},
	methods: {
		open() {
			this.opened = true;
		},
		close() {
			this.opened = false;
		},
		confirm() {
			this.$emit('saveQuotation');
			this.close();
		},
		cancel() {
			this.close();
		}
	}
};
</script>

<style lang="scss" scoped>
.dialogStyle {
	padding: 20px 50px;
	border-radius: 20px;
	background: #FFF3E2;
	border: 2px solid #FFC875
}

.imgStyle {
	background-color: rgba(255, 200, 117, 0.6);
	padding: 15px;
	border-radius: 40px;
}
</style>
