<template>
	<v-flex xs12 style="padding: 0px !important">
		<v-card color="#FFF3E2" class="cardStyle sans" :class="isOutOfBox ? 'outOfBoxStyle' : '' ">
			<v-layout wrap class="full-height">
				<v-flex xs2 class="q-column items-center fit imageStyle" :class="isOutOfBox ? 'imgBorderOutOfBoxStyle' : 'imgBorderStyle'" :style="isOutOfBox ? 'padding: 20px !important' : 'padding: 30px !important'">
					<span v-if="isOutOfBox" class="textOutOfBox mb-1 text-bold caption sans">Fora da box</span>
					<img
						src="/img/default-medicine-box.svg" width="100%" height="100" alt="Imagem do produto"/>
				</v-flex>
				<v-flex xs10 class="items-center justify-space-between" :style="!isOutOfBox ? 'padding: 20px !important' : 'padding: 30px !important'">
					<v-layout wrap>
						<v-flex xs6>
							<p class="subheading text-bold mb-0 text-uppercase">{{name}}</p>
							<p class="body-1">{{ activeIngredient }}</p>
						</v-flex>
						<v-flex xs4>
							<p class="mb-0">{{presentation}}</p>
							<p class="mb-0">{{textQuantity}}</p>
							<p class="mb-0">{{manufacturer}}</p>
						</v-flex>
						<v-flex xs2 class="flex-center">
							<MedicineGenericBadge v-if="isGeneric" />
							<MedicinePbmBadge v-if="isPbm" />
							<MedicinePrescriptionRetentionBadge v-if="isControlled" />
							<MedicinePrescriptionUtilizationBadge v-if="hasPrescriptionUtilization" />
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-card>
	</v-flex>

</template>

<script>
import MedicinePrescriptionUtilizationBadge from '@Componentes/medicine-prescription-utilization-badge.vue';
import MedicinePrescriptionRetentionBadge from '@Componentes/medicine-prescription-retention-badge.vue';
import MedicineGenericBadge from '@Componentes/medicine-generic-badge.vue';
import MedicinePbmBadge from '@Componentes/medicine-pbm-badge.vue';

export default {
	name: 'CardProduct',
	components: {
		MedicinePbmBadge,
		MedicineGenericBadge,
		MedicinePrescriptionRetentionBadge,
		MedicinePrescriptionUtilizationBadge
	},
	props: {
		item: {
			required: true,
			type: Object
		}
	},
	computed: {
		name() {
			return this.item.name;
		},
		presentation() {
			return this.item.presentation;
		},
		manufacturer() {
			return this.item.manufacturer;
		},
		activeIngredient() {
			return this.item.activeIngredient;
		},
		textQuantity() {
			return `${this.item.pricing.packageAmount} Caixa(s)`;
		},
		isOutOfBox() {
			return this.item.isOutOfBox;
		},
		isPbm() {
			return this.item.isPbm;
		},
		isGeneric() {
			return this.item.isGeneric;
		},
		isControlled() {
			return this.item.isControlled;
		},
		hasPrescriptionUtilization() {
			return this.item.hasPrescriptionUtilization;
		}
	}
};
</script>

<style lang="scss" scoped>
.cardStyle {
	border-radius: 0px 10px 10px 0px;
	color: rgba(52, 43, 29, 1);
	padding: 12px;
}

.textOutOfBox {
	position: relative;
	top: -22px;
	background-color: #FFC875;
	border-radius: 0px 0px 4px 4px;
	padding: 2px 4px;
}

.imageStyle {
	background-color: white;
	padding-top: 0px !important;
}

.imgBorderStyle {
	border-radius: 10px 0px 0px 10px;
	border: 1px solid #DCDCDC;
}

.imgBorderOutOfBoxStyle {
	border-radius: 10px 0px 0px 10px;
	border-right: 2px dashed rgba(255, 200, 117, 1) !important;
}

.outOfBoxStyle {
		border: 2px dashed rgba(255, 200, 117, 1) !important;
		border-radius: 0px 10px 10px 0px;
	}

.iconStyle {
		border-radius: 17px;
		margin: 5px
	}

	.generic {
		background-color: #E7EAFF;
		padding: 7px;
	}

	.percentage {
		background-color: rgba(45, 206, 113, 0.2);
		padding: 7px;
	}

	.receipt {
		background-color: rgba(229, 0, 0, 0.2);
		padding: 7px 10px;
	}

	.exclamation {
		background-color: rgba(255, 200, 117, 0.4);
		padding: 7px;
	}

</style>
