<template>
	<section class="">
		<PageHeader
			@reload="getPontualQuotations"
			:sellers="sellers"
			:restHomes="restHomes"
			@handleFilter="headerFilterParams => updateFilters(headerFilterParams)"/>

		<PageTable
			:loading="isLoading"
			:items="items"
			:total="total"
			@openDetailDetailPontualQuotationDialog="setQueryParam"
			@handleFilter="tableFilterParams => updateFilters(tableFilterParams)"/>

		<LoaderDialog v-if="showLoader" />

		<v-dialog
			v-model="isPontualDetailDialogOpened"
			scrollable
			persistent
			transition="fade"
			max-width="900px">
			<transition>
				<PontualQuotationDetail
					v-if="isPontualDetailDialogOpened"
					:quotation="quotation"
					@close="closeDialogDetail"
					@handleCancel="handleCancel"
					@handleApprove="handleApprove" />
			</transition>
		</v-dialog>
	</section>
</template>

<script>
import { isEqual, debounce } from 'lodash';
import LoaderDialog from '@Componentes/loader-dialog';
import { PontualQuotationPresenter } from '@Presenter/v2/pontual-quotation-presenter';
import { UserPresenter } from '@Presenter/user-presenter';
import { RestHomePresenter } from '@Presenter/v2/rest-home-presenter';
import { getCurrentCompanyUnit } from '@Config/unidades';
import PontualQuotationDetail from './dialog-detail/index.vue';
import PageHeader from './header';
import PageTable from './table';

export default {
	name: 'PontualQuotationPage',
	components: {
		PageHeader, PageTable, LoaderDialog, PontualQuotationDetail
	},
	async created() {
		await Promise.all([
			this.getSellers(),
			this.getRestHomes(),
			this.getPontualQuotations()
		]);

		if (this.$route.query.id_detalhes)
			await this.onOpenDetailDetailPontualQuotationDialog(this.$route.query.id_detalhes);
	},
	data() {
		return {
			isLoading: false,
			showLoader: false,
			filterParams: {
				pagination: {},
				sorting: {},
				filter: {}
			},
			items: [],
			sellers: [],
			restHomes: [],
			isPontualDetailDialogOpened: false,
			isPontualUpdateDialogOpened: false,
			total: 0
		};
	},
	methods: {
		updateFilters(filters) {
			this.filterParams = {
				...this.filterParams,
				...filters
			};
		},

		setQueryParam(quotationId) {
			this.$router.replace({ query: { id_detalhes: quotationId } });
		},

		getPontualQuotations: debounce(async function () {
			this.isLoading = true;
			const params = {
				filter: this.filterParams.filter,
				pagination: this.filterParams.pagination,
				sorting: this.filterParams.sorting
			};

			try {
				const response = await PontualQuotationPresenter.list(params);
				this.items = response.items;
				this.total = response.total;
			} catch (e) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						message: e.message || 'Erro ao buscar orçamentos pontuais.',
						color: 'error'
					}
				);
			} finally {
				this.isLoading = false;
			}
		}, 500),

		async getSellers() {
			try {
				const response = await UserPresenter.index();
				this.sellers = response.filter(item => !!item.telefone).map(seller => ({ id: seller.id, name: seller.nome, phone: seller.telefone }));
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'error',
						message: 'Erro ao buscar vendedores'
					}
				);
			}
		},

		async getRestHomes() {
			try {
				const response = await RestHomePresenter.list({
					pagination: {
						all: true
					},
					filter: {
						companyUnitId: getCurrentCompanyUnit().id
					}
				});
				this.restHomes = response.items;
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'error',
						message: 'Erro ao buscar casas de repouso'
					}
				);
			}
		},

		async onOpenDetailDetailPontualQuotationDialog(quotationId) {
			this.isLoading = true;
			this.showLoader = true;
			try {
				const response = await PontualQuotationPresenter.getDetail(quotationId);
				this.quotation = response;
				this.openDialogDetail();
			} catch (e) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						message: e.message || 'Erro ao buscar orçamento pontual.',
						color: 'error'
					}
				);
			} finally {
				this.isLoading = false;
				this.showLoader = false;
			}
		},

		async handleCancel() {
			this.isLoading = true;
			this.showLoader = true;
			try {
				await PontualQuotationPresenter.cancel(this.quotation.id);
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'success',
					message: 'Orçamento cancelado com sucesso!'
				});
				this.closeDialogDetail();
				await this.getPontualQuotations();
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						message: error.message || 'Erro ao cancelar orçamento pontual.',
						color: 'error'
					}
				);
			} finally {
				this.isLoading = false;
				this.showLoader = false;
			}
		},

		async handleApprove(prescriptions) {
			this.isLoading = true;
			this.showLoader = true;
			try {
				await PontualQuotationPresenter.approve(
					this.quotation.id,
					{
						prescriptions: prescriptions.map(item => ({
							notificationNumber: item.notificationNumber,
							prescribedDate: item.prescribedDate,
							prescriber: {
								id: item.prescriber.id,
								name: item.prescriber.name,
								number: item.prescriber.number,
								state: item.prescriber.uf,
								type: item.prescriber.typeOfRegister
							}
						}))
					}
				);
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'success',
					message: 'Orçamento aprovado e liberado com sucesso!'
				});
				this.closeDialogDetail();
				await this.getPontualQuotations();
			} catch (error) {
				this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						message: error.message || 'Erro ao aprovar/liberar orçamento pontual.',
						color: 'error'
					}
				);
			} finally {
				this.isLoading = false;
				this.showLoader = false;
			}
		},

		openDialogDetail() {
			this.isPontualDetailDialogOpened = true;
		},

		closeDialogDetail() {
			this.isPontualDetailDialogOpened = false;
			if (this.$route.query.id_detalhes)
				this.$router.replace({ query: null });
			this.quotation = null;
		}
	},
	watch: {
		filterParams: {
			deep: true,
			async handler(newVal, oldVal) {
				if (isEqual(newVal, oldVal))
					return;
				await this.getPontualQuotations();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
