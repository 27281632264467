<template>
	<Dialog titulo="Produto" @close="$emit('close')" background="#FFEACC">
		<v-container fluid grid-list-xl>
			<v-layout row wrap>
				<CardProduct :item="product" />
				<Values
					:pricing="pricing"
					:discountTypeDefault="getDiscountTypeDefault"
					:discountValueDefault="getDiscountValueDefault"/>

				<hr class="spacerQuotationStyle">

				<v-flex md3 xs12 class="d-flex q-column">
					<span class="inter text-bold body-1 mb-3">Quantidade de caixas</span>
					<v-autocomplete
						v-model="pricing.packageAmount"
						:items="quantityOfBoxes"
						box
						placeholder="Digite para filtrar..."
						clearable
						item-value="value"
						item-text="label"
						label="Quantidade de caixas"
						data-vv-name="Desconto"
						v-validate="{required: true}"
						background-color="white"
						:error-messages="errors.collect('Desconto')"/>
				</v-flex>
				<hr class="spacerQuotationStyle">
				<v-flex xs12>
					<v-layout class="mt-4">
						<v-flex xs10>
							<v-btn class="px-5 my-0" @click="$emit('close')">Cancelar</v-btn>
						</v-flex>
						<v-flex class="d-flex items-end">
							<v-btn class="px-5 my-0" color="primary" @click="addProduct">Salvar</v-btn>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>

import { quantityOfBoxes } from '@Consts/quotation-v2';
import Values from './values.vue';
import CardProduct from './card-product.vue';

export default {
	name: 'DialogProductAndPosology',
	$_veeValidate: { validator: 'new' },
	components: {
		Values, CardProduct
	},
	props: {
		product: {
			required: true,
			type: Object
		},
		pricing: {
			required: true,
			type: Object
		},
		discountTypeDefault: {
			required: false,
			default: null
		},
		discountValueDefault: {
			required: false,
			default: 0
		}
	},

	created() {
		if (this.discountTypeDefault)
			this.discountType = this.discountTypeDefault;
	},
	data() {
		return {
			quantityOfBoxes,
			dialogRemoveProduct: false,
			dialogSelectDiscount: false
		};
	},
	computed: {
		getDiscountTypeDefault() {
			return this.discountTypeDefault;
		},
		getDiscountValueDefault() {
			return this.discountValueDefault;
		}
	},
	methods: {
		async addProduct() {
			const item = {
				...this.product,
				pricing: {
					...this.product.pricing
					/*
					packagePrice: this.$functions.onlyNumber(this.product.pricing.packagePrice, true),
					discountValue: this.$functions.onlyNumber(this.product.pricing.discountValue, true),
					finalPrice: this.$functions.onlyNumber(this.product.pricing.finalPrice, true)
					*/
				}
			};

			if (!item.pricing.packageAmount) {
				return this.$store.dispatch(
					'SHOW_SNACKBAR',
					{
						color: 'error',
						message: 'Informe a quantidade de caixas'
					}
				);
			}

			this.$emit('handleProduct', item);
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
