<template>
	<v-dialog
		v-model="opened"
		transition="fade"
		max-width="600px">
		<template v-slot:activator="{ on }">
			<v-icon

				v-on="on"
				color="white"
				style="cursor: pointer" @click="open">
				close
			</v-icon>
		</template>

		<section class="farme-dialog" style="border: 2px solid #E50000">
			<section class="farme-dialog-body py-5">
				<v-layout column wrap class="d-flex items-center justify-center">
					<v-flex xs12 class="imgStyle d-flex items-center mb-4 bg-error pa-3" style="background-color: red; border-radius: 50%;">
						<v-icon x-large color="white">
							report_gmailerrorred
						</v-icon>
					</v-flex>
					<v-flex xs12 class="text-center sans">
						<h5 class="title font-weight-bold mb-4">Você tem certeza que deseja <br> remover essa receita?</h5>
						<p class="font-weight-light">
							Esta ação não poderá ser desfeita.
						</p>
					</v-flex>
					<v-flex xs12 class="d-flex items-center mt-3">
						<v-btn dark depressed @click="close">Cancelar</v-btn>
						<v-btn color="error" depressed @click="confirm">Remover</v-btn>
					</v-flex>
				</v-layout>
			</section>
		</section>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			opened: false
		};
	},
	methods: {
		open() {
			this.opened = true;
		},
		close() {
			this.opened = false;
		},
		confirm() {
			this.$emit('handleConfirm');
			this.close();
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
