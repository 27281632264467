<template>
	<v-layout wrap class="mt-5">

		<v-flex md3 xs12 class="d-flex q-column">
			<span class="inter text-bold body-1 mb-3">Preço da caixa</span>
			<v-text-field
				:value="pricing.packagePrice"
				readonly
				disabled
				box
				background-color="white"
				label="Preço da Caixa *"
				v-money
				data-vv-name="boxPrice"
				v-validate="{ required: true }"/>
		</v-flex>

		<v-flex md3 xs12 class="d-flex q-column">
			<span class="inter text-bold body-1 mb-3">Tipo de desconto</span>
			<v-autocomplete
				box
				clearable
				v-model="discountType"
				:items="options"
				item-value="value"
				item-text="text"
				background-color="white"
				label="Tipo de desconto"
				data-vv-name="Tipo de desconto"
				v-validate="{ required: false }"
				:error-messages="errors.collect('Tipo de desconto')"
				@change="() => discountValue = 0"/>
		</v-flex>

		<v-flex md3 xs12 class="d-flex q-column">
			<span class="inter text-bold body-1 mb-3">Valor do desconto</span>
			<v-text-field
				v-model="discountValue"
				v-money="isRealValueDiscountType"
				background-color="white"
				box
				label="Valor do desconto"
				:disabled="!discountType"
				data-vv-name="Desconto"
				v-validate="discountValueValidations"
				:append-icon="getDiscountTypeIcon"
				:error-messages="errors.collect('Desconto')"
				@input=" value => setDiscount($functions.onlyNumber(value))"/>
		</v-flex>

		<v-flex md3 xs12 class="d-flex q-column">
			<span class="inter text-bold body-1 mb-3">Preço Unitário Final</span>
			<v-text-field
				:value="$functions.mascaraDinheiro(getFinalPackagePrice)"
				readonly
				disabled
				background-color="white"
				box
				label="Preço final"/>
		</v-flex>
	</v-layout>
</template>

<script>
import { DISCOUNT_AMOUNT_TYPE, DISCOUNT_PERCENTAGE_TYPE, DISCOUNT_TYPES } from '@Consts/discount-options';
import { CalculateDiscount } from '@Util/calculate-discount';

export default {
	name: 'ProductValues',
	$_veeValidate: { validator: 'new' },
	props: {
		pricing: {
			required: true,
			type: Object
		},
		discountTypeDefault: {
			required: false,
			default: null
		},
		discountValueDefault: {
			required: false,
			default: 0
		}
	},
	data() {
		return {
			discountType: null,
			discountValue: 0
		};
	},
	created() {
		if (this.discountTypeDefault)
			this.discountType = this.discountTypeDefault;
		if (this.discountValueDefault)
			this.discountValue = this.discountValueDefault;
	},
	computed: {
		options() {
			return DISCOUNT_TYPES.map(option => ({ text: option.text, value: option.value }));
		},
		showDiscountType() {
			return this.showDiscountText;
		},
		getDiscountTypeIcon() {
			if (!this.isPercentageDiscountType)
				return null;
			return this.isPercentageDiscountType ? 'percentage' : 'attach_money';
		},
		isPercentageDiscountType() {
			return this.discountType === DISCOUNT_PERCENTAGE_TYPE.value;
		},
		isRealValueDiscountType() {
			return this.discountType === DISCOUNT_AMOUNT_TYPE.value;
		},
		discountValueValidations() {
			const validations = {
				required: true

			};
			if (this.isPercentageDiscountType) {
				validations.gte = 0;
				validations.lte = 100;
			}

			if (this.isRealValueDiscountType) {
				validations.gte = 0;
				validations.lte = this.$functions.onlyNumber(this.pricing.packagePrice);
			}

			return validations;
		},
		getFinalPackagePrice() {
			return this.pricing.packagePrice - this.pricing.packageDiscount;
		}
	},
	methods: {
		setDiscount(discountValue) {
			const discount = this.discountType
				? CalculateDiscount.calcule(
					this.pricing.packagePrice,
					this.discountType,
					discountValue
				) : 0;
			this.pricing.packageDiscount = discount;
		}
	}
};
</script>
