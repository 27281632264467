<template>
	<v-chip
		label
		:color="getColor"
		:small="small"
		text-color="white"
		style="border-radius: 10px;">
		<section style="display: flex; align-items: center;">
			<v-icon class="ma-0" :small="small">house</v-icon>
			<span class="font-weight-bold inter">
				{{ type }}
			</span>
		</section>
	</v-chip>
</template>

<script>
export default {
	props: {
		type: {
			required: true,
			type: String
		},
		small: {
			required: false,
			default: false
		}
	},
	computed: {
		getColor() {
			switch (this.type) {
				case 'B2B':
					return '#E50000';
				case 'B2C':
					return '#00B503';
				case 'B2B2C':
					return '#FF823C';
				default:
					throw new Error('Tipo de negócio desconhecido');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
section {
	display: flex;
	align-items: center;
}
</style>
