<template>
	<Dialog :titulo="!is_new ? 'Editar responsável' : 'Cadastrar responsável'"
		:btn_acao="!is_new ? 'Editar' : 'Cadastrar'" :btn_acao_disable="!habilitarBtnSalvar"
		@acao="submeter()"
		@excluir="modal_excluir = true" @close="fechaModal()" :loading="salvando">
		<div v-if="loading" class="loading">
			<v-progress-circular indeterminate color="primary" />
		</div>
		<v-container v-else fluid grid-list-lg class="px-3 pt-1 pb-0">
			<v-layout v-if="is_new" row wrap>
				<v-flex xs12 md12>
					<v-autocomplete
						v-model="farmembro"
						box
						color="green"
						label="Buscar Farmembro"
						append-icon="search"
						:loading="carrega_farmembros"
						:item-value="'id'"
						:item-text="'nome'"
						:items="farmembros"
						:return-object="true"></v-autocomplete>
				</v-flex>
			</v-layout>
			<v-layout v-if="is_new" align-center row wrap>
				<v-flex xs12 md12>
					<h3>Tipo de responsável:
						<v-tooltip right :max-width="350">
							<template v-slot:activator="{ on }">
								<v-icon color="#3C235C" style="font-size: 18px" class="mr-2" v-on="on">far fa-question-circle</v-icon>
							</template>
							<span class="tooltip-responsaveis"><p class="tooltip-font"><strong>Responsável Faturamento:</strong> para quem a nota fiscal será faturada. É permitido apenas uma pessoa responsável por farmembro.</p>

								<p class="tooltip-font"><strong>Responsável Clínico:</strong> é a pessoa que mais acompanha a saúde do farmembro (ex.: familiar, cuidador ou enfermeiro) é permitido apenas um responsável clínico por farmembro.</p>

								<p class="tooltip-font"><strong>Responsável Financeiro:</strong> é quem faz o pagamento da assinatura e para quem o boleto será direcionado. É permitido apenas um por farmembro.</p>

								<p style="font-weight: 400; font-size: 10px">* O boleto aparecerá conforme dados cadastrados no IUGU.</p> </span>
						</v-tooltip>
					</h3>
				</v-flex>
				<v-layout class="alinha-checkbox" row wrap>
					<v-checkbox v-model="tipo.clinico" label="Clínico"></v-checkbox>
					<v-checkbox v-model="tipo.faturamento" label="Faturamento"></v-checkbox>
					<v-checkbox v-model="tipo.financeiro" label="Financeiro"></v-checkbox>
				</v-layout>
			</v-layout>
			<Form :campos="campos" :item="item" />
		</v-container>

	</Dialog>
</template>

<script>
import { CRUDMixin } from '@Mixins/crud';
import { PacientePresenter } from '@Presenter/paciente-presenter';

export default {
	name: 'DialogResponsavel',
	mixins: [CRUDMixin],
	data: () => ({
		type: 'responsavel',
		fields_type: 'responsavel',
		loading: false,
		carrega_farmembros: true,
		farmembros: [],
		farmembro: {},
		tipo: {
			clinico: false,
			faturamento: false,
			financeiro: false
		}
	}),
	created() {
		this.buscaFarmembros();
	},
	watch: {
		campos: {
			handler() {
				if (!this.is_new)
					delete this.campos.vinculo;
			},
			deep: true
		}
	},
	methods: {
		buscaFarmembros() {
			PacientePresenter.index()
				.then(response => {
					this.farmembros = response.items || [];
				})
				.finally(() => {
					this.carrega_farmembros = false;
				});
		},
		createData() {
			if (this.is_new) {
				return {
					...this.item,
					paciente_id: this.farmembro.id,
					tipo: this.tipo
				};
			}
			return {
				id: this.item.id,
				cpf: this.item.cpf,
				nome: this.item.nome,
				email: this.item.email,
				telefone: this.item.telefone
			};
		},
		definirResponsavelPrincipal(habilitado) {
			this.dialog_confirmacao = habilitado;
		}
	},
	computed: {
		habilitarBtnSalvar() {
			return this.habilitarBtnAcao
			|| (this.is_new && this.habilitarBtnAcao && this.tem_farmembro);
		},
		tem_farmembro() {
			return (this.farmembro || {});
		}
	}
};
</script>

<style lang="scss" scoped>
.alinha-checkbox {
	padding-left: 16px;
	padding-right: 17px;
}

.tooltip-responsaveis {
	padding: 15px;
}

.tooltip-font {
	padding-right: 10px;
	padding-left: 10px;
	text-align: center;
	font-size: 12px;
	font-weight: 700;
}
</style>
