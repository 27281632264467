<template>
	<v-flex xs12 class="full-width mb-3" style="padding: 0px !important">
		<v-card
			color="#FFF3E2"
			class="cardStyle sans"
			:class="isOutOfBox ? 'outOfBoxStyle' : ''">
			<v-layout wrap class="full-height">
				<v-flex xs2 class="q-column items-center fit imageStyle" :class="isOutOfBox ? 'imgBorderOutOfBoxStyle' : 'imgBorderStyle'" :style="isOutOfBox ? 'padding: 20px !important' : 'padding: 10px !important'">
					<span v-if="isOutOfBox" class="textOutOfBox mb-1 text-bold caption sans">Fora da box</span>
					<img
						src="/img/default-medicine-box.svg" width="100%" height="180" alt="Imagem do produto"/>
				</v-flex>
				<v-flex xs10 class="justify-space-between" :style="!isOutOfBox ? 'padding: 0px !important' : 'padding: 20px !important'">
					<v-card-title class="pt-4 pb-0">
						<v-layout>
							<ProductInfo :item="item" />

							<v-flex xs2 class="mx-3">
								<v-layout column>
									<v-flex>
										<p class="mb-0 inter text-bold-medium titleOpacity">Quantidade</p>
										<p class="mb-0 text-bold subheading titleOpacity">
											<v-icon small color="#000" class="pr-1">inventory_2</v-icon> {{item.pricing.packageAmount}} caixa(s)
										</p>
									</v-flex>

									<v-flex>
										<p class="mb-0 inter text-bold-medium titleOpacity">Preço da Caixa</p>
										<p class="mb-0 text-bold subheading titleOpacity">
											<v-icon small color="#000" class="pr-1">inventory_2</v-icon> {{item.pricing.packagePrice | dinheiro}}
										</p>
									</v-flex>
								</v-layout>
							</v-flex>

							<v-flex xs3 class="mx-3">
								<v-layout column>
									<v-flex>
										<p class="mb-0 inter text-bold-medium titleOpacity">ID: {{item.productId}}</p>
									</v-flex>
									<v-flex>
										<p class="mb-0 inter text-bold-medium titleOpacity">Subtotal do item</p>
										<p v-if="hasDiscount" class="mb-0 text-bold subheading" :class="{'lineThrough caption' : true}">{{ getSubtotal | dinheiro }}</p>
										<p  class="mb-0 text-bold title colorfinalPrice">{{ getTotal | dinheiro }}</p>
									</v-flex>
									<v-flex v-if="hasDiscount">
										<p class="mb-0 inter text-bold-medium titleOpacity">Desconto</p>
										<p class="mb-0 text-bold subheading titleOpacity">{{ getDiscount | dinheiro }}</p>
									</v-flex>
								</v-layout>
							</v-flex>

							<v-flex xs1 class="d-flex items-center justify-center">
								<div class="d-flex q-column items-center">
									<RemoveProduct @removeProduct="removeProduct" />
									<EditProduct :product="item" :pricing="item.pricing" @editProduct="editProduct" />
								</div>
							</v-flex>
						</v-layout>
					</v-card-title>
				</v-flex>
			</v-layout>
		</v-card>
	</v-flex>
</template>

<script>
import { getPropertyValue } from '@Util/ProductService';
import ProductInfo from './product-info.vue';
import RemoveProduct from './remove-product/index.vue';
import EditProduct from './edit-product/index.vue';

export default {
	name: 'ItemQuotationV2',
	components: { ProductInfo, RemoveProduct, EditProduct },
	props: {
		item: {
			type: Object,
			default: () => {}
		},
		index: {
			type: Number
		}
	},

	methods: {
		removeProduct() {
			this.$emit('removeProduct');
		},
		editProduct(product) {
			this.$emit('editProduct', product);
		}
	},
	computed: {
		isMedicine() {
			return this.item.drug;
		},
		isOutOfBox() {
			return this.isMedicine ? Boolean(getPropertyValue(this.item.drug.properties, 'isOutOfBox')) : false;
		},

		getPackageAmount() {
			return +this.item.pricing.packageAmount;
		},

		getPackagePrice() {
			return +this.item.pricing.packagePrice;
		},

		getPackageDiscount() {
			return +this.item.pricing.packageDiscount;
		},

		getPackageFinalPrice() {
			return this.getPackagePrice - this.getPackageDiscount;
		},

		hasDiscount() {
			return !!this.getPackageDiscount;
		},

		getSubtotal() {
			return this.getPackagePrice * this.getPackageAmount;
		},

		getTotal() {
			return this.getPackageFinalPrice * this.getPackageAmount;
		},

		getDiscount() {
			return this.getPackageDiscount * this.getPackageAmount;
		}
	}
};
</script>

<style lang="scss" scoped>
	.cardStyle {
		border-radius: 0px 10px 10px 0px;
		color: rgba(52, 43, 29, 1);
		padding: 5px;
	}

	.imageStyle {
		background-color: white;
		padding-top: 0px !important;
	}

	.textOutOfBox {
		position: relative;
		top: -23px;
		left: 2px;
		background-color: #FFC875;
		border-radius: 0px 0px 4px 4px;
		padding: 2px 4px;
	}

	.outOfBoxStyle {
		border: 2px dashed rgba(255, 200, 117, 1) !important;
		border-radius: 0px 10px 10px 0px;
	}

	.imgBorderStyle {
		border-radius: 10px 0px 0px 10px;
		border: 1px solid #dcdcdc;
	}

	.imgBorderOutOfBoxStyle {
		border-radius: 10px 0px 0px 10px !important;
		border-right: 2px dashed rgba(255, 200, 117, 1) !important;
	}

	.bgTrashIcon {
		background-color: rgba(229, 0, 0, 0.1);
		border-radius: 20px;

		&:hover {
			background-color: rgba(229, 0, 0, 0.2);
			transition: 0.1s ease-in;
		}
	}

	.bgEditIcon {
		background-color: #ffeacc;
		border-radius: 20px;

		&:hover {
			background-color: rgba(52, 43, 29, 0.2);
			transition: 0.1s ease-in;
		}
	}

	.colorfinalPrice {
		color: #039800;
	}

	.titleOpacity {
		opacity: 0.7;
	}

	.lineThrough {
		text-decoration: line-through !important;
	}
</style>
