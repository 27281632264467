import $http from '@Config/axios';

const PREFIX_V2 = '/v2/orcamentos/pontual';

/**
 * @template {Object} PageableContentItem
 * @typedef {Object} PageableContent<PageableContentItem>
 * @property {PageableContentItem[]} items
 * @property {Number} total
 */

export class PontualQuotationPresenter {
	/**
	 * @typedef {Object} ListPontualQuotationResponseItem
	 * @property {Number} id
	 * @property {String} publicId
	 * @property {Number} companyUnitId
	 * @property {String} status
	 * @property {String} businessType
	 * @property {String} customer
	 * @property {String} seller
	 * @property {String} restHome
	 * @property {String} deliveryDate
	 * @property {Boolean} hasControlled
	 * @property {Number} total
	 *
	 * @typedef {Object} Params
	 * @property {String=} q
	 * @property {String=} seller
	 * @property {String=} [page=0]
	 * @property {String=} [perPage=5]
	 * @property {String=} status
	 * @property {String=} [sort=ASC]
	 * @property {Number=} companyUnitId
	 * @property {String=} [types=ONE_TIME_PURCHASE]
	 * @typedef {Object} Pagination
	 * @property {Number=} page
	 * @property {Number=} perPage
	 *
	 * @typedef {Object} Sort
	 * @property {String | null} sortBy
	 * @property {String<ASC|DESC> | null} sortDir
	 *
	 * @typedef {Object} Filter
	 * @property {String | null} q
	 * @property {String | null} status
	 * @property {String[]=} types
	 * @property {String | null} companyUnitId
	 *
	 * @typedef {Object} Params
	 * @property {Filter=} filter
	 * @property {Pagination=} pagination
	 * @property {Sort=} sort
	 *
	 * @param {Params} params
	 *
	 * @returns {Promise<PageableContent<ListPontualQuotationResponseItem>>}
	 */
	static async list(props = {}) {
		const params = {
			...props.filter,
			...props.sorting,
			...props.pagination
		};

		const response = await $http.get(PREFIX_V2, { params });
		return {
			total: response.total,
			items: response.items.map(item => ({
				id: item.id,
				status: item.status,
				publicId: item.public_id,
				companyUnitId: item.company_unit_id,
				businessType: item.business_type,
				deliveryDate: item.delivery_date,
				customer: item.customer,
				seller: item.seller,
				restHome: item.restHome,
				hasControlled: !!item.has_controlled,
				total: Number(item.total)
			}))
		};
	}

	/**
	 *
	 * @param {Number} quotationId
	 * @typedef {Object} DetailQuotationResponse
	 * @property {Number} id
	 * @property {String} publicId
	 * @property {String} status
	 * // adicionar os outros
	 * @returns {Promise<DetailQuotationResponse>}
	 */
	static async getDetail(quotationId) {
		const response = await $http.get(`${PREFIX_V2}/${quotationId}`);
		return response;
	}

	/**
	 *
	 * @param {Number} quotationId
	 * @returns {Promise<void>}
	 */
	static async cancel(quotationId) {
		await $http.delete(`${PREFIX_V2}/${quotationId}`);
	}

	/**
	 * @param {Number} quotationId
	 * @param {ApprovePontualQuotationRequest} data
	 * @returns {Promise<void>}
	 */
	static async approve(quotationId, data) {
		await $http.post(`${PREFIX_V2}/${quotationId}/approve`, data);
	}
}

/**
 * @typedef {Object} Prescriber
 * @property {Number} id
 * @property {String} name
 * @property {String} type
 * @property {String} number
 * @property {String} state
 * @typedef {Object} Prescription
 * @property {Date} notificationNumber
 * @property {Date} prescribedDate
 * @property {Prescriber} prescriber
 *
 * @typedef {Object} ApprovePontualQuotationRequest
 * @property {Prescription[]} prescriptions
 */
