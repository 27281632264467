<template>
	<div style="display: flex; align-items: center; justify-content: space-between;">
		<div>
			<h1 class="title mb-3">Detalhes do orçamento</h1>
			<p>Orçamento {{ quotationId }}</p>
			<p v-if="restHome">
				{{ restHome.tradingName }} - {{ restHome.id }}
			</p>
		</div>

		<div style="display: flex; flex-direction: row; align-items: center;">
			<div v-if="invoice" style="display: flex; align-items: center;">
				ID fatura: {{ invoice.invoiceId }}

				<v-btn depressed small @click="copyInvoiceUrl(invoice.link)" color="#409EFF4D" text-colo="#0051CA">
					<v-icon small class="mr-1">
						content_copy
					</v-icon>
					Copiar link
				</v-btn>
			</div>
			<QuotationTypeBadge :type="quotationBusinessType" />
			<PrescriptionRetentionBadge v-if="hasControlled" square />
		</div>
	</div>
</template>

<script>
import QuotationTypeBadge from '@Componentes/quotation/business-type-badge.vue';
import { copyToClipboard } from '@Util/functions';
import PrescriptionRetentionBadge from '@Views/componentes/medicine-prescription-retention-badge.vue';

export default {
	components: {
		QuotationTypeBadge,
		PrescriptionRetentionBadge
	},
	props: {
		quotationId: {
			required: true,
			type: Number
		},
		quotationBusinessType: {
			required: true,
			type: String
		},
		invoice: {
			required: false,
			type: Object,
			default: null
		},
		restHome: {
			required: false,
			type: Object,
			default: null
		},
		hasControlled: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	methods: {
		copyInvoiceUrl(invoiceUrl) {
			return copyToClipboard(invoiceUrl);
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
