<template>
	<v-layout wrap class="d-flex items-center justify-space-between mt-4">
		<v-flex md8 xs12 class="positionTitle ml-5">
			<v-layout column>
				<v-flex>
					<v-toolbar-title style="color: #342b1d" class="titulo display-1">{{title}}</v-toolbar-title>
					<p class="black--text inter mb-0 caption mt-1">{{ budgetCreationCity }}</p>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex md4 xs12 class="d-flex items-center positionButtons mr-5">
			<v-btn style="color: #342b1d; border-radius: 5px;" outline flat @click="$emit('close')">
				Fechar <v-icon small right>close</v-icon>
			</v-btn>

			<ConfirmQuotationCreateDialog
				:loading="loadingSave"
				@saveQuotation="$emit('saveQuotation')" />
		</v-flex>
	</v-layout>
</template>

<script>
import { stepsQuotation } from '@Consts/quotation-v2';

import ConfirmQuotationCreateDialog from './dialog-confirm-save.vue';

export default {
	name: 'PontualQuotationHeader',
	components: {
		ConfirmQuotationCreateDialog
	},
	props: {
		tabSelected: {
			type: String
		},
		companyUnitId: {
			required: false,
			default: null
		},
		loadingSubmit: {
			type: Boolean
		},
		loadingSave: {
			type: Boolean
		},
		title: {
			required: true,
			type: String
		}
	},
	computed: {
		textSave() {
			return 'Salvar';
		},
		textCity() {
			switch (this.companyUnitId) {
				case 1:
					return 'Belo Horizonte';
				case 2:
					return 'São Paulo';
				default:
					return '';
			}
		},
		budgetCreationCity() {
			if (this.isNewQuotation)
				return `Orçamento sendo criado em: ${this.textCity}`;

			return `Orçamento da cidade: ${this.textCity}`;
		},
		isLastTab() {
			return this.tabSelected === stepsQuotation.clientData;
		},
		isProductTabOrPersonalDataTab() {
			return this.tabSelected !== stepsQuotation.generalData;
		}
	},
	methods: {
		save() {
			return this.isNewQuotation ? this.$emit('saveQuotation') : this.$emit('saveEditedQuotation');
		},
		submit() {
			return this.$emit('submitQuotation');
		}
	}
};
</script>
