import { DISCOUNT_AMOUNT_TYPE, DISCOUNT_PERCENTAGE_TYPE } from '@Consts/discount-options';

export class CalculateDiscount {
	/**
	 *
	 * @param {Number} value
	 * @param {String} discountType
	 * @param {Number} discountValue
	 * @returns {Number}
	 */
	static calcule(value, discountType, discountValue) {
		if (![DISCOUNT_PERCENTAGE_TYPE.value, DISCOUNT_AMOUNT_TYPE.value].includes(discountType))
			throw new Error('Tipo de desconto inválido');

		if (discountType === DISCOUNT_AMOUNT_TYPE.value)
			return Number(discountValue);

		if (discountType === DISCOUNT_PERCENTAGE_TYPE.value)
			return Math.ceil((value * discountValue) / 100);

		throw new Error('Erro ao calcular desconto');
	}
}
