<template>
	<v-dialog
		v-model="opened"
		transition="fade"
		max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn  v-on="on" color="#2DCE71" depressed @click="open">
				<strong>
					Aprovar e Liberar
				</strong>
			</v-btn>
		</template>

		<section class="farme-dialog">
			<section class="farme-dialog-body">
				<template v-if="!hasControlled">
					<v-layout column wrap class="d-flex items-center justify-center py-5">
						<v-flex xs12 class="imgStyle d-flex items-center mb-4 bg-error pa-3" style="background-color: #FFC87599; border-radius: 50%;">
							<v-icon x-large color="secondary">
								report_gmailerrorred
							</v-icon>
						</v-flex>
						<v-flex xs12 class="text-center sans">
							<h5 class="title font-weight-bold mb-4">Você tem certeza?</h5>
							<p>
								Ao confirmar a liberação deste orçamento, a ação <br> <b>não poderá ser desfeita</b> e o pedido será <strong>processado para entrega</strong> ao cliente.<br>
								Se houver fatura em aberto, ficará pendente até o pagamento.
							</p>
						</v-flex>
						<v-flex xs12 class="d-flex items-center mt-5">
							<v-btn dark depressed @click="close" class="px-4">Fechar</v-btn>
							<v-btn color="primary" depressed @click="confirm" class="px-4">Confirmar</v-btn>
						</v-flex>
					</v-layout>
				</template>

				<template v-if="hasControlled && !hasPrescriptions">
					<v-layout column wrap class="d-flex items-center justify-center py-5">
						<v-flex xs12 class="imgStyle d-flex items-center mb-4 bg-error pa-3" style="background-color: red; border-radius: 50%;">
							<v-icon x-large color="white">
								report_gmailerrorred
							</v-icon>
						</v-flex>
						<v-flex xs12 class="text-center sans">
							<p class="title font-weight-light">
								Este orçamento possui um ou mais medicamentos controlados que precisam de receita para liberação. <br> Registre as receitas corretamente antes de prosseguir.
							</p>
						</v-flex>
						<v-flex xs12 class="d-flex items-center mt-5">
							<v-btn dark depressed @click="close" class="px-4">Fechar</v-btn>
						</v-flex>
					</v-layout>
				</template>

				<template v-if="hasControlled && hasPrescriptions">
					<v-layout column wrap class="d-flex items-center justify-center py-5">
						<v-flex xs12 class="imgStyle d-flex items-center mb-4 bg-error pa-3" style="background-color: #FFC87599; border-radius: 50%;">
							<v-icon x-large color="secondary">
								report_gmailerrorred
							</v-icon>
						</v-flex>
						<v-flex xs12 class="text-center sans">
							<h5 class="title font-weight-bold mb-4">Você tem certeza?</h5>
							<p>
								Ao confirmar a revisão das receitas deste orçamento, a ação <br> <b>não poderá ser desfeita</b> e o pedido será <strong>processado para entrega</strong> ao cliente. <br>
								Se houver fatura em aberto, ficará pendente até o pagamento.
							</p>
						</v-flex>
						<v-flex xs12 class="d-flex items-center mt-5">
							<v-btn dark depressed @click="close" class="px-4">Fechar</v-btn>
							<v-btn color="primary" depressed @click="confirm" class="px-4">Confirmar</v-btn>
						</v-flex>
					</v-layout>
				</template>
			</section>
		</section>
	</v-dialog>
</template>

<script>
export default {
	props: {
		hasControlled: {
			required: true,
			type: Boolean
		},
		hasPrescriptions: {
			required: true,
			type: Boolean
		}
	},
	data() {
		return {
			opened: false
		};
	},
	methods: {
		open() {
			this.opened = true;
		},
		close() {
			this.opened = false;
		},
		confirm() {
			this.$emit('handleApprove');
			this.close();
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
