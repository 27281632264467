<template>
	<v-layout wrap align-content-space-between class="mb-4">
		<v-flex>
			<h1 class="font-weight-bold mb-2" style="font-family: Inter; font-size: 30px">Orçamentos pontuais</h1>
			<p class="mb-0">Controle os orçamentos pontuais da Far.me.</p>
			<p>Utilize os filtros abaixo para encontrar os orçamentos.</p>
		</v-flex>
		<CreatePontualQuotation @reload="$emit('reload')"/>

		<v-flex xs12 >
			<v-layout wrap>
				<v-flex xs12 md3>
					<v-text-field
						box
						hide-details
						background-color="white"
						label="Busca"
						append-icon="search"
						v-model="filter.search" />
				</v-flex>

				<v-flex xs12 md2>
					<v-autocomplete
						box
						background-color="white"
						hide-details
						multiple
						v-model="filter.restHomeIds"
						append-icon="filter_list"
						label="Casa de repouso"
						no-data-text="Nenhum item disponível"
						clearable
						small-chips
						item-text="tradingName"
						item-value="id"
						:items="restHomes"/>
				</v-flex>

				<v-flex xs12 md2>
					<v-autocomplete
						box
						background-color="white"
						hide-details
						v-model="filter.seller"
						append-icon="filter_list"
						label="Vendedor"
						clearable
						item-text="name"
						item-value="name"
						:items="sellers"/>
				</v-flex>

				<v-flex xs12 md2>
					<v-select
						box
						background-color="white"
						hide-details
						v-model="filter.status"
						append-icon="filter_list"
						clearable
						label="Status"
						:items="statuses" />
				</v-flex>

				<v-flex xs12 md1>
					<v-select
						box
						background-color="white"
						hide-details
						v-model="filter.type"
						append-icon="filter_list"
						clearable
						label="Tipo"
						:items="getQuotationTypes" />

				</v-flex>

				<v-flex xs12 md2>
					<v-select
						box
						background-color="white"
						hide-details
						v-model="filter.companyUnitId"
						append-icon="filter_list"
						clearable
						label="Unidade"
						item-text="nome"
						item-value="id"
						:items="getCompanyUnitItems" />
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { PontualQuotationUtil } from '@Util/pontual-quotation';
import { unidades } from '@Config/unidades';
import CreatePontualQuotation from './create-quotation.vue';

export default {
	name: 'PontualQuotationPageHeader',
	components: {
		CreatePontualQuotation
	},
	props: {
		sellers: {
			required: false,
			type: Array,
			default: () => []
		},
		restHomes: {
			required: false,
			type: Array,
			default: () => []
		}
	},
	created() {
		this.statuses.push(...Object.entries(this.$functions.statusOrcamentoB2C()).map(([key, { label }]) => ({ value: key, text: label })));
	},
	data: () => ({
		statuses: [],
		filter: {
			search: null,
			seller: null,
			status: null,
			restHomeIds: [],
			companyUnitId: null,
			type: null
		}
	}),
	computed: {
		getQuotationTypes() {
			return PontualQuotationUtil.getPontualQuotationTypes().map(item => ({
				text: `${item.businessType}`,
				value: item.quotationType
			}));
		},
		getCompanyUnitItems() {
			return unidades;
		}
	},
	methods: {
		handleFilter() {
			this.$emit(
				'handleFilter',
				{
					filter: {
						status: this.filter.status,
						q: this.filter.search,
						types: this.filter.type ? [this.filter.type] : [],
						seller: this.filter.seller,
						companyUnitId: this.filter.companyUnitId,
						restHomeIds: this.filter.restHomeIds
					}
				}
			);
		}
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.handleFilter();
			}
		}
	}
};
</script>
