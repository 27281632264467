<template>
	<v-card>
		<v-card-title class="pb-0">
			<v-avatar size="38" class="mr-3">
				<v-icon color="white" small>
					fas fa-pills
				</v-icon>
			</v-avatar>
			<h3>Medicamentos{{ origem }}</h3>
			<v-spacer />
			<v-btn depressed small class="green white--text" @click="dialog_pesquisar_medicamento = true">
				<v-icon small class="mr-2">search</v-icon>
				Consultar
			</v-btn>
			<v-btn v-if="!disabled" depressed small class="green white--text" @click="dialog_medicamento = true">
				<v-icon small class="mr-2">add</v-icon>
				Adicionar
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-layout wrap>
				<v-flex v-if="medicamentos.length === 0">
					{{ mensagem }}
				</v-flex>
				<template v-else>
					<v-flex xs12 md6 v-for="(medicamento, index) in medicamentos" :key="index">
						<MedicamentoCard :disabled="disabled"
							:somente_leitura="$store.getters.user.permissao === $permissoes.Financeiro"
							:medicamento="medicamento" :medicamentos="medicamentos"
							:estoque="estoque"
							:mostrar_excluir="medicamentos.length === 1 ? false : true"
							:data_base_pausa="data_base_pausa"
							:assinatura="assinatura"
							:medicamentosPorHorarios="medicamentosPorHorarios"
							:boxId="boxId || ultimaBox"
							:quantidadeAproveitamentoReceita="getAproveitamentoReceita(medicamento.id)"
							:discountPercentage="discountPercent"
							:e_rascunho="eRascunho"
							:fluxo="'recorrencia'"
							:assinaturaId="assinatura ? assinatura.id : null"
							:prescriptionIsVisible="prescriptionIsVisible"
							:permiteTrocarMedicamento="true"
							:externalBilling="assinatura ? Boolean(assinatura.cobranca_externa) : false"
							:mostrar_como_integral="isIntegral"
							:eHistorico="eHistorico"/>
					</v-flex>
				</template>
			</v-layout>
		</v-card-text>
		<DialogMedicamento
			:visible="dialog_medicamento" :close.sync="dialog_medicamento"
			:discount_percentage="discountPercent"
			:medicamento_selecionado="{}"
			:medicamentos="medicamentos"
			:orcamento_filial_id="assinatura ? assinatura.filial_id : null"
			:fluxo="'recorrencia'"
			:assinaturaId="assinatura ? assinatura.id : null"
			:medicamentosPorHorarios="medicamentosPorHorarios"
			:discountDisabled="discountPercent > 0"
			:externalBilling="assinatura ? Boolean(assinatura.cobranca_externa) : false"/>

		<v-dialog persistent v-if="dialog_pesquisar_medicamento" scrollable v-model="dialog_pesquisar_medicamento"
			transition="fade">
			<transition>
				<DialogPesquisarMedicamento :close.sync="dialog_pesquisar_medicamento" :fluxo="'recorrencia'" :assinaturaId="assinatura ? assinatura.id : null"/>
			</transition>
		</v-dialog>

	</v-card>
</template>

<script>
import DialogMedicamento from '@Componentes/medicamento/dialog-medicamento';
import DialogPesquisarMedicamento from '@Componentes/medicamento/dialog-medicamento/dialog-pesquisar-medicamento';
import MedicamentoCard from '@Componentes/medicamento/medicamento-card';
import modalidadesAssinatura from '@Consts/modalidade_assinatura';

export default {
	name: 'MedicamentosAssinatura',
	components: {
		MedicamentoCard, DialogMedicamento, DialogPesquisarMedicamento
	},
	props: {
		assinatura: {
			type: Object,
			required: false
		},
		discountPercent: {
			required: false,
			Type: Number,
			default: 0
		},
		estoque: {
			type: Array,
			default: () => []
		},
		disabled: {
			type: Boolean,
			default: false
		},
		medicamentos: {
			type: Array,
			required: true
		},
		mensagem: {
			type: String,
			default: 'Nenhum medicamento foi escolhido.'
		},
		data_base_pausa: {
			type: Object,
			required: false
		},
		medicamentosComAproveitamentoReceita: {
			required: false,
			type: Array
		},
		prescriptionIsVisible: {
			type: Boolean,
			default: false
		},
		boxId: {
			required: false,
			default: null
		},
		eRascunho: {
			type: Boolean,
			default: false
		},
		eHistorico: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		dialog_medicamento: false,
		dialog_pesquisar_medicamento: false,
		modalidadesAssinatura
	}),
	computed: {
		origem() {
			if (!this.assinatura || !this.assinatura.rascunho_status)
				return '';
			if (this.assinatura.rascunho_status === 'aberto')
				return ' (Rascunho em aberto)';
			if (this.assinatura.rascunho_status === 'fechado')
				return ' (Rascunho finalizado)';
			if (this.assinatura.rascunho_status === 'aprovado')
				return ' (Rascunho aprovado)';
			return '';
		},
		visao_colaborador() {
			return this.$store.getters.visao_colaborador;
		},
		medicamentosPorHorarios() {
			const posologias = this.medicamentos.map(med => med.posologia);
			const horarios = posologias.map(posologia => posologia.horarios);

			const contagemPorHorario = horarios.flat();

			const res = {};
			contagemPorHorario.forEach(v => {
				res[v.horario] = (res[v.horario] || 0) + 1;
			});

			return res;
		},
		ultimaBox() {
			return this.assinatura?.boxes && this.assinatura?.boxes?.length ? this.assinatura.boxes[0].id : null;
		},
		isIntegral() {
			return this.assinatura.modalidade === this.modalidadesAssinatura.INTEGRAL;
		}
	},
	watch: {
		medicamentos() {
			this.$root.$emit('ALTEROU', true);
			this.$root.$emit('ALTEROU_MEDICAMENTO', true);
		}
	},
	methods: {
		primeiraBoxEAntesDoPicking() {
			const boxes = this.assinatura?.boxes;
			return boxes?.length === 1 && ['WAITING', 'PURCHASED', 'SERVICE_STARTED', 'SERVICE_ENDED'].includes(boxes[0].status);
		},
		getAproveitamentoReceita(medicamentoId) {
			if (!this.medicamentosComAproveitamentoReceita?.length)
				return 1; // Default cycles

			const medicamentoComAproveitamentoReceitaEncontrado = this.medicamentosComAproveitamentoReceita
				.find(medicamentoComAproveitamentoReceita => medicamentoComAproveitamentoReceita.medicineId === medicamentoId);

			if (!medicamentoComAproveitamentoReceitaEncontrado)
				return 1;

			return medicamentoComAproveitamentoReceitaEncontrado.totalCycles;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
.v-card {
  ::v-deep .v-card {
    box-shadow: none !important;
  }
}
</style>
