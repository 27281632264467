<template>
	<section>
		<h4 class="subtitle mb-3">Receitas</h4>
		<v-card flat elevation="0" color="white" class="sans pa-4 d-flex items-center">
			<section style="display: flex;justify-content: flex-start; align-items: center; gap: 10px">
				<CreatePrescriptionDialog
					v-if="prescription.canAdd && prescription.items.length === 0"
					@handlePrescription="handlePrescription"/>

				<template v-if="prescription.items.length">
					<div v-for="(prescription, index) of prescription.items" :key="index">
						<v-badge right overlap color="error" >
							<template v-slot:badge v-if="prescription.canRemove">
								<RemovePrescriptionDialog @handleConfirm="remove(index)"/>
							</template>
							<PrescriptionItem>
								<v-flex class="text-center" style="display: flex;flex-direction: column;">
									<h5 class="font-weight-black mb-2">RECEITA {{ index+1 }}</h5>
									<p class="mb-0 body-2 caption">
										<span v-if="prescription.prescribedDate">{{ prescription.prescribedDate | formatDate('DD/MM/YYYY')}}</span> <br>
										<span v-if="prescription.notificationNumber">{{ prescription.notificationNumber }}<br></span>
										<span class="column-overflow">{{ prescription.prescriber.name }}</span>
									</p>
								</v-flex>
							</PrescriptionItem>
						</v-badge>
					</div>
				</template>
			</section>
		</v-card>
	</section>
</template>

<script>
import CreatePrescriptionDialog from './create-prescription-dialog.vue';
import RemovePrescriptionDialog from './remove-prescription-dialog.vue';
import PrescriptionItem from './item.vue';

export default {
	components: {
		CreatePrescriptionDialog,
		RemovePrescriptionDialog,
		PrescriptionItem
	},
	props: {
		prescription: {
			required: true,
			type: Object
		}
	},
	methods: {
		remove(index) {
			this.prescription.items.splice(index, 1);
		},
		handlePrescription(data) {
			this.prescription.items.push(data);
		}
	}
};
</script>

<style lang="scss" scoped>
.column-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-clamp: 1;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

</style>
