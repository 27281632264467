<template>
	<v-flex
		class="bgEditIcon pointer d-flex items-center justify-center mb-2"
		@click="openEditProductDialog">
		<v-icon color="#342B1D" small style="padding: 8px">fas fa-pen</v-icon>

		<v-dialog persistent
			v-if="isEditProductDialogOpened"
			v-model="isEditProductDialogOpened"
			scrollable
			transition="fade"
			max-width="1200px">
			<transition>
				<EditProductDialog
					:product="product"
					:pricing="pricing"
					:discountTypeDefault="getDiscountTypeDefault"
					:discountValueDefault="getDiscountValueDefault"
					@handleProduct="editProduct"
					@close="closeEditProductDialog"/>
			</transition>
		</v-dialog>
	</v-flex>
</template>

<script>
import { DISCOUNT_AMOUNT_TYPE } from '@Consts/discount-options';
import EditProductDialog from '../../dialog-product/index.vue';

export default {
	components: {
		EditProductDialog
	},
	props: {
		product: {
			required: true,
			type: Object
		},
		pricing: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			isEditProductDialogOpened: false
		};
	},
	computed: {
		getDiscountTypeDefault() { return DISCOUNT_AMOUNT_TYPE.value; },
		getDiscountValueDefault() { return this.pricing.packageDiscount; }
	},
	methods: {
		openEditProductDialog() {
			this.isEditProductDialogOpened = true;
		},
		closeEditProductDialog() {
			this.isEditProductDialogOpened = false;
		},
		editProduct(product) {
			this.$emit('editProduct', product);
			this.closeEditProductDialog();
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
