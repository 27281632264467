<template>
	<section class="prescription-item pa-2" :class="getCustomClasses">
		<slot />
	</section>
</template>

<script>
export default {
	props: {
		itemStyle: {
			required: false,
			type: String,
			default: 'solid'
		}
	},
	computed: {
		getCustomClasses() {
			const styles = {
				dashed: 'dashed',
				solid: 'solid'
			};
			return [
				styles[this.itemStyle]
			];
		}
	}
};
</script>

<style lang="scss" scoped>
.prescription-item {
	width: 140px;
	min-height: 100px;
	background-color: #E6E6E6;
	display: flex;
	align-items: center;
	border-width: 2px;
	border-color: #D7D7D7;
	border-radius: 10px;
	&.dashed {
		border-style: dashed;
	}
	&.solid {
		border-style: solid;
	}
}
</style>
