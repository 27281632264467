<template>
	<v-container fluid grid-list-md class="pa-0">
		<v-layout wrap>
			<v-flex md6 xs12>
				<v-autocomplete
					:search-input.sync="q"
					:items="items"
					:loading="loading"
					:value="selected"
					:disabled="loading"
					:auto-select-first="true"
					box
					label="Vendedor"
					placeholder="Digite para filtrar..."
					background-color="white"
					return-object
					:no-data-text="getNoDataText"
					append-icon="search"
					no-filter
					item-text="name"
					data-vv-name="Vendedor"
					:error-messages="errors.collect('Vendedor')"
					v-validate="{ required: false }"
					@change="selectSeller"  />
			</v-flex>
			<v-flex md6 xs12>
				<TextDateField
					v-model="extra.expiresAt"
					box
					background-color="white"
					label="Data de expiração"
					placeholder="dd/mm/yyyy"
					data-vv-name="Data de expiração"
					:validate="{ required: true, data: true }"
					:error-messages="errors.collect('Data de expiração')" />
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { UserPresenter } from '@Presenter/user-presenter';
import { debounce, isEqual } from 'lodash';

export default {
	$_veeValidate: { validator: 'new' },
	props: {
		extra: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			loading: false,
			items: [],
			q: null,
			selected: null
		};
	},
	created() {
		this.items.push(this.extra.seller);

		this.selected = {
			name: this.extra.seller.name,
			phone: this.extra.seller.phone
		};
	},
	computed: {
		getNoDataText() {
			if (this.loading)
				return 'Carregando items...';
			if (this.items.length === 0)
				return 'Nenhum vendedor encontrado';
			return '...';
		}
	},
	methods: {
		getSellers: debounce(async function () {
			this.loading = true;
			try {
				const sellersResponse = await UserPresenter.index();
				this.items = sellersResponse.filter(s => s.telefone).map(seller => ({
					// id: seller.id,
					name: seller.nome,
					phone: seller.telefone
				}));
			} catch (error) {
				return this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: error.message || 'Erro ao buscar vendedores'
				});
			} finally {
				this.loading = false;
			}
		}, 1000),
		selectSeller(item) {
			this.extra.seller.name = item.name;
			this.extra.seller.phone = item.phone;
			this.q = null;
		},
		async validate() {
			const isValid = await this.$validator.validateAll();
			return !!isValid;
		}
	},
	watch: {
		q: {
			deep: true,
			async handler(newVal, oldVal) {
				if (isEqual(newVal, oldVal))
					return;
				await this.getSellers();
			}
		}
	}
};
</script>
