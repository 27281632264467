import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const PREFIX_V2 = '/v2/pessoas';

export class PersonPresenter {
	/**
	 * @typedef {Object} Pagination
	 * @property {Number | null} page
	 * @property {Number | null} perPage
	 *
	 * @typedef {Object} Sort
	 * @property {String | null} field
	 * @property {String<ASC|DESC> | null} direction
	 *
	 * @typedef {Object} Props
	 * @property {String | null} q
	 * @property {Boolean} shouldBePaginated
	 * @property {Pagination | null} pagination
	 * @property {Sort | null} sort
	 *
	 * @typedef {Object} ResponseListItem
	 * @property {Number} id
	 * @property {String} name
	 * @property {String} email
	 * @property {String} document
	 * @property {String} birthdate
	 * @property {String} sex
	 * @property {String} phone
	 *
	 * @typedef {Object} ResponseList
	 * @property {Array<ResponseListItem>} items
	 * @property {Number} total
	 *
	 * @param {Props} props
	 *
	 * @returns {Promise<ResponseList>}
	 */
	static async list(props) {
		const response = await $http.get(buildQueryParams(PREFIX_V2, props));
		return {
			total: response.total,
			items: response.items.map(item => ({
				id: item.id,
				name: item.nome,
				email: item.email,
				document: item.cpf,
				sex: item.sexo,
				phone: item.telefone,
				birthdate: item.nascimento
			}))
		};
	}

	/**
	 * @typedef {Object} CreatePersonRequest
	 * @property {String} name
	 * @property {String} email
	 * @property {String} document
	 * @property {String} birthdate
	 * @property {String=} sex
	 * @property {String=} phone
	 *
	 * @param {CreatePersonRequest} body
	 */
	static async create(body) {
		const response = await $http.post(
			PREFIX_V2,
			body
		);
		return response;
	}

	/**
	 * @typedef {Object} CreatePersonResponse
	 * @property {Number} id
	 * @property {String} name
	 * @property {String} email
	 * @property {String} documentNumber
	 * @property {String} birthdate
	 * @property {?String} sex
	 * @property {?String=} phone
	 *
	 * @param {Number} personId
	 *
	 * @returns {CreatePersonResponse}
	 */
	static async getDetails(personId) {
		const response = await $http.get(`${PREFIX_V2}/${personId}`);
		return response;
	}
}
