<template>
	<v-flex md12 xs12>
		<v-textarea
			v-model="observation"
			placeholder="Observações do cliente"
			readonly
			box
			background-color="white"
			hide-details/>
	</v-flex>
</template>

<script>
export default {
	props: {
		observation: {
			required: false,
			type: String,
			default: null
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
