<template>
	<Dialog
		:titulo="eh_novo ? 'Adicionar medicamento' : (somente_leitura ? 'Visualizar medicamento' : 'Editar medicamento')"
		:subtitulo="($vuetify.breakpoint.smAndUp && !somente_leitura)  ? 'Selecione os dias e confirme.' : null"
		btn_acao="Confirmar" :btn_acao_disable="buttonActionDisable"
		btn_cancelar="Cancelar" @acao="salvar()" @close="$emit('fechar-dialog')">
		<v-container fluid grid-list-xl class="pa-3">
			<v-layout wrap>

				<EscolherMedicamento
					:medicamento="medicamento"
					:somente_leitura="somente_leitura"
					:medicamento_selecionado="medicamento_selecionado"
					:orcamento_filial_id="orcamento_filial_id"
					:fluxo="fluxo"
					:assinaturaId="assinaturaId"
					:tipoCliente="tipoCliente"
					:discount_percentage="discount_percentage"
					:permiteTrocarMedicamento="permiteTrocarMedicamento"
					:restHomeId="restHomeId"
					:externalBilling="externalBilling"
					@medicamento-selecionado="selecionaMedicamento"/>

				<template v-if="medicamento.id">
					<v-flex xs12>
						<v-divider/>
					</v-flex>

					<v-flex xs6>
						<h3 class="mb-2" style="font-size: 14px">Preço do medicamento</h3>
						<v-text-field
							box
							:disabled="eh_vendedor"
							persistent-hint v-money
							v-model="preco_personalizado"
							label="Preço da caixa*"
							style="max-width: 200px"
							@input="calculateDiscount"
							:error="precoPersonalizadoInvalido"
							:readonly="!eh_admin" />

						<span v-if="precoPersonalizadoInvalido" class="red--text">
							O preço não pode ser menor que o desconto
						</span>
					</v-flex>

					<v-flex xs6 v-if="!eh_vendedor">
						<h3 class="mb-2" style="font-size: 14px">Desconto do medicamento</h3>
						<v-tooltip bottom :disabled="!(this.discount_percentage > 0 && this.externalBilling) && !this.is_pbm">
							<v-text-field
								box
								v-money
								v-model="desconto"
								label="Valor de desconto"
								style="max-width: 200px"
								:error-messages="menssagem_erro_desconto"
								:disabled="discountDisabled"
								slot="activator"/>

							<span style="font-size: 12px; color: red" v-if="this.discount_percentage > 0 && this.externalBilling">
								Esta ILPI possui um desconto global.
							</span>
							<span v-if="this.is_pbm">Não é permitido aplicar desconto em um medicamento PBM.</span>
						</v-tooltip>
					</v-flex>
					<v-flex xs12>
						<v-divider />
					</v-flex>

					<v-flex xs12 v-if="medicamento.liquido">
						<h3 class="mb-2" style="font-size: 14px">Quantidade de caixas do medicamento</h3>
						<v-autocomplete
							v-model="medicamento.qtd_caixas"
							:items="quantityOfBoxes"
							solo
							flat
							box
							hide-details
							placeholder="Digite para filtrar..."
							clearable
							:disabled="desabilitaQtdCaixas"
							item-value="value" item-text="label" style="max-width: 300px" />

						<!-- <v-text-field v-model.number="medicamento.qtd_caixas" label="Quantidade de caixas *"
							box style="max-width: 300px" type="number" step="1"
							:rules="[val => (!!val && Number.isInteger(val)) || 'Deve ser um número inteiro maior que 0.']"
							min="1"/> -->
						<v-flex xs12 class="px-0" v-if="enableIntegrality && isControlled">
							<h3 class="mb-2" style="font-size: 14px">Aproveitamento de receita</h3>
							<v-select
								box
								hide-details
								placeholder="Selecione..."
								v-model="hasPrescriptionUtilization"
								:items="prescriptionUtilization"
								:disabled="disabledPrescriptionUtilization"
								item-value="value" item-text="label" style="max-width: 300px">
							</v-select>
						</v-flex>
					</v-flex>
					<v-flex xs12 v-else>
						<Posologia :posologia="posologia" :somente_leitura="somente_leitura" :isControlled="isControlled" :medPrescriptionUtilization="hasPrescriptionUtilization" :disabledPrescriptionUtilization="disabledPrescriptionUtilization"
							@prescriptionUtilization="val => hasPrescriptionUtilization = val" />
					</v-flex>
				</template>

			</v-layout>
		</v-container>
	</Dialog>
</template>

<script>
import { posologyTypes } from '@Consts/posologia';
import { featureFlags } from '@Consts/feature-flags';
import { prescriptionUtilization, quantityOfBoxes } from '@Consts/quotation-v2';
import { getSimulationCyclesByMedicine } from '@Util/cycles';
import modalidade_assinatura from '@Consts/modalidade_assinatura';
import EscolherMedicamento from './escolher-medicamento';
import Posologia from './posologia';

export default {
	name: 'DialogMedicamento',
	components: { EscolherMedicamento, Posologia },
	props: {
		discount_percentage: {
			type: Number,
			required: false,
			default: 0
		},
		medicamento_selecionado: {
			type: Object,
			default: null
		},
		somente_leitura: {
			type: Boolean,
			required: false
		},
		eh_orcamento: {
			type: Boolean,
			default: false
		},
		orcamento_filial_id: {
			type: Number,
			required: false
		},
		medicamentosPorHorarios: {
			type: Object,
			default: () => ({})
		},
		fluxo: {
			type: String,
			required: false
		},
		assinaturaId: {
			type: Number,
			required: false
		},
		tipoCliente: {
			type: String,
			required: false
		},
		permiteTrocarMedicamento: {
			type: Boolean,
			default: false
		},
		externalBilling: {
			type: Boolean,
			default: false
		},
		restHomeId: {
			required: false
		},
		subscriptionMode: {
			type: String,
			required: false
		}
	},
	data: () => ({
		medicamento: {
			produto: ''
		},
		oldCustomPrice: 0,
		preco_personalizado: 0,
		desconto: 0,
		menssagem_erro_desconto: [],
		desconto_invalido: false,
		posicao_medicamento: null,
		carregando_medicamentos: false,
		posologyWasChanged: false,
		posologia: {
			horarios: [],
			periodo_de_uso: posologyTypes.allDays,
			dias_da_semana: [],
			dias_do_mes: []
		},
		desabilitaQtdCaixas: false,
		hasPrescriptionUtilization: null,
		prescriptionUtilization,
		quantityOfBoxes
	}),
	created() {
		if (this.subscriptionMode === modalidade_assinatura.PROPORCIONAL)
			this.hasPrescriptionUtilization = false;

		if (!this.eh_novo) {
			this.medicamento = this.$lodash.cloneDeep(this.medicamento_selecionado);
			this.posologia = this.$lodash.cloneDeep(this.medicamento_selecionado.posologia);
			this.preco_personalizado = this.medicamento_selecionado.preco_personalizado;
			this.desconto = this.medicamento_selecionado.desconto;
			if (this.subscriptionMode === modalidade_assinatura.PROPORCIONAL)
				this.hasPrescriptionUtilization = false;
			else
				this.hasPrescriptionUtilization = this.medicamento.aproveitamento_receita;

			this.disableQuantityOfBoxes = !!this.hasPrescriptionUtilization;

			delete this.medicamento.posologia;
		}
	},
	methods: {
		selecionaMedicamento(med) {
			this.medicamento = med;
			this.preco_personalizado = med.preco_personalizado;
			this.desconto = med.desconto_tabela || med.desconto || 0;
		},
		calculateDiscount(value) {
			const oldPrice = Number(this.$functions.onlyNumber(this.oldCustomPrice));
			const newPrice = Number(this.$functions.onlyNumber(value));
			if (oldPrice === newPrice || this.is_pbm || (this.discount_percentage <= 0) || (!this.assinaturaId && !this.externalBilling))
				return this.desconto;
			const discount = Math.round(newPrice * (this.discount_percentage / 100));
			this.desconto = discount;
		},
		normalizedPosology(posology) {
			const clearedPosology = {
				horarios: posology.horarios,
				periodo_de_uso: posology.periodo_de_uso
			};

			switch (posology.periodo_de_uso) {
				case posologyTypes.monthly:
					clearedPosology.dias_do_mes = posology.dias_do_mes;
					break;
				case posologyTypes.weekly:
					clearedPosology.dias_da_semana = posology.dias_da_semana;
					break;
				case posologyTypes.other:
					clearedPosology.outro_periodo = posology.outro_periodo;
					break;
				default:
					clearedPosology.dias_do_mes = [];
					clearedPosology.dias_da_semana = [];
					break;
			}
			return clearedPosology;
		},
		async salvar() {
			const medicamento = this.$lodash.cloneDeep({
				...this.medicamento,
				posologia: this.posologyWasChanged ? this.normalizedPosology(this.posologia) : this.posologia,
				aproveitamento_receita: this.hasPrescriptionUtilization
			});
			const preco_personalizado = Number(this.$functions.onlyNumber(this.preco_personalizado));
			const desconto = Number(this.$functions.onlyNumber(this.desconto));
			medicamento.desconto = desconto;
			medicamento.preco_personalizado = preco_personalizado;
			medicamento.preco_com_desconto = preco_personalizado - desconto;

			const isDivisible = !this.medicamento.liquido;
			if (isDivisible) {
				if (this.enableIntegrality && this.eh_orcamento) {
					const cycles = await getSimulationCyclesByMedicine({
						id: medicamento.id,
						hasPrescriptionUtilization: medicamento.aproveitamento_receita,
						isDivisible,
						isOutOfBox: medicamento.fora_da_box,
						closedPackages: undefined,
						priceSku: medicamento.preco_com_desconto,
						posology: medicamento.posologia,
						boxStartDate: undefined
					});

					const ciclo = cycles[0].produtos.find(item => item.id === medicamento.id);

					medicamento.qtd_caixas = ciclo ? ciclo.quantidade_caixas_ciclo : null;
				} else
					medicamento.qtd_caixas = null;
			}

			this.$emit('medicamento-salvo', medicamento);
		}
	},
	watch: {
		preco_personalizado(newCustomPrice) {
			const _customPrice = Number(this.$functions.onlyNumber(newCustomPrice));
			this.oldCustomPrice = _customPrice;
		},
		desconto(valor_desconto) {
			valor_desconto = Number(this.$functions.onlyNumber(valor_desconto));
			const preco = Number(this.$functions.onlyNumber(this.preco_personalizado));
			this.desconto_invalido = false;

			if (valor_desconto > preco) {
				this.menssagem_erro_desconto.push(`Desconto não pode ser maior que preço: ${this.$functions.mascaraDinheiro(preco)}`);
				this.desconto_invalido = true;
				return;
			}
			this.menssagem_erro_desconto.pop();

			if (!this.$store.getters.eh_vendedor)
				return;

			const preco_com_desconto = preco * 0.5;

			this.desconto_invalido = valor_desconto > preco_com_desconto;

			if (this.desconto_invalido)
				this.menssagem_erro_desconto.push(`Desconto máximo permitido: ${this.$functions.mascaraDinheiro(preco_com_desconto)}`);
			else
				this.menssagem_erro_desconto.pop();
		},
		'posologia.periodo_de_uso': function () {
			this.posologyWasChanged = true;
		},
		hasPrescriptionUtilization: {
			handler(val, oldValue) {
				if (this.medicamento.liquido) {
					if (this.eh_novo) {
						if (val === true) {
							this.medicamento.qtd_caixas *= 2;
							this.desabilitaQtdCaixas = true;
						} else if (this.medicamento.qtd_caixas === 1)
							this.desabilitaQtdCaixas = false;
						else {
							this.medicamento.qtd_caixas /= 2;
							this.desabilitaQtdCaixas = false;
						}
					} else {
						this.desabilitaQtdCaixas = Boolean(val);
						const isFirstLoader = oldValue === null;
						if (!isFirstLoader) {
							if (!val)
								this.medicamento.qtd_caixas /= 2;
							else
								this.medicamento.qtd_caixas *= 2;
						}
					}
				}
			},
			deep: true
		}
	},
	computed: {
		discountDisabled() {
			if (this.is_pbm)
				return true;

			if (this.assinaturaId && this.discount_percentage)
				return true;

			if (!this.assinaturaId && this.discount_percentage && this.externalBilling)
				return true;

			return false;
		},
		hint_preco() {
			const preco_personalizado = Number(this.$functions.onlyNumber(this.preco_personalizado));
			const preco_recorrente = this.medicamento.preco_recorrente;
			if (preco_personalizado !== preco_recorrente)
				return `O preço recorrente é ${this.$functions.mascaraDinheiro(preco_recorrente)}`;
			return '';
		},
		is_pbm() {
			return this.medicamento.pbm;
		},
		discount_field_is_readonly() {
			return this.somente_leitura || !this.can_update_discount;
		},
		can_update_discount() {
			return this.$store.getters.eh_administrador && !this.is_pbm;
		},
		tem_medicamento() {
			return typeof this.medicamento.id === 'number';
		},
		eh_novo() {
			if (!this.medicamento_selecionado)
				return true;
			return typeof this.medicamento_selecionado.id !== 'number';
		},
		eh_vendedor() {
			return this.$store.getters.eh_vendedor;
		},
		eh_admin() {
			return this.$store.getters.eh_administrador;
		},
		precoPersonalizadoInvalido() {
			const customPriceValue = Number(this.$functions.onlyNumber(this.preco_personalizado));
			const discountValue = Number(this.$functions.onlyNumber(this.desconto));
			return customPriceValue < discountValue;
		},
		qtdCaixasInvalido() {
			return !this.tem_posologia && (Number(this.medicamento.qtd_caixas) === 0 || !Number.isInteger(this.medicamento.qtd_caixas));
		},
		habilitar_adicionar_medicamento() {
			return !!this.medicamento.produto && !!this.medicamento.apresentacao && (this.tem_posologia || this.medicamento.qtd_caixas);
		},
		tem_posologia() {
			return !!this.posologia && !!this.posologia.horarios.length
				&& (this.posologia.periodo_de_uso === posologyTypes.allDays
					|| this.posologia.periodo_de_uso === posologyTypes.dayYesDayNot
					|| this.posologia.periodo_de_uso === posologyTypes.dayNotDayYes
					|| (this.posologia.periodo_de_uso === posologyTypes.weekly && !!this.posologia.dias_da_semana.length)
					|| (this.posologia.periodo_de_uso === posologyTypes.monthly && !!this.posologia.dias_do_mes.length)
					|| this.posologia.periodo_de_uso === posologyTypes.other
				);
		},
		isControlled() {
			return !!this.medicamento.portaria_344_98;
		},
		enableIntegrality() {
			return this.$store.getters.isFeatureFlagEnabled(featureFlags.integrality_b2b);
		},
		buttonActionDisable() {
			return !this.habilitar_adicionar_medicamento || this.desconto_invalido || this.qtdCaixasInvalido || this.precoPersonalizadoInvalido || (this.enableIntegrality && this.isControlled && (this.hasPrescriptionUtilization === null || this.hasPrescriptionUtilization === undefined));
		},
		disabledPrescriptionUtilization() {
			return this.medicamento.liquido === 1 ? !this.medicamento.qtd_caixas && this.subscriptionMode === modalidade_assinatura.PROPORCIONAL : this.subscriptionMode === modalidade_assinatura.PROPORCIONAL;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";

h2 {
	@media screen and (max-width: $break-point-sm) {
		font-size: 18px !important;
	}
}

.chip-wrapper {
	.v-chip {
		margin-left: 0 !important;
		margin-right: 16px !important;

		.icone {
			font-size: 12px;
			margin-right: 4px;
			opacity: 0.5;
		}
	}

	.qnt_hor {
		display: inline-block;
		max-width: 130px;
	}
}

.outro-tipo-select {
	@media screen and (min-width: $break-point-sm) {
		flex: 0;
		min-width: 150px;
	}
}

.outro-dia-select {
	@media screen and (min-width: $break-point-sm) {
		flex: 0;
		min-width: 130px;
	}
}

::v-deep .v-card__actions {
	background: #f5f5f5;
	padding: 16px 32px;
}

::v-deep .v-input--is-disabled {
	.v-text-field__prefix,
	.v-text-field__suffix {
		opacity: 0.3;
	}
}

::v-deep .v-input--selection-controls {
	.v-label {
		color: #222;
		font-size: 15px;
	}
}
</style>
