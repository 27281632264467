import {
	RESPONSIBLE_PAYMENT_MIXED,
	RESPONSIBLE_PAYMENT_FARMEMBRO,
	RESPONSIBLE_PAYMENT_REST_HOME
} from '@Consts/rest-home/responsible-payment';
import { PAYMENT_TYPE_OPEN_CLOSE } from '@Consts/rest-home/payment-types';

/**
 *
 * @param {String} paymentType
 * @returns {Boolean}
 */
function isPaymentTypeOpenClosed(paymentType) {
	return paymentType === PAYMENT_TYPE_OPEN_CLOSE.value;
}

/**
 *
 * @param {String} paymentResponsible
 * @returns {Boolean}
 */
function isPaymentResponsibleMixed(paymentResponsible) {
	return paymentResponsible === RESPONSIBLE_PAYMENT_MIXED.value;
}

/**
 *
 * @param {String} paymentResponsible
 * @returns {Boolean}
 */
function isPaymentResponsibleFarmembro(paymentResponsible) {
	return paymentResponsible === RESPONSIBLE_PAYMENT_FARMEMBRO.value;
}

/**
 *
 * @param {String} paymentResponsible
 * @returns {Boolean}
 */
function isPaymentResponsibleIlpi(paymentResponsible) {
	return paymentResponsible === RESPONSIBLE_PAYMENT_REST_HOME.value;
}

export class RestHomeUtil {
	/**
     *
     * @typedef {Object} PaymentInfo
	 * @property {String} type
	 * @property {String} responsible
	 *
     * @param {PaymentInfo} paymentInfo
     * @returns {Boolean}
     */
	static isExternalPaymentReadonly(paymentInfo) {
		// Apenas quando o tipo de pagamento da ILPI é MISTO
		// E a casa tem pagamento na modalidade abertura e fechamento,
		// o campo "Cobrança Externa" pode ser alterado
		return !(isPaymentTypeOpenClosed(paymentInfo?.type)
            && isPaymentResponsibleMixed(paymentInfo.responsible));
	}

	/**
     *
     * @typedef {Object} PaymentInfo
	 * @property {String} type
	 * @property {String} responsible
	 *
     * @param {PaymentInfo} paymentInfo
     * @returns {Boolean}
     */
	static isExternalPaymentAllowed(paymentInfo) {
		// Apenas quando o tipo de pagamento da ILPI é MISTO Ou CASAPAGA
		// E a casa tem pagamento na modalidade abertura e fechamento,
		// o campo "Cobrança Externa" pode ter valor == true
		return isPaymentTypeOpenClosed(paymentInfo?.type)
			&& paymentInfo?.type
            && !isPaymentResponsibleFarmembro(paymentInfo.responsible);
	}

	/**
     *
     * @typedef {Object} PaymentInfo
	 * @property {String} type
	 * @property {String} responsible
	 *
     * @param {PaymentInfo} paymentInfo
     * @returns {Boolean}
     */
	static isIlpiFinancialResponsibleForAllResidents(paymentInfo) {
		return isPaymentResponsibleIlpi(paymentInfo?.responsible)
            && isPaymentTypeOpenClosed(paymentInfo?.type);
	}

	static isIlpiFinancialResponsibleForSomeResidents(paymentInfo) {
		return isPaymentResponsibleMixed(paymentInfo.responsible)
            && isPaymentTypeOpenClosed(paymentInfo?.type);
	}
}
