<template>
	<section>
		<v-data-table
			:items="items"
			:headers="headers"
			:loading="loading"
			:total-items="total"
			class="custom-data-table"
			:disable-initial-sort="true"
			:pagination.sync="pagination"
			rows-per-page-text="Items por página"
			:rows-per-page-items="itemsPerPageOptions"
			no-results-text="Nenhum resultado encontrado"
			:no-data-text="loading ? 'Carregando...' : 'Nenhum intem encontrado'">
			<template v-slot:items="props">
				<tr @click="openDetailPontualQuotationDialog(props.item.id)" class="pointer">
					<td class="text-left">
						{{ props.item.id }}
					</td>
					<td class="text-left">
						{{ getCompanyUnitDescription(props.item.companyUnitId) }}
					</td>
					<td class="">
						<QuotationBadgeBusinessType small :type="props.item.businessType" />
					</td>
					<td class="text-center" style="width: 300px;">
						{{ props.item.restHome || '-' }}
					</td>
					<td class="text-center" style="width: 500px;">
						<div class="items-center justify-start ">
							<PrescriptionRetentionBadge v-if="props.item.hasControlled" />
							<span class="font-weight-bold ml-3">{{ props.item.customer }}</span>
						</div>
					</td>
					<td class="text-center">
						<span> {{ props.item.deliveryDate | formatDate('DD/MM/YYYY') }}</span>
					</td>
					<td class="text-left">
						<span> {{ props.item.seller }}</span>
					</td>
					<td class="text-center">
						<span> {{ props.item.total | dinheiro }}</span>
					</td>
					<td class="">
						<QuotationBadgeStatus :status="props.item.status" />
					</td>
					<td>
						<v-menu offset-y>
							<template v-slot:activator="{ on }">
								<v-btn
									color="primary"
									v-on="on"
									depressed
									@click.stop>
									Ações

									<v-icon class="ml-2">more_horiz</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-tile
									v-if="isAvailableToEdit(props.item.status)"
									@click.stop="$router.push({ name: 'quotation.pontual.update', params: { id: props.item.id }})">
									<v-list-tile-action>
										<v-icon color="black">edit</v-icon>
									</v-list-tile-action>
									<v-list-tile-title>Editar orçamento</v-list-tile-title>
								</v-list-tile>
								<v-list-tile
									@click.stop="copyPontualQuotationUrl(props.item)">
									<v-list-tile-action>
										<v-icon color="primary">fas fa-share</v-icon>
									</v-list-tile-action>
									<v-list-tile-title>Copiar link</v-list-tile-title>
								</v-list-tile>
							</v-list>
						</v-menu>
					</td>
				</tr>
			</template>
		</v-data-table>
	</section>
</template>

<script>
import QuotationBadgeStatus from '@Componentes/quotation/status-badge.vue';
import QuotationBadgeBusinessType from '@Componentes/quotation/business-type-badge.vue';
import PrescriptionRetentionBadge from '@Componentes/medicine-prescription-retention-badge.vue';
import { copyToClipboard } from '@Util/functions';
import { FarmeUrls } from '@Util/farme-urls';
import { PontualQuotationUtil } from '@Util/pontual-quotation';
import { companyUnitAbbr } from '@Consts/unidades';

export default {
	name: 'ListProductionTable',
	components: {
		QuotationBadgeStatus,
		QuotationBadgeBusinessType,
		PrescriptionRetentionBadge
	},
	props: {
		loading: {
			required: false,
			type: Boolean,
			default: false
		},
		items: {
			required: false,
			type: Array,
			default: () => ([])
		},
		total: {
			required: false,
			type: Number,
			default: 0
		},
		itemsPerPageOptions: {
			required: false,
			type: Array,
			default: () => ([5, 10, 25, 100])
		},
		itemsPerPage: {
			required: false,
			type: Number,
			default: 10
		}
	},
	data() {
		return {
			pagination: {
				descending: true,
				page: 1,
				rowsPerPage: this.itemsPerPage,
				sortBy: 'id',
				totalItems: this.total
			}
		};
	},
	computed: {
		headers() {
			return [

				{
					text: 'ID',
					value: 'id',
					sortable: true,
					width: '10px'
				},
				{
					text: 'Unidade',
					value: 'companyUnitId',
					sortable: false,
					width: '10px'
				},
				{
					text: 'Tipo',
					value: 'header-business-type',
					align: 'center',
					sortable: false,
					width: '10px'
				},
				{
					text: 'ILPI',
					value: 'restHome',
					align: 'center',
					sortable: false,
					width: '100px'
				},
				{
					text: 'Cliente',
					value: 'header-customer',
					sortable: false,
					width: '300px'
				},
				{
					text: 'Data de entrega',
					value: 'startDate',
					sortable: true,
					width: '10px'
				},
				{
					text: 'Vendedor',
					value: 'header-seller',
					sortable: false,
					width: '300px'
				},
				{
					text: 'Preço',
					value: 'header-price',
					sortable: false
				},
				{
					text: 'Status',
					value: 'header-produced-by',
					sortable: false
				},
				{
					sortable: false,
					width: '10px'
				}
			];
		}
	},
	methods: {
		isAvailableToEdit(status) {
			return PontualQuotationUtil.isDraft(status);
		},
		getCompanyUnitDescription(companyUnitId) {
			return companyUnitAbbr[companyUnitId];
		},
		updateFilters() {
			this.$emit('handleFilter', {
				pagination: {
					page: Number(this.pagination.page),
					perPage: Number(this.pagination.rowsPerPage)
				},
				sorting: {
					sortBy: this.pagination.sortBy,
					sortDir: this.pagination.descending ? 'DESC' : 'ASC'
				}
			});
		},
		toggleAll() {
			if (this.selected.length)
				this.selected = [];
			else
				this.selected = this.items.slice();
		},
		changeSort(header) {
			if (!header.sortable)
				return;
			const column = header.value;
			if (this.pagination.sortBy === column)
				this.pagination.descending = !this.pagination.descending;
			else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		openDetailPontualQuotationDialog(quotationId) {
			this.$emit('openDetailDetailPontualQuotationDialog', quotationId);
		},
		openUpdatePontualQuotationDialog(quotationId) {
			this.$emit('openUpdatePontualQuotationDialog', quotationId);
		},
		copyPontualQuotationUrl(quotation) {
			const url = FarmeUrls.getPontualQuotationJornadaDigitalUrl(quotation.publicId);
			return copyToClipboard(url);
		}
	},
	watch: {
		pagination: {
			deep: true,
			handler() {
				this.updateFilters();
			}
		},
		selected: {
			handler() {
				this.$emit('updatedSelected', this.selected);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
header {
	border-bottom-width: 5px;
	border-bottom-color: #ffd9a0 !important;
	border-bottom-style: solid;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

</style>
