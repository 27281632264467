<template>
	<v-flex xs12 md12 class="tabs">
		<v-btn
			v-for="tab in tabs"
			:key="tab.id"
			depressed
			large
			class="styleButtons pointer mx-2 tab"
			@click="selectTab(tab)"
			:class="tabSelected === tab.id ? 'colorButtonSelected' : 'colorButtonsNotSelected'">
			<span
				class="styleNumber"
				:style="tab.number === 1 ? 'padding: 0px 7px' : 'padding: 0px 6.5px'">
				{{ tab.number }}
			</span>
			<span class="textTabs">{{ tab.title }}</span>
		</v-btn>
	</v-flex>
</template>

<script>
export default {
	name: 'TabsQuotationPontual',
	props: {
		tabSelected: {
			type: String,
			required: true
		},
		allowNavagitation: {
			required: false,
			type: Boolean,
			default: false
		}

	},
	data: () => ({
		tabs: [
			{ id: 'general-data', title: 'Dados Gerais', number: 1 },
			{ id: 'products', title: 'Produtos', number: 2 }
		]
	}),
	methods: {
		selectTab(tab) {
			if (this.allowNavagitation)
				this.$emit('selectTab', tab.id);
		}
	}
};
</script>

<style lang="scss" scoped>
.v-btn {
	margin: 0;
}

.styleButtons {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	padding: 0px 75px !important;
}

.colorButtonSelected {
	background-color: #FFEACC !important;
}

.colorButtonsNotSelected {
	opacity: 0.5;
	background-color: #FFEACC !important;

	&:hover {
		opacity: 0.7;
		background-color: #FFEACC !important;
	}
}

.textTabs {
	font-size: 14px;
	font-family: Roboto;
	color: black !important;
}

.tabs {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 6px;
}

.tab{
	flex-grow: 1;
}

.styleNumber {
	border: 2px solid #342B1D;
	border-radius: 15px;
	margin-right: 5px;
	color: #FFEACC;
	background-color: #342B1D;
}
</style>
