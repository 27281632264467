<template>
	<Dialog
		titulo="Adicionar receitas"
		btn_acao="Enviar receitas"
		@acao="storePrescription"
		@close="$emit('update:close', false)">
		<v-container class="pa-0">
			<form>
				<v-container grid-list-md text-xs-center>
					<v-layout row wrap>
						<v-flex xs12>
							<v-autocomplete
								v-model="form.prescriber"
								:search-input.sync="prescribers.searchTerm"
								:prepend-inner-icon="prescribers.loading ? 'sync' : 'search'"
								hint="Filtre pelo nome ou pelo número de identificação do prescritor"
								persistent-hint
								:items="prescribers.items"
								:loading="prescribers.loading"
								:disabled="prescribers.loading"
								label="Prescritor"
								box
								no-filter
								clearable
								v-validate="{ required: true }"
								data-vv-name="Prescritor"
								:error-messages="errors.collect('Prescritor')"
								return-object
								item-value="id"
								item-text="name"
								no-data-text="Nenhum prescritor encontrado"
								append-outer-icon="add"
								@click:append-outer="openedCreatePrescriberDialog = true">
								<template v-slot:selection="data">
									<v-chip
										v-if="data.item.typeOfRegister"
										small
										:color="getColorOfTypeOfRegister(data.item.typeOfRegister)"
										:title="getDescriptionOfTypeOfRegister(data.item.typeOfRegister)">{{ data.item.typeOfRegister }}</v-chip>
									{{ getRegister(data.item.number, data.item.uf) }}-									-
									{{ data.item.name }}
								</template>
								<template v-slot:item="data">
									<v-list-tile-content>
										<v-list-tile-title>
											<v-chip
												v-if="data.item.typeOfRegister"
												small
												:color="getColorOfTypeOfRegister(data.item.typeOfRegister)"
												:title="getDescriptionOfTypeOfRegister(data.item.typeOfRegister)">{{ data.item.typeOfRegister }}</v-chip>
											{{ getRegister(data.item.number, data.item.uf) }} - {{ data.item.name }}
										</v-list-tile-title>
									</v-list-tile-content>
								</template>

							</v-autocomplete>
						</v-flex>

						<v-flex xs12 md6>
							<v-text-field
								box
								type="number"
								label="Número de notificação"
								v-model="form.notificationNumber"
								data-vv-name="Número de notificação"
								v-validate="{ required: false }"/>
						</v-flex>

						<v-flex xs12 md6>
							<TextDateField
								box
								label="Data prescrita"
								placeholder="dd/mm/yyyy"
								v-model="form.prescriptionDate"
								data-vv-name="Data prescrita"
								v-validate="{ required: false }"/>
						</v-flex>

						<v-flex v-if="withFile" xs12>
							<v-btn
								block
								depressed
								class="white--text"
								large
								@click="$refs.upload.click()"
								:loading="false">
								<v-icon small class="mr-2">cloud_upload</v-icon>
								Escolher receita
							</v-btn>
							<input
								type="file"
								ref="upload"
								@change="renderPreview"
								accept="image/png, image/jpeg, application/pdf"/>

							<v-alert outline :value="!this.form.file.value" type="error">
								Selecione o arquivo da receita (Imagem ou PDF)
							</v-alert>
						</v-flex>
					</v-layout>
				</v-container>
			</form>

			<template v-if="this.form.file.value">
				<embed
					v-if="this.form.file.type === 'application/pdf'"
					:src="previewData"
					type="application/pdf"
					width="100%"/>
				<img v-else :src="previewData" width="100%" />
			</template>
		</v-container>

		<v-dialog
			v-model="openedCreatePrescriberDialog"
			persistent
			scrollable
			transition="fade"
			max-width="840px"
			style="z-index:999">
			<transition>
				<DialogCreatePrescriber
					v-if="openedCreatePrescriberDialog"
					@prescriberCreated="searchPrescibers()"
					@close="closeCreatePrescriberDialog()"/>
			</transition>
		</v-dialog>
	</Dialog>
</template>

<script>
import { PrescriberPresenter } from '@Presenter/prescriber-presenter';
import { debounce } from 'lodash';
import { typesOfRegisterData } from '@Consts/prescriber-register-types';
import DialogCreatePrescriber from './dialog-create-prescriber.vue';

export default {
	name: 'DialogUploadPrescriptions',
	components: { DialogCreatePrescriber },
	$_veeValidate: {
		validator: 'new'
	},
	props: {
		withFile: {
			required: false,
			type: Boolean,
			default: true
		}
	},
	async created() {
		await this.searchPrescibers();
	},
	data() {
		return {
			openedCreatePrescriberDialog: false,
			prescribers: {
				loading: false,
				searchTerm: null,
				items: []
			},
			form: {
				file: {
					name: null,
					type: null,
					value: null
				},
				prescriber: null,
				notificationNumber: null,
				prescriptionDate: null
			},
			previewData: null
		};
	},

	methods: {
		initForm() {
			this.form = {
				file: {
					name: null,
					type: null,
					value: null
				},
				notificationNumber: null,
				prescriptionDate: null,
				prescriber: null
			};
		},
		renderPreview() {
			const file = this.$refs.upload.files[0];
			this.form.file.name = file.name;
			this.form.file.type = file.type;

			if (file.type === 'application/pdf') {
				const fileReader = new FileReader();
				fileReader.onloadend = () => {
					this.form.file.value = fileReader.result;
					this.previewData = fileReader.result;
				};
				fileReader.readAsDataURL(file);
			} else {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				const maxWidth = 1000;
				const maxHeigth = 1500;
				const image = new Image();
				image.onload = () => {
					const width = image.width;
					const height = image.height;
					const scale = Math.min(maxWidth / width, maxHeigth / height);
					const widthScaled = width * scale;
					const heigthScaled = height * scale;
					canvas.width = widthScaled;
					canvas.height = heigthScaled;
					ctx.drawImage(image, 0, 0, widthScaled, heigthScaled);
					this.form.file.value = canvas.toDataURL('image/jpeg', 0.7);
				};
				image.src = URL.createObjectURL(file);
				this.previewData = image.src;
			}
		},
		searchPrescibers: debounce(async function () {
			this.prescribers.loading = true;

			const prescribersResponse = await PrescriberPresenter.list({
				search: this.prescribers.searchTerm
			});

			this.prescribers.items = prescribersResponse.items;
			this.prescribers.loading = false;
		}, 500),

		async storePrescription() {
			const validated = await this.$validator.validateAll();

			if (!validated) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					message: 'Preencha as informações obrigatórias do formulário',
					color: 'error'
				});
				return;
			}

			const dataToSend = {
				receita_base64: this.form.file.value,
				tipo: this.form.file.type,
				name: this.form.file.name,
				numero_notificacao: this.$functions.onlyNumber(this.form.notificationNumber),
				data_prescrita: this.form.prescriptionDate,
				prescriberId: this.form.prescriber.id,
				extra: {
					prescriber: this.form.prescriber
				}
			};

			this.$emit('prescriptionToBeCreated', dataToSend);
		},
		closeCreatePrescriberDialog() {
			this.openedCreatePrescriberDialog = false;
		},
		getDescriptionOfTypeOfRegister(type) {
			if (typesOfRegisterData[type])
				return typesOfRegisterData[type].description;
			return null;
		},
		getColorOfTypeOfRegister(type) {
			if (typesOfRegisterData[type])
				return typesOfRegisterData[type].color;
			return null;
		},
		getRegister(number, state) {
			let str = number;
			if (state)
				str = `${number}/${state}`;
			return str;
		}
	},
	watch: {
		'prescribers.searchTerm': function () {
			this.searchPrescibers();
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
.v-list__tile__sub-title, .v-list__tile__title {
    overflow: visible;
}

input {
	display: none;
}

.q-column {
	min-height: 100px;
}

.btn {
	background: lightgrey;
	padding: 8px;
	border-radius: 8px;
	margin-bottom: 8px;
}

.img-container {
	position: relative;

	.delete-btn {
		opacity: 0;
		transition: opacity 0.3s;
	}

	&:hover {
		.delete-btn {
			opacity: 1;
		}
	}

	button {
		position: absolute;
		right: 0;
	}

	img {
		max-height: 100px;

		&.selecionada {
			box-shadow: 0 0 20px $purple;
		}
	}
}
</style>
