<template>
	<div class="pa-3">
		<h1>Quem utilizará os medicamentos?</h1>
		<h2 class="subheading font-weight-bold">Assinatura sendo criada em: {{ filial }}</h2>
		<v-container fluid grid-list-xl class="pa-0" v-if="orcamento && type === 'b2b' ">
			<v-layout wrap>
				<v-flex xs12>
					<h3 class="mt-4">Adicione o numero do Deal do HubSpot para buscar os dados do cliente</h3>
					<v-layout wrap>
						<v-flex xs6>
							<v-text-field box hide-details v-model="dealId" label="Número do deal no HubSpot" placeholder="Busque o cliente no Hub Spot "/>
						</v-flex>
						<v-flex xs6>
							<v-btn depressed small class="green white--text mt-4" @click="getDataFromHubspot">
								Buscar
							</v-btn>
						</v-flex>
					</v-layout>
				</v-flex>

				<v-flex xs12 md6 class="mt-3">
				</v-flex>

			</v-layout>
		</v-container>

		<v-container fluid grid-list-xl class="pa-0">
			<v-layout wrap>
				<v-flex xs12>
					<h3 class="mt-4">Escolha a pessoa abaixo</h3>
					<v-layout wrap>
						<v-flex xs6>
							<v-autocomplete
								box hide-details v-model="assinatura.paciente" :items="pacientes"
								item-text="nome" return-object label="Escolha a pessoa" placeholder="Paciente / familiar"
								no-data-text="Nenhuma pessoa encontrada" class="mt-3" color="green"
								no-filter :search-input.sync="search_input"
								@change="value => $emit('selected-rest-home', value.casa_repouso)"/>
						</v-flex>
						<v-flex xs6>
							<v-btn depressed small class="green white--text mt-4" @click="dialog_paciente = true">
								<v-icon small class="mr-2">add</v-icon>
								Nova pessoa
							</v-btn>
						</v-flex>
					</v-layout>
				</v-flex>

				<v-flex xs12 v-if="tem_paciente_selecionado">
					<PacienteInfo :paciente="assinatura.paciente" />
				</v-flex>

				<ObservacoesAssinatura :assinatura="assinatura" />

			</v-layout>
		</v-container>

		<!-- Dialog paciente -->
		<v-dialog persistent scrollable v-model="dialog_paciente" transition="fade" max-width="1070px"
			v-if="dialog_paciente">
			<transition>
				<DialogPaciente
					:close.sync="dialog_paciente"
					@inserir="salvo"
					:paciente_orcamento="assinatura.paciente_orcamento"
					:patientResponsibleFromQuotation="patientResponsibleFromQuotation"
					:sendUpdatePatientResponsibles="sendUpdatePatientResponsibles"/>
			</transition>
		</v-dialog><!-- Fim, dialog paciente -->

	</div>
</template>

<script>
import DialogPaciente from '@Componentes/pacientes/dialog-paciente';
import { PacientePresenter } from '@Presenter/paciente-presenter';
import { AssinaturaMixin } from '@Mixins/assinatura';
import { OrcamentoPresenter } from '@Presenter/orcamento-presenter';
import moment from 'moment';
import { isDomicilio } from '@Util/functions';
import { debounce, isEqual } from 'lodash';
import PacienteInfo from './paciente-info';

export default {
	name: 'EscolherPessoa',
	mixins: [AssinaturaMixin],
	components: { PacienteInfo, DialogPaciente },
	props: {
		clinica: {
			type: Boolean,
			default: false
		},
		cpfPreSelecionado: {
			type: String,
			default: null
		},
		filial: {
			type: String,
			default: ''
		},
		type: {
			type: String // 'b2c' || b2b
		},
		orcamento: {
			type: Object,
			required: false,
			default: () => ({})
		}
	},
	data: () => ({
		pacientes: [],
		radio_is_para_mim: true,
		dialog_paciente: false,
		dealId: null,
		patientResponsibleFromQuotation: null,
		search_input: ''
	}),

	watch: {
		search_input: {
			handler: debounce(function (val, oldVal) {
				if (isEqual(val, oldVal))
					return;

				this.getPacientes(val);
			}, 1000)
		}
	},

	created() {
		this.getPacientes(this.cpfPreSelecionado);
	},
	methods: {
		salvo(paciente) {
			this.pacientes.push(paciente);
			this.assinatura.paciente = paciente;

			if (this.canGetRestAddress(paciente.casa_repouso))
				this.$emit('selected-rest-home', paciente.casa_repouso);
		},
		canGetRestAddress(rest_home) {
			if (rest_home)
				return !isDomicilio(rest_home.nome);
		},
		sendUpdatePatientResponsibles(responsibles) {
			this.assinatura.paciente.responsaveis = responsibles || [];
		},
		getDataFromHubspot() {
			if (!this.dealId || !this.orcamento) {
				this.$store.dispatch('SHOW_SNACKBAR', { color: 'error', message: 'Id do Deal é obrigatório' });
				return;
			}
			return OrcamentoPresenter.getHubspotData(this.orcamento.id, this.dealId)
				.then(data => {
					this.assinatura.paciente_orcamento = {
						...data.paciente,
						nascimento: moment(data.paciente.nascimento).format('DD/MM/YYYY'),
						casa_repouso_id: this.orcamento.casa_repouso_id
					};
					this.patientResponsibleFromQuotation = data.responsavel;
					this.dialog_paciente = true;
				});
		},

		getPacientes(search) {
			const data = {
				sem_assinaturas: true
			};
			if (this.clinica)
				data.casa_repouso_ids = [29, 76];

			data.busca = search;
			data.page = 1;
			data.perPage = 12;

			PacientePresenter.index(data)
				.then(response => {
					this.pacientes = response.items;

					// we only have to select a patient if no other patient has been searched
					if (this.cpfPreSelecionado && !this.search_input)
						this.assinatura.paciente = this.pacientes.find(p => p.cpf === this.cpfPreSelecionado) || {};
				});
		}

	},
	computed: {
		tem_paciente_selecionado() {
			return !!this.assinatura.paciente.id;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@Assets/css/settings.scss";
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;

  .v-avatar {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.06);
    transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &:hover {
      background: rgba(0, 0, 0, 0.12);
    }

    &:active {
      background: rgba(0, 0, 0, 0.06);
    }
  }
}
</style>
