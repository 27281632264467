<template>
	<v-flex class="space-between items-start q-column" xs5>
		<div>
			<p class="mb-0 text-bold subheading long-string">{{name}}</p>
			<p class="mb-0 body-2">{{presentation}} - {{manufacturer}}</p>
		</div>
		<v-layout wrap row align-center justify-start>
			<MedicineGenericBadge v-if="isGeneric" />
			<MedicinePbmBadge v-if="isPbm"  />
			<MedicinePrescriptionRetentionBadge v-if="isControlled"  />
			<MedicinePrescriptionUtilizationBadge v-if="hasPrescriptionUtilization" />
		</v-layout>
	</v-flex>
</template>

<script>
import MedicinePbmBadge from '@Componentes/medicine-pbm-badge.vue';
import MedicineGenericBadge from '@Componentes/medicine-generic-badge.vue';
import MedicinePrescriptionRetentionBadge from '@Componentes/medicine-prescription-retention-badge.vue';
import MedicinePrescriptionUtilizationBadge from '@Componentes/medicine-prescription-utilization-badge.vue';

export default {
	name: 'PontualQuotationItemDetail',
	components: {
		MedicinePbmBadge,
		MedicineGenericBadge,
		MedicinePrescriptionRetentionBadge,
		MedicinePrescriptionUtilizationBadge
	},
	props: {
		item: {
			required: true,
			type: Object
		}
	},

	computed: {
		name() {
			return this.item.name;
		},
		manufacturer() {
			return this.item.manufacturer;
		},
		presentation() {
			return this.item.presentation;
		},
		isPbm() {
			return this.item.isPbm;
		},
		isGeneric() {
			return this.item.isGeneric;
		},
		isControlled() {
			return this.item.isControlled;
		},
		hasPrescriptionUtilization() {
			return this.item.hasPrescriptionUtilization;
		}
	}
};
</script>

<style lang="scss" scoped>
.titleOpacity {
	opacity: 0.7;
}

.long-string {
	word-break: break-all;
}
</style>
