<template>
	<div>
		<v-data-table
			class="custom-data-table"
			:headers="headers"
			:items="items"
			:loading="loading"
			:ref="reference"
			:no-data-text="loading ? 'Carregando...' : 'Nenhum item'"
			no-results-text="Nenhum resultado encontrado"
			:rows-per-page-items="[items.length]"
			:total-items="totalItems"
			:pagination.sync="localPagination">
			<template slot="headers" slot-scope="props">
				<tr>
					<th
						v-for="(header) in props.headers"
						:key="header.text"
						@click="header.sortable ? changeSort(header.value) : () => {}"
						class="column text-xs-center"
						:class="[
							header.class,
							{ sortable: header.sortable },
							pagination.descending ? 'desc' : 'asc',
							header.value === pagination.sortBy ? 'active' : '',
						]">
						{{ header.text }}
						<v-icon small v-if="header.sortable">arrow_upward</v-icon>
					</th>
				</tr>
			</template>
			<template slot="items" slot-scope="props">
				<tr class="pointer" @click="$emit('openDetails',props.item)">
					<td class="text-xs-center">
						{{ props.item.id }}
					</td>
					<td class="text-xs-center px-3">
						{{ props.item.customer.name }}
					</td>
					<td class="text-xs-center">
						{{props.item.properties &&  props.item.properties.assinatura_id  ?  props.item.properties.assinatura_id : '-'}}
					</td>
					<td class="text-xs-center">
						{{ props.item.reference_type }}
					</td>
					<td class="text-xs-center">
						{{ props.item.reference_id }}
					</td>
					<td class="text-xs-center">
						{{ props.item.created_at | formatDate('DD/MM/YYYY') }}
					</td>
					<td class="text-xs-center px-2">
						{{props.item.due_date | formatDate('DD/MM/YYYY')}}
					</td>
					<td class="roboto text-xs-center px-3">
						<div class="status-label-style" :style="classStatus(props.item.status)">
							{{ labelStatus(props.item.status).toUpperCase() }}
						</div>
					</td>
					<td class="text-xs-center">
						{{ props.item.total_amount | dinheiro }}
					</td>
					<td class="text-center px-1">
						<ManageInvoice :item="props.item" @load-invoice="$emit('load-invoice')">
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" flat fab @click.stop>
									<v-icon large color="primary" class="material-symbols-outlined">add_circle</v-icon>
								</v-btn>
							</template>
						</ManageInvoice>

					</td>
				</tr>
			</template>
			<template v-slot:footer>
				<tr>
					<td :style="'padding:0;'" v-for="header in headers" :key="header.text">
						<div class="custom-footer-container" >
							<span v-if="header.text === ''" class="custom-footer">
								{{  getPaginationText() }}
							</span>
						</div>
					</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { getInvoiceStatusAttributes } from '@Consts/invoices';
import ManageInvoice from './manage-invoice.vue';

export default {
	name: 'InvoicesDataTable',
	components: {
		ManageInvoice
	},
	props: {
		items: {
			type: Array,
			required: false,
			default: () => []
		},
		pagination: {
			type: Object,
			required: true
		},
		totalItems: {
			type: Number,
			required: false,
			default: 0
		},
		loading: {
			type: Boolean,
			required: false,
			default: false
		},
		reference: {
			type: String,
			required: false,
			default: ''
		},
		changePagination: {
			type: Function,
			required: false
		}
	},
	data: () => ({
		localPagination: {},
		headers: [
			{

				text: 'ID Fatura',
				value: 'id',
				// this property has to be on sortable from pagination
				sortable: true
			},
			{
				text: 'Nome do cliente',
				value: 'customer_name'
			},
			{

				text: 'ID Assinatura',
				value: 'subscription_id'

			},
			{

				text: 'Tipo',
				value: 'reference_type'

			},
			{
				text: 'ID Origem',
				value: 'reference_id'
			},
			{
				text: 'Criação',
				value: 'created_at',
				// this property has to be on sortable from pagination
				sortable: true
			},
			{
				text: 'Vencimento',
				value: 'due_date',
				// this property has to be on sortable from pagination
				sortable: true
			},
			{
				text: 'Status',
				value: 'status'
			},
			{
				text: 'Valor total',
				value: 'total-amount'
			},
			{
				text: '',
				value: '',
				class: 'px-1'

			}
		]
	}),
	mounted() {
		this.localPagination = this.pagination;
	},
	watch: {
		localPagination: {
			handler(val) {
				this.$emit('changePagination', val);
			},
			deep: true
		}
	},
	methods: {
		getPaginationText() {
			const currentPage = this.localPagination.page;
			const currentPageToCalculate = this.localPagination.page - 1;
			const totalByPage = currentPage > 1 ? ((currentPageToCalculate * this.localPagination.rowsPerPage) + this.items.length) : this.items.length;
			return `${currentPage} - ${totalByPage} de ${this.totalItems}`;
		},
		changeSort(column) {
			if (this.localPagination.sortBy === column)
				this.localPagination.descending = !this.localPagination.descending;
			else {
				this.localPagination.sortBy = column;
				this.localPagination.descending = false;
			}
		},
		openActions(item) {
			this.$router.push({ name: 'invoice', params: { id: item.id } });
		},
		labelStatus(status) {
			return getInvoiceStatusAttributes(status).label;
		},
		classStatus(status) {
			return getInvoiceStatusAttributes(status).class;
		}
	}
};
</script>

<style lang="scss" scoped>
	@import "@Assets/css/settings.scss";

	::v-deep .custom-data-table .v-table thead tr,
	::v-deep .custom-data-table .v-table tbody tr,
	::v-deep .v-datatable .v-datatable__actions {
		border: 0;
	}

	::v-deep .custom-data-table .v-table tbody td {
		background-color: $secondary-light;
		height: 100%;
	}

	::v-deep .custom-data-table .v-table tbody td:first-child {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border: 2px solid $secondary-light;
	}
	::v-deep .custom-data-table .v-table tbody td:last-child {
		border: 4px solid $secondary-light;
		border-bottom-right-radius: 10px;
		border-top-right-radius: 10px;
	}

	::v-deep .custom-data-table .v-table {
		background-color: $primary_light;
		border-collapse: separate;
		border-spacing: 0 15px;
	}

	::v-deep .custom-data-table .v-datatable .v-datatable__actions {
		background-color: $primary_light;
	}

	::v-deep .v-input--selection-controls .v-input__control,
	::v-deep .v-input--selection-controls.v-input .v-label {
		width: 100%;
	}

	::v-deep .v-datatable__actions__pagination{
		display: none;
	}

	.custom-footer{
		font-size: 12px;
	}

	.custom-footer-container{
		padding:0;
		display: flex;
		flex-direction: column;
		align-self:end
	}

	.status-label-style {
		border-radius: 30px;
		text-align: center;
		font-weight: 600;
		font-size: 10px !important;
		padding: 3px 15px;
	}

</style>
