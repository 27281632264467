import { BUSINESS_B2B2C_TYPE, BUSINESS_B2B_TYPE, BUSINESS_B2C_TYPE } from '@Consts/business-type';
import { allQuotationStatus, quotationTypes } from '@Consts/quotation-v2';

export class PontualQuotationUtil {
	/**
	 *
	 * @param {String} quotationStatus
	 * @returns {Boolean}
	 */
	static isDraft(quotationStatus) {
		return quotationStatus.toLowerCase() === allQuotationStatus.DRAFT.value.toLowerCase();
	}

	/**
	 *
	 * @param {String} quotationStatus
	 * @returns {Boolean}
	 */
	static isDone(quotationStatus) {
		return quotationStatus.toLowerCase() === allQuotationStatus.DONE.value.toLowerCase();
	}

	/**
	 *
	 * @param {String} businessType
	 * @returns {Boolean}
	 */
	static isB2B(businessType) {
		return businessType.toLowerCase() === BUSINESS_B2B_TYPE.value.toLowerCase();
	}

	/**
	 *
	 * @param {String} businessType
	 * @returns {Boolean}
	 */
	static isB2C(businessType) {
		return businessType.toLowerCase() === BUSINESS_B2C_TYPE.value.toLowerCase();
	}

	/**
	 *
	 * @param {String} businessType
	 * @returns {Boolean}
	 */
	static isB2B2C(businessType) {
		return businessType.toLowerCase() === BUSINESS_B2B2C_TYPE.value.toLowerCase();
	}

	/**
	 *
	 * @param {String} quotationStatus
	 * @returns {Boolean}
	 */
	static isCanceled(quotationStatus) {
		return quotationStatus.toLocaleLowerCase() === allQuotationStatus.CANCELED.value.toLocaleLowerCase();
	}

	/**
	 *
	 * @param {String} quotationStatus
	 * @returns {Boolean}
	 */
	static isPaid(quotationStatus) {
		return quotationStatus.toLocaleLowerCase() === allQuotationStatus.PAID.value.toLocaleLowerCase();
	}

	/**
	 *
	 * @param {*} businessType
	 * @returns {String}
	 */
	static getQuotationTypeFromBusinessType(businessType) {
		if (businessType === BUSINESS_B2C_TYPE.value)
			return quotationTypes.ONE_TIME_PURCHASE;
		if (businessType === BUSINESS_B2B_TYPE.value)
			return quotationTypes.ONE_TIME_PURCHASE_NO_PAYMENT;
		if (businessType === BUSINESS_B2B2C_TYPE.value)
			return quotationTypes.ONE_TIME_PURCHASE_OPTIONAL_PAYMENT;
		throw new Error('Erro ao definir o tipo do orçamento');
	}

	/**
	 *
	 * @param {String} quotationType
	 * @returns {String}
	 */
	static getQuotationBusinessTypeFromQuotationType(quotationType) {
		if (quotationType === quotationTypes.ONE_TIME_PURCHASE_NO_PAYMENT)
			return BUSINESS_B2B_TYPE.value;
		if (quotationType === quotationTypes.ONE_TIME_PURCHASE)
			return BUSINESS_B2C_TYPE.value;
		if (quotationType === quotationTypes.ONE_TIME_PURCHASE_OPTIONAL_PAYMENT)
			return BUSINESS_B2B2C_TYPE.value;
		throw new Error('Erro ao definir o tipo do orçamento');
	}

	static getPontualQuotationTypes() {
		return [
			{
				businessType: BUSINESS_B2C_TYPE.value,
				quotationType: quotationTypes.ONE_TIME_PURCHASE
			},
			{
				businessType: BUSINESS_B2B_TYPE.value,
				quotationType: quotationTypes.ONE_TIME_PURCHASE_NO_PAYMENT
			},
			{
				businessType: BUSINESS_B2B2C_TYPE.value,
				quotationType: quotationTypes.ONE_TIME_PURCHASE_OPTIONAL_PAYMENT
			}
		];
	}
}
