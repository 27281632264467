<template>
	<v-container>
		<v-layout align-center class="p-0">
			<v-flex>
				<v-btn flat class="px-5 my-0" @click="$emit('cancel')">{{textCancel}}</v-btn>
			</v-flex>
			<v-flex class="display-flex justify-end">
				<v-btn v-if="this.tabSelected !== stepsQuotation.generalData" class="my-0 px-5" @click="$emit('back')">Voltar</v-btn>
				<v-btn v-if="this.tabSelected !== stepsQuotation.products" class="primary my-0 px-5" @click="$emit('next')">{{textNext}}</v-btn>
			</v-flex>
		</v-layout >
	</v-container>
</template>

<script>
import { stepsQuotation } from '@Consts/quotation-v2';
import Mixin from './mixin';

export default {
	name: 'FooterQuotationV2',
	mixins: [Mixin],
	props: {
		tabSelected: {
			type: String
		}
	},
	data: () => ({
		stepsQuotation
	}),
	computed: {
		textCancel() {
			return this.tabSelected !== stepsQuotation.generalData ? 'Cancelar orçamento' : 'Cancelar';
		},
		textNext() {
			return this.tabSelected !== stepsQuotation.products ? 'Próximo' : 'Salvar orçamento';
		}
	}
};
</script>
