import $http from '@Config/axios';

const PREFIX_V2 = '/v2/casas_repouso';

export class RestHomePresenter {
	/**
	 * @param {ListRestHomeProps} props
	 *
	 * @returns {Promise<ListRestHomeResponse>}
	 */
	static async list(props) {
		const params = {
			...props.filter,
			...props.sort,
			...props.pagination
		};
		const response = await $http.get(PREFIX_V2, { params });
		return response;
	}

	/**
	 * @param {Number} restHomeId
	 *
	 * @returns {Promise<Object>}
	 */
	static async getDetail(restHomeId) {
		const response = await $http.get(`${PREFIX_V2}/${restHomeId}`);
		return response;
	}
}

/**
 * @typedef {Object} ListRestHomeProps
 * @property {ListRestHomePagination | null} pagination
 * @property {ListRestHomeSort | null} sort
 * @property {ListRestHomeFilter | null} filter
 */

/**
 * @typedef {Object} ListRestHomeResponse
 * @property {ListRestHomeResponseListItem[]} items
 * @property {Number} total
 *
 * @param {Props} props
 *
 * @returns {Promise<ResponseList>}
 */

/**
 * @typedef {Object} ListRestHomeResponseListItem
 * @property {Number} id
 * @property {String} tradingName
 * @property {String} documentNumber
 */

/**
 * @typedef {Object} ListRestHomePagination
 * @property {Boolean} all
 * @property {Number | null} page
 * @property {Number | null} perPage
 */

/**
 * @typedef {Object} ListRestHomeSort
 * @property {String | null} field
 * @property {String<ASC|DESC> | null} direction
 */

/**
 * @typedef {Object} ListRestHomeFilter
 * @property {Number} companyUnitId
 * @property {String=} q
 */
