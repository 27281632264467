<template>
	<v-container fluid grid-list-md class="pa-0">
		<v-layout wrap>
			<v-flex md6 xs12>
				<RestHomeTypeBadge v-if="extra.businessType" :businessType="getBusinessType"/>
				<p v-if="extra.restHome && !isB2C(extra.restHome.businessType)" class="font-weight-black title">{{ extra.restHome.tradingName }}</p>
			</v-flex>
			<v-flex md6 xs12>
				<v-select
					v-model="extra.companyUnitId"
					:items="units"
					remove-selected
					label="Unidade *"
					box
					v-validate="{ required: true }"
					:error-messages="errors.collect('Unidade')"
					data-vv-name="Unidade"
					background-color="white"/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { BUSINESS_B2C_TYPE } from '@Consts/business-type';
import { getAllCompanyUnits } from '@Consts/unidades';
import RestHomeTypeBadge from '@Views/componentes/rest-home-badge.vue';

export default {
	$_veeValidate: { validator: 'new' },
	components: {
		RestHomeTypeBadge
	},
	props: {
		extra: {
			required: true,
			type: Object
		}
	},
	computed: {
		getBusinessType() {
			return this.extra.businessType;
		},
		units() {
			return getAllCompanyUnits().map(item => ({
				value: item.id,
				text: `${item.name} (${item.abbr})`
			}));
		}
	},
	methods: {
		async validate() {
			const isValid = await this.$validator.validateAll();
			return !!isValid;
		},
		isB2C(businessType) {
			return businessType === BUSINESS_B2C_TYPE.value;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
