import $http from '@Config/axios';
import { buildQueryParams } from 'luiz-fns';

const PREFIX = '/charges';

/**
 * Lista das cobranças das boxes
 *
 * @typedef {Object} Filter
 * @property {String} view
 * @property {Array} restHomeIds
 * @property {String} startDate
 * @property {String} boxStatus
 * @property {String} paymentMode
 * @property {Boolean} showDeleted
 * @property {Boolean} withPBM
 * @property {String} search
 * @property {Array} ignoreTagsIds
 * @property {String} initialDateRange
 * @property {String} finalDateRange
 *
 * @typedef {Object} Pagination
 * @property {Number} page
 * @property {Number} perPage

 * @typedef {Object} Sorting
 * @property {Number} field
 * @property {(DESC|ASC)} direction
 *
 * @typedef {Object} Params
 * @property {Filter} filter
 * @property {Pagination} pagination
 * @property {Sorting} sorting
 *
 * @typedef {Object} Response
 * @property {Array} items
 * @property {Number} total
 * @property {Number} totalCharged
 * @property {Number} page
 * @property {Number} perPage

 * @param {Params} params
 * @returns {Promise<Response>}
 */
async function list(params = {}) {
	const query = {
		page: params.pagination.page,
		perPage: params.pagination.perPage,
		sortBy: params.sorting.field,
		sortDir: params.sorting.direction,
		restHomeIds: params.filter.restHomeIds,
		initialDateRange: params.filter.initialDateRange,
		finalDateRange: params.filter.finalDateRange,
		boxStatus: params.filter.boxStatus,
		paymentMode: params.filter.paymentMode,
		withPBM: params.filter.withPBM,
		view: params.filter.view,
		q: params.filter.search,
		ignoreTagsIds: params.filter.ignoreTagsIds,
		showDeleted: params.filter.showDeletedSubscriptions
	};
	const response = await $http.get(buildQueryParams(PREFIX, query));
	return response;
}

async function charge(box_ids = []) {
	const response = await $http.post(`${PREFIX}/charge`, { box_ids });
	return response;
}

export const ChargesPresenter = {
	list,
	charge
};
