<template>
	<v-dialog
		v-model="opened"
		transition="fade"
		max-width="600px">
		<template v-slot:activator="{ on }">
			<PrescriptionItem v-on="on" class="pointer" itemStyle="dashed">
				<v-flex  @click="open" class="text-center" style="display: flex;flex-direction: column;">
					<v-icon>add</v-icon>
					<h5 class="font-weight-black mb-2">Adicionar <br> receita</h5>
				</v-flex>
			</PrescriptionItem>
		</template>

		<UploadPrescriptionDialog
			v-if="opened"
			:withFile="false"
			:close.sync="opened"
			@prescriptionToBeCreated="confirm"/>
	</v-dialog>
</template>

<script>
import UploadPrescriptionDialog from '@Componentes/dialog-comparar-receitas-medicamentos/dialog-upload-receitas';
import PrescriptionItem from './item.vue';

export default {
	data() {
		return {
			opened: false
		};
	},
	components: {
		UploadPrescriptionDialog,
		PrescriptionItem
	},
	methods: {
		open() {
			this.opened = true;
		},
		close() {
			this.opened = false;
		},
		confirm(data) {
			const prescription = {
				prescribedDate: this.$functions.FormatDateToRequest(data.data_prescrita),
				notificationNumber: data.numero_notificacao || null,
				prescriber: data.extra.prescriber,
				canRemove: true
			};
			this.$emit('handlePrescription', prescription);
			this.close();
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
