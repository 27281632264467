<template>
	<section>
		<Info :quotation="quotation" class="my-4" />

		<Items
			:total="quotation.total"
			:discount="quotation.discount || 0"
			:shippingPrice="quotation.shippingPrice || 0"
			:items="quotation.items" class="my-4"/>

		<FarmeDivider />
		<template
			v-if="isPrescriptionSectionVisible">
			<Prescriptions
				class="my-4"
				:prescription="prescription"/>

			<FarmeDivider />
		</template>

		<Observation :observation="quotation.publicObservations" class="my-4"/>

		<FarmeDivider />

		<StatusHistory :history="quotation.history"  class="my-4"/>
	</section>
</template>

<script>

import { PontualQuotationUtil } from '@Util/pontual-quotation';
import FarmeDivider from '@Componentes/farme-divider.vue';
import Info from './info.vue';
import Items from './items.vue';
import Prescriptions from './prescriptions/index.vue';
import Observation from './observation.vue';
import StatusHistory from './status-history.vue';

export default {
	components: {
		Info,
		Items,
		Prescriptions,
		Observation,
		FarmeDivider,
		StatusHistory
	},
	props: {
		quotation: {
			required: true,
			type: Object
		},
		hasControlled: {
			required: false,
			type: Boolean,
			default: null
		},
		prescription: {
			required: true,
			type: Object
		}
	},
	computed: {
		isPrescriptionSectionVisible() {
			return this.hasControlled;
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
