const MALE = 'MALE';
const FEMALE = 'FEMALE';

export const sex = {
	[FEMALE]: 'Feminino',
	[MALE]: 'Masculino'
};

export const sexByValue = {
	[sex.FEMALE]: FEMALE,
	[sex.MALE]: MALE
};

export const getAllSex = () => Object.keys(sex)
	.map(key => (
		{
			id: key,
			name: sex[key]
		}
	)).filter(item => item.id);
