const BUSINESS_B2B_TYPE_VALUE = 'B2B';
const BUSINESS_B2B_TYPE_TEXT = 'B2B';
export const BUSINESS_B2B_TYPE = Object.freeze({
	value: BUSINESS_B2B_TYPE_VALUE,
	text: BUSINESS_B2B_TYPE_TEXT,
	icon: null
});

const BUSINESS_B2C_TYPE_VALUE = 'B2C';
const BUSINESS_B2C_TYPE_TEXT = 'B2C';
export const BUSINESS_B2C_TYPE = Object.freeze({
	value: BUSINESS_B2C_TYPE_VALUE,
	text: BUSINESS_B2C_TYPE_TEXT,
	icon: null
});

const BUSINESS_B2B2C_TYPE_VALUE = 'B2B2C';
const BUSINESS_B2B2C_TYPE_TEXT = 'B2B2C';
export const BUSINESS_B2B2C_TYPE = Object.freeze({
	value: BUSINESS_B2B2C_TYPE_VALUE,
	text: BUSINESS_B2B2C_TYPE_TEXT,
	icon: null
});

export const BUSINESS_TYPES = Object.freeze([
	BUSINESS_B2B_TYPE,
	BUSINESS_B2C_TYPE,
	BUSINESS_B2B2C_TYPE
]);
