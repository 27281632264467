<template>
	<div class="my-2" :class="text_style">
		<v-tooltip top >
			<v-icon small slot="activator" :color="(icon_style || desconto_un) ? 'warning_light' : icon_style">fas fa-dollar-sign</v-icon>
			<span v-if="desconto_un">Preço da caixa: {{ preco_un | dinheiro }}<br /></span>
			<span v-if="desconto_un">Desconto na caixa: {{ desconto_un | dinheiro }}<br /></span>
			<span>Preço final da caixa: {{ (preco_un - desconto_un) | dinheiro }}</span>
		</v-tooltip>
		<div style="margin-top: -20px;">
			<p class="mb-0" style="margin-left: 28px">
				{{ preco | dinheiro }}
			</p>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { posologyTypes } from '@Consts/posologia';

export default {
	name: 'Preco',
	props: {
		inicio: {
			type: String,
			default: moment().endOf('month').add(1, 'days').format('YYYY-MM-DD')
		},
		medicamento: {
			type: Object,
			required: true
		},
		text_style: {
			type: String,
			default: 'grey--text text--darken-2'
		},
		icon_style: {
			type: String,
			default: ''
		},
		mostrar_como_integral: {
			type: Boolean,
			default: false
		},
		eHistorico: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		dias_semana: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
	}),
	computed: {
		qtd_mensal() {
			if (this.medicamento.qtd_mensal)
				return this.medicamento.qtd_mensal;

			if (typeof this.medicamento.qtd_caixas === 'number')
				return this.medicamento.qtd_caixas * this.medicamento.qtd_embalagem;

			let total_dias = 0;
			const { posologia } = this.medicamento;
			switch (posologia.periodo_de_uso) {
				case posologyTypes.allDays:
					total_dias = 30;
					break;
				case posologyTypes.dayYesDayNot:
					total_dias = 15;
					break;
				case posologyTypes.dayNotDayYes:
					total_dias = 15;
					break;
				case posologyTypes.weekly:
					if (posologia.dias_da_semana.indexOf(this.dias_semana[moment(this.inicio).weekday()]) >= 0)
						total_dias += 1;
					if (posologia.dias_da_semana.indexOf(this.dias_semana[moment(this.inicio).add(1, 'day').weekday()]) >= 0)
						total_dias += 1;
					total_dias += (4 * posologia.dias_da_semana.length);
					break;
				case posologyTypes.monthly:
					if (posologia.dias_do_mes.indexOf('Último dia do mês') >= 0)
						total_dias += 1;
					for (let i = 0; i < 30; i += 1) {
						if (posologia.dias_do_mes.indexOf(moment(this.inicio).add(i, 'day').format('DD').toString()) >= 0) {
							total_dias += 1;
							if (posologia.dias_do_mes.indexOf('Último dia do mês') >= 0 && (moment(this.inicio).add(i, 'day').format('MM') !== moment(this.inicio).add(i + 1, 'day').format('MM')))
								total_dias -= 1;
						}
					}
					break;
				case posologyTypes.other:
					total_dias = 1;
					break;
				default: break;
			}
			let total_por_dia = 0;
			posologia.horarios.forEach(horario => {
				total_por_dia += horario.quantidade_por_horario;
			});
			const total_mes = total_dias * total_por_dia;
			return (Math.ceil(total_mes) - total_mes) < 0.1 ? Math.ceil(total_mes) : total_mes;
		},
		preco_un() {
			const { preco_personalizado, preco } = this.medicamento;
			return preco_personalizado || preco;
		},
		desconto_un() {
			return this.medicamento.desconto || 0;
		},
		preco_proporcional() {
			return Math.round((this.preco_un / this.medicamento.qtd_embalagem) * this.qtd_mensal);
		},
		desconto_proporcional() {
			return Math.round((this.desconto_un / this.medicamento.qtd_embalagem) * this.qtd_mensal);
		},
		caixas_fechadas() {
			if (this.medicamento.qtd_caixas && this.mostrar_como_integral)
				return this.medicamento.qtd_caixas;

			if (this.medicamento.qtd_embalagem === 0 || this.qtd_mensal === 0)
				return 0;

			return Math.ceil(this.qtd_mensal / this.medicamento.qtd_embalagem);
		},
		preco_integral() {
			return Math.round(this.medicamento.preco_com_desconto * this.caixas_fechadas);
		},
		desconto_integral() {
			return Math.round(this.desconto_un * this.caixas_fechadas);
		},
		preco() {
			if (this.mostrar_como_integral)
				return this.preco_integral;

			return this.preco_proporcional - this.desconto_proporcional;
		}
	}
};
</script>
