<template>
	<section>
		<LoaderDialog v-if="loading"/>

		<v-dialog
			persistent
			v-model="opened"
			fullscreen
			hide-overlay
			scrollable
			transition="fade">

			<template v-slot:activator="{ on }">
				<v-btn
					v-on="on"
					color="primary"
					depressed
					@click="open">
					<v-icon small class="mr-2">add</v-icon>
					Criar orçamento
				</v-btn>
			</template>

			<PontualQuotation
				v-if="opened"
				title="Criar orçamento"
				:generalData="generalData"
				:productData="productData"
				:config="config"
				:allowNavagitation="false"
				@handleQuotation="createQuotation"
				@close="close" />
		</v-dialog>
	</section>

</template>

<script>
import PontualQuotation from '@Componentes/quotation-pontual/index.vue';
import { getCurrentCompanyUnit } from '@Config/unidades';
import moment from 'moment';
import LoaderDialog from '@Views/componentes/loader-dialog.vue';
import { QuotationPresenter } from '@Presenter/quotation-presenter';
import { companyUnitAbbr } from '@Consts/unidades';
import { getUFByStateName } from '@Consts/estados';
import { PontualQuotationUtil } from '@Util/pontual-quotation';

export default {
	name: 'PontualQuotationCreateDialog',
	components: {
		LoaderDialog,
		PontualQuotation
	},
	created() {
		this.initGeneralData();
		this.initProductData();
		this.initConfig();
	},
	data() {
		return {
			opened: false,
			loading: false,
			generalData: {},
			productData: {},
			config: {
				showCustomerSearch: true,
				showCustomerDetail: false,
				canChangeCustomer: true,
				showRestHomeSearch: false,
				showRestHomeSection: false,
				canChangeRestHome: true,
				showAddressSection: false,
				canMarkAsOptionalPayment: true,
				canChangeEmail: true
			}
		};
	},
	methods: {
		open() {
			this.initGeneralData();
			this.initProductData();
			this.initConfig();
			this.opened = true;
		},

		close() {
			this.initGeneralData();
			this.initProductData();
			this.initConfig();
			this.opened = false;
		},

		initGeneralData() {
			this.generalData = {
				address: {
					zipCode: null,
					street: null,
					number: null,
					neighborhood: null,
					complement: null,
					city: null,
					state: null
				},
				customer: {
					externalId: null,
					name: null,
					documentNumber: null,
					phone: null,
					email: null,
					gender: null,
					birthdate: null
				},
				extra: {
					seller: {
						name: this.$store.getters.user?.nome || null,
						phone: this.$store.getters.user?.telefone || null
					},
					expiresAt: moment().add(7, 'days').format('DD/MM/YYYY'),
					companyUnitId: null,
					person: null,
					observation: null,
					isOptionalPayment: false,
					restHome: null
				}
			};
		},

		initProductData() {
			this.productData = {
				shippingPrice: 990, // deveria pegar da api?
				deliveryDate: moment().add(5, 'days').format('DD/MM/YYYY'),
				items: []
			};
		},

		initConfig() {
			this.config.showCustomerSearch = true;
			this.config.showCustomerDetail = false;
			this.config.canChangeCustomer = true;
			this.config.showRestHomeSearch = false;
			this.config.showRestHomeSection = false;
			this.config.canChangeRestHome = true;
			this.config.showAddressSection = false;
			this.config.canMarkAsOptionalPayment = true;
			this.config.canChangeEmail = true;
		},

		async createQuotation(data) {
			const quotation = {
				type: PontualQuotationUtil.getQuotationTypeFromBusinessType(data.generalData.extra.businessType),
				companyUnit: companyUnitAbbr[data.generalData.extra.companyUnitId],
				restHomeId: PontualQuotationUtil.isB2C(data.generalData.extra.businessType) ? null : data.generalData.extra.restHome.id,
				startDate: this.$functions.FormatDateToRequest(data.productData.deliveryDate),
				shippingPrice: this.$functions.onlyNumber(data.productData.shippingPrice),
				expiresAt: moment(this.$functions.FormatDateToRequest(data.generalData.extra.expiresAt)).format(),
				percentDiscountFirstBox: 0,
				publicObservations: data.generalData.extra.observation,
				customer: {
					externalId: data.generalData.customer.externalId,
					name: data.generalData.customer.name,
					email: data.generalData.customer.email,
					phone: data.generalData.customer.phone,
					cpf: data.generalData.customer.documentNumber,
					gender: data.generalData.customer.gender,
					birthdate: this.$functions.FormatDateToRequest(data.generalData.customer.birthdate)
				},
				address: {
					zipCode: data.generalData.address.zipCode,
					street: data.generalData.address.street,
					addressNumber: data.generalData.address.number,
					neighborhood: data.generalData.address.neighborhood,
					city: data.generalData.address.city,
					state: data.generalData.address.state, // deve ser a sigla do estado
					stateName: getUFByStateName(data.generalData.address.state),
					complement: this.generalData.address.complement
				},
				seller: {
					name: data.generalData.extra.seller.name,
					phone: data.generalData.extra.seller.phone
				},
				items: data.productData.items.map(prodt => {
					const price = (prodt.pricing.packagePrice - prodt.pricing.packageDiscount) * prodt.pricing.packageAmount;
					return {
						productId: prodt.productId,
						name: prodt.name,
						presentation: prodt.presentation,
						dosage: prodt.dosage,
						isControlled: prodt.isControlled,
						isDivisible: prodt.isDivisible,
						gtin: prodt.gtin,
						manufacturer: prodt.manufacturer,
						isOutOfBox: prodt.isOutOfBox,
						hasPrescriptionUtilization: prodt.hasPrescriptionUtilization,
						closedPackages: prodt.pricing.packageAmount,
						quantityPillsPerPackage: prodt.quantityPillsPerPackage,
						priceSku: prodt.pricing.packagePrice,
						discount: prodt.pricing.packageDiscount,
						price
					};
				})
			};

			this.loading = true;
			try {
				await QuotationPresenter.createOrSaveQuotationB2c(quotation);
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'success',
					message: 'Orçamento criado com sucesso!'
				});
				this.$emit('reload');
				this.close();
			} catch (error) {
				this.$store.dispatch('SHOW_SNACKBAR', {
					color: 'error',
					message: 'Erro ao salvar orçamento'
				});
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>

</style>
