<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }">
			<v-icon v-on="on" small color="#212B72" class="medicine-generic-badge">fab fa-google</v-icon>
		</template>
		<span class="inter text-bold" style="color: #000;">Genérico</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'PrescriptionRetentionBadge'
};
</script>

<style lang="scss" scoped>
.medicine-generic-badge {
	border-radius: 17px;
	background-color: #E7EAFF;
	padding: 7px;
}
</style>
